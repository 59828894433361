<template>
  <div class="shoppingCartTableWrapper">
    <DataTable
      :value="localCurrentShoppingCart.products"
      class="p-datatable-responsive-demo"
      :paginator="localCurrentShoppingCart.products.length > 5"
      v-model:selection="selectedProducts"
      columnResizeMode="fit"
      :rowHover="true"
      :rows="5">
      <template #empty>
        Nie posiadasz żadnych elementów w koszyku!
      </template>
      <Column headerStyle="width:5%" selectionMode="multiple" :exportable="true"></Column>
      <Column headerStyle="width:20%;">
        <template #body="slotProps">
            <span>
              <img
                class="productImage"
                :src="slotProps.data.image"
              />
            </span>
        </template>
      </Column>
      <Column headerStyle="width:30%;" field="Nazwa" header="Nazwa">
        <template #body="slotProps">
          <span class="p-column-title">Nazwa</span>
          <span class="spanWithValue">{{ slotProps.data.name }}</span>
        </template>
      </Column>
      <Column headerStyle="width:10%;" field="Cena" header="Cena">
        <template #body="slotProps">
          <span class="p-column-title">Cena</span>
          <span class="spanWithValue">{{ slotProps.data.price }}zł</span>
        </template>
      </Column>
      <Column headerStyle="width:20%;" field="Ilość" header="Ilość">
        <template #body="slotProps">
          <span class="p-column-title">Ilość</span>
          <span class="spanWithValue">
              <InputNumber
                class="amountInput"
                v-model="slotProps.data.amount"
                mode="decimal"
                showButtons
                :min="1"
                :max="slotProps.data.stock"
                buttonLayout="horizontal"
                decrementButtonClass="p-button-secondary"
                incrementButtonClass="p-button-secondary"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
              />
            </span>
        </template>
      </Column>
      <Column headerStyle="width:15%;" field="Wartość" header="Wartość">
        <template #body="slotProps">
          <span class="p-column-title">Wartość</span>
          <span class="spanWithValueTotalPrice">{{
              parseFloat((slotProps.data.price * slotProps.data.amount).toString()).toFixed(2)
            }}zł</span>
        </template>
      </Column>
    </DataTable>
    <div class="shoppingCartContentFooter">
      <div>
        <CustomButton class="marginBottom" @click="openMoveSelectedProductsSidebar"
                      label="Przenieś zaznaczone" buttonStyle="white"/>
        <CustomButton @click="removeSelectedProducts" label="Usuń zaznaczone" buttonStyle="white"/>
      </div>
      <CustomButton @click="saveShoppingCart" label="Zapisz koszyk" buttonStyle="red"/>
    </div>
  </div>
  <Sidebar v-model:visible="isMoveSelectedProductsSidebarVisible" :baseZIndex="1000" position="right">
    <div>
      <h3>Wybierz koszyk</h3>
      <div class="sidebarShoppingCartList">
        <DataView
          :pageLinkSize="10"
          :value="localShoppingCarts.filter((element) => element.id !== localCurrentShoppingCart.id)"
          layout="grid"
          :paginator="localShoppingCarts.value > 10"
          :rows="10">
          <template #grid="slotProps">
            <div class="shoppingCartItem shoppingCartItemSidebar"
                 @click="onMoveProductsSideBarItemClick(slotProps.data)">
              <span>
                <h4>{{ slotProps.data.name }}</h4>
                <p>{{ formatDate(new Date(slotProps.data.date)) }} </p>
              </span>
            </div>
          </template>
        </DataView>
      </div>
    </div>
  </Sidebar>
</template>

<script>
import DataView from 'primevue/dataview'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputNumber from 'primevue/inputnumber'
import CustomButton from '@/components/CustomButton'
import Sidebar from 'primevue/sidebar'

import { isObjectEmpty, formatDate } from '@/utils/utils'
import { ShoppingCart } from '@/models/shoppingCart'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'ShoppingCartStepOneTableColumn',
  components: {
    DataView,
    DataTable,
    Column,
    InputNumber,
    Sidebar,
    CustomButton
  },
  props: {
    shoppingCarts: {
      type: Array,
      required: true
    },
    currentShoppingCart: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()

    const localShoppingCarts = computed(() => props.shoppingCarts)
    const localCurrentShoppingCart = computed(() => new ShoppingCart(JSON.parse(JSON.stringify(props.currentShoppingCart))))
    const selectedProducts = ref(null)

    const removeSelectedProducts = async () => {
      if (selectedProducts.value !== null && selectedProducts.value.size !== 0) {
        selectedProducts.value.forEach((selectedProduct) => {
          localCurrentShoppingCart.value.products = localCurrentShoppingCart.value.products.filter(function (product) {
            return product !== selectedProduct
          })
        })
        await store.dispatch('snackbarStore/showMessage', 'Zapisz koszyk, aby zatwierdzić usunięcie!')
        selectedProducts.value = null
      } else {
        await store.dispatch('snackbarStore/showMessage', 'Brak elementów do usunięcia!')
      }
    }

    const isMoveSelectedProductsSidebarVisible = ref(false)
    const openMoveSelectedProductsSidebar = async () => {
      if (selectedProducts.value !== null && !isObjectEmpty(selectedProducts.value)) {
        isMoveSelectedProductsSidebarVisible.value = true
      } else {
        await store.dispatch('snackbarStore/showMessage', 'Brak elementów do przeniesienia!')
      }
    }

    const onMoveProductsSideBarItemClick = async (selectedShoppingCart) => {
      const shoppingCartToMoveFrom = new ShoppingCart(JSON.parse(JSON.stringify(localCurrentShoppingCart.value)))
      const shoppingCartToMoveTo = new ShoppingCart(JSON.parse(JSON.stringify(selectedShoppingCart)))

      for (const product of selectedProducts.value) {
        let indexToRemove = 0
        for (let i = 0; i < shoppingCartToMoveFrom.products.length; i++) {
          if (localCurrentShoppingCart.value.products[i].id === product.id) {
            indexToRemove = i
            break
          }
        }

        // ?
        localCurrentShoppingCart.value.products.splice(indexToRemove, 1)

        shoppingCartToMoveFrom.products.splice(indexToRemove, 1)
        shoppingCartToMoveTo.products.push(product)
      }

      await store.dispatch('shoppingCartStore/saveShoppingCart', shoppingCartToMoveFrom, shoppingCartToMoveFrom.id)
      await store.dispatch('shoppingCartStore/saveShoppingCart', shoppingCartToMoveTo, shoppingCartToMoveTo.id)

      selectedProducts.value = null
      isMoveSelectedProductsSidebarVisible.value = false
      await store.dispatch('snackbarStore/showMessage', 'Zaznaczone przedmioty zostały przeniesione do koszyka: ' + selectedShoppingCart.name + '.')
    }

    const saveShoppingCart = async () => {
      await store.dispatch('shoppingCartStore/setCurrentShoppingCartId', localCurrentShoppingCart.value.id)
      await store.dispatch('shoppingCartStore/saveShoppingCart', localCurrentShoppingCart.value)

      await store.dispatch('snackbarStore/showMessage', 'Koszyk o nazwie "' + localCurrentShoppingCart.value.name + '" został zapisany!')
    }

    return {
      localShoppingCarts,
      localCurrentShoppingCart,
      selectedProducts,
      isMoveSelectedProductsSidebarVisible,
      removeSelectedProducts,
      openMoveSelectedProductsSidebar,
      onMoveProductsSideBarItemClick,
      saveShoppingCart,
      formatDate
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/componentStyles/shoppingCartProductsTable.scss";
</style>
