<template>
  <div class="settingsWrapper">
    <Form @submit="onSubmit" @keydown.enter.prevent.self>
      <h3>
        Moje dane
      </h3>
      <div class="section fields">
        <LabeledInput name="firstName" label="Imię*" rules="required|text" v-model:modelValue="firstName"/>
        <LabeledInput name="lastName" label="Nazwisko*" rules="required|text" v-model:modelValue="lastName"/>
        <LabeledInput name="email" label="Adres e-mail*" rules="required|email" v-model:modelValue="email"/>
      </div>
      <div class="section-footer">
        <button type="submit">Zapisz zmiany</button>
        <p>* Pole wymagane</p>
      </div>
    </Form>
    <hr/>
    <h3>
      Zmiana hasła
    </h3>
    <div class="section">
      <button @click="onResetUserPasswordClick">
        Resetuj hasło
      </button>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { Form } from 'vee-validate'
import LabeledInput from '@/components/LabeledInput'
import { createComputedWrapper } from '@/utils/utils'

export default {
  name: 'AccountSettingsContent',
  components: {
    LabeledInput,
    Form
  },
  props: {
    userData: {
      type: Object,
      required: true
    },
    userService: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const userData = computed(() => props.userData)
    const userService = computed(() => props.userService).value
    const initialUserData = ref(userData.value.cloneForSettings())

    const onSubmit = async () => {
      if (userData.value !== false) {
        if (userData.value.isSameMapForSettings(initialUserData.value)) {
          await store.dispatch('snackbarStore/showMessage', 'Próba wysłania tych samych danych!')
          return
        }

        const response = await userService.updateUserData(userData.value.createMapForSettingsRequest())

        if (response !== false) {
          initialUserData.value = userData.value.cloneForSettings()
          await store.dispatch('snackbarStore/showMessage', 'Zapisano zmiany!')
        }
      }
    }

    const onResetUserPasswordClick = async function () {
      const response = await userService.resetUserPassword()

      if (response !== false) {
        await store.dispatch('snackbarStore/showMessage', 'Na twoją pocztę został wysłany link do zresetowania hasła!')
      }
    }

    return {
      firstName: createComputedWrapper(userData.value, 'firstName'),
      lastName: createComputedWrapper(userData.value, 'lastName'),
      email: createComputedWrapper(userData.value, 'email'),
      onResetUserPasswordClick,
      onSubmit
    }
  }
}
</script>

<style lang="scss" scoped>
.settingsWrapper {
  display: flex;
  flex-direction: column;

  h3 {
    color: $brown;
  }

  hr {
    width: 90%;
    margin: 20px 0;
  }

  @media (max-width: 968px) {
    width: 100%;

    hr {
      width: 100%;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    padding: 2% 1% 2% 2%;

    @media (max-width: 968px) {
      padding: 2% 0 2% 0;
    }
    button {
      border: 2px solid $brown;
      font-weight: bold;
      height: 2.5em;
      width: 9em;
      background-color: white;
      transition: background-color 0.5s, color 0.5s, border 0.5s;
      color: $brown;
      font-size: 1em;
    }
  }

  button:hover {
    background-color: $brown;
    color: white;
    border: 2px solid $brown;
    cursor: pointer;
  }

  .fields {
    width: 50%;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 968px) {
      width: 100%;
    }

    .field {
      position: relative;
      padding-top: 2%;
      padding-bottom: 2%;
      flex-basis: 100%;

      input, label {
        display: block;
      }

      label {
        color: $grey;
      }

      input {
        width: 100%;
        margin-top: 10px;
        border: 1px solid #717171;
        border-radius: 5px;
        padding: 11px 4px 11px 4px;
        position: relative;
      }

      span {
        position: absolute;
        color: red;
        font-size: 0.75em;
        display: flex;
        justify-content: flex-end;
        right: 0;
      }
    }
  }

  .section-footer {
    padding: 0 2%;

    button {
      font-weight: bold;
      height: 2.5em;
      width: 9em;
      background-color: $brown;
      color: white;
      border: none;
      font-size: 1em;
    }

    button:hover {
      border: none;
      background-color: $dark_brown;
      transition: background-color 0.1s ease-out;
    }

    p {
      margin-top: 20px;
      width: 90%;
      text-align: end;
      font-size: 12px;
      color: $brown;
    }

    @media (max-width: 968px) {
      padding: 2% 0 0 0;

      p {
        width: 100%;
      }
    }
  }
}

</style>
