<template>
  <p v-if="title">{{ title }}</p>

  <div v-for="item of items" :key="item.key" class="p-field-radiobutton">
    <RadioButton :id="item.key" name="category" :value="item" v-model="localValue" />
    <label :for="item.key">{{item.name}}</label>
  </div>
</template>

<script>
import RadioButton from 'primevue/radiobutton'
import { computed } from 'vue'

export default {
  name: 'CheckboxList',
  props: {
    title: {
      type: String,
      required: false
    },
    items: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Object,
      required: true
    }
  },
  components: {
    RadioButton
  },
  setup (props, { emit }) {
    const localValue = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })
    return {
      localValue
    }
  }
}
</script>

<style lang="scss" scoped>
p {
  font-weight: 500;
  margin-bottom: 15px;
}

.p-field-radiobutton {
  padding-left: 15px;
}
</style>
