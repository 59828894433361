import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView'
import AccountView from '@/views/AccountView'
import OrdersView from '@/views/OrdersView'
import AdminView from '@/views/AdminView'
import AboutView from '@/views/AboutView'
import ProductsView from '@/views/ProductsView'
import ProductDetailsView from '@/views/ProductDetailsView'
import ShoppingCartView from '@/views/ShoppingCartView'
import ShoppingCartStepOne from '@/components/shopping_cart/step_one/ShoppingCartStepOne'
import ShoppingCartStepTwo from '@/components/shopping_cart/step_two/ShoppingCartStepTwo'
import ShoppingCartStepThree from '@/components/shopping_cart/step_three/ShoppingCartStepThree'
import store from '../store'
import { userRoleEnum } from '@/enums/userRoleEnum'
import faqConfigView from '../admin_panel/faqConfigView'
import faqClientView from '../admin_panel/faqClientViev'
import termsOfUseConfigView from '../admin_panel/termsOfUseConfigView'
import newNewsletterConfigView from '@/admin_panel/NewsletterConfigView'
import newsletterCategoriesConfigView from '@/admin_panel/NewsletterCategoriesConfigView'

import CuttingBoardsView from '@/views/CuttingBoardsView'
import NewsletterView from '@/views/NewsletterView'
import DocumentsView from '@/views/DocumentsView'
import LoyaltyProgramView from '@/views/LoyaltyProgramView'
import CarouselConfigView from '@/admin_panel/CarouselConfigView'
import AllArticlesView from '@/views/AllArticlesView'
import ArticleView from '@/views/ArticleView'
import ArticleConfigView from '@/admin_panel/ArticleConfigView'
import MessagesView from '@/views/MessagesView'
import ChatRoomView from '@/views/ChatRoomView'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/carousel-config',
    name: 'Carousel config',
    component: CarouselConfigView
  },
  {
    path: '/article-config',
    name: 'Article config',
    component: ArticleConfigView
  },
  {
    path: '/articles',
    name: 'Articles',
    component: AllArticlesView
  },
  {
    path: '/article/:articleId',
    name: 'Article',
    props: true,
    component: ArticleView
  },
  {
    path: '/chat',
    name: 'Chat',
    component: MessagesView
  },
  {
    path: '/chat-room/:chatRoomId',
    name: 'Chat room',
    props: true,
    component: ChatRoomView
  },
  {
    path: '/cutting-boards',
    name: 'Cutting boards',
    component: CuttingBoardsView
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: faqClientView
  },
  {
    path: '/documents',
    name: 'Documents',
    component: DocumentsView
  },
  {
    path: '/loyalty-program',
    name: 'Loyalty program',
    component: LoyaltyProgramView
  },
  {
    path: '/newsletter',
    name: 'Newsletter',
    component: NewsletterView
  },
  {
    path: '/faq-configuration',
    name: 'FAQ config',
    component: faqConfigView
  },
  {
    path: '/newsletter-configuration',
    name: 'Newsletter config',
    component: newNewsletterConfigView
  },
  {
    path: '/newsletter-categories-configuration',
    name: 'Newsletter categories config',
    component: newsletterCategoriesConfigView
  },
  {
    path: '/loyalty-program-configuration',
    name: 'Loyalty program config',
    component: termsOfUseConfigView
  },
  {
    path: '/orders',
    name: 'Orders',
    component: OrdersView
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountView
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView
  },
  {
    path: '/products/:category',
    name: 'Products',
    props: true,
    component: ProductsView
  },
  {
    path: '/product/:id',
    name: 'ProductDetails',
    props: true,
    component: ProductDetailsView
  },
  {
    path: '/faqClientView',
    name: 'faqClientView',
    component: faqClientView
  },
  {
    path: '/shopping-cart',
    name: 'ShoppingCart',
    component: ShoppingCartView,
    children: [
      {
        path: '',
        component: ShoppingCartStepOne
      },
      {
        path: 'shopping-invoice',
        component: ShoppingCartStepTwo
      },
      {
        path: 'shopping-summary',
        component: ShoppingCartStepThree
      }
    ]
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminView,
    beforeEnter: (to, from, next) => {
      if (store.state.keycloakStore.roles.includes(userRoleEnum.ADMIN)) {
        next()
      } else {
        next({ path: '/' })
      }
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
