import axios from 'axios'
import { UserData } from '@/models/userData'
import authHeader from '@/services/auth_header'
import { handleRequestError } from '@/utils/utils'

export class UserService {
  constructor (keyCloak) {
    this.keyCloak = keyCloak
    this.baseUrl = process.env.VUE_APP_BASE_BACKEND_URL
  }

  async readUserData () {
    const response = await axios.get(this.baseUrl + '/users', {
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + this.keyCloak.token
      }
    })

    if (response.status === 200) {
      return new UserData(response.data)
    } else {
      return false
    }
  }

  async updateUserData (userData) {
    const response = await axios.put(this.baseUrl + '/users', userData, {
      headers: {
        accept: '*/*',
        Authorization: 'Bearer ' + this.keyCloak.token
      }
    })

    return response.status === 200
  }

  async fetchActiveSubscriptions () {
    const options = {
      method: 'get',
      url: this.baseUrl + '/users/newsletter/subscriptions/',
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(response => {
      return response.data
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async subscribeToNewsletter (subscribeMap) {
    const options = {
      method: 'put',
      url: this.baseUrl + '/users/newsletter/subscribe/',
      headers: authHeader(this.keyCloak.token),
      data: subscribeMap
    }

    const response = await axios(options).then(response => {
      return response
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async unsubscribeToNewsletter (unsubscribeMap) {
    const options = {
      method: 'put',
      url: this.baseUrl + '/users/newsletter/unsubscribe/',
      headers: authHeader(this.keyCloak.token),
      data: unsubscribeMap
    }

    const response = await axios(options).then(response => {
      return response
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async resetUserPassword () {
    const response = await axios.get(this.baseUrl + '/users/reset-password', {
      headers: {
        accept: 'application/json',
        Authorization: 'Bearer ' + this.keyCloak.token
      }
    })

    return response.status === 200
  }

  async changeUserRole (admin) {
    console.log(this.keyCloak.id)

    // const response = await axios.get(this.baseUrl + '/users/')
  }
}
