import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import './utils/validators'
import './styles/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

import { keycloakOptions } from '@/services/keycloakConfig'
import Keycloak from 'keycloak-js'
import PrimeVue from 'primevue/config'

const keycloak = Keycloak(keycloakOptions)

keycloak.init({ onLoad: 'login-required', checkLoginIframe: false }).then(async (isAuthorized) => {
  const app = createApp(App)
  app.provide(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE, keycloak)
  console.log(keycloak.token)
  app.use(PrimeVue)
  app.use(store)
  app.component('QuillEditor', QuillEditor)
  app.use(router)
  app.mount('#app')
}).catch((e) => {
  document.getElementById('app').innerHTML = '' +
    '<div>Błąd serwera: ' + '' +
    '<strong>' + e + '' +
    '</strong>' + ' ' +
    '</div>'
})
