import { computed } from 'vue'
import sanitizeHtml from 'sanitize-html'

export const createComputedWrapper = (map, key) => {
  return computed({
    get: () => map[key],
    set: (value) => {
      map[key] = value
      if (value !== undefined) {
        map[key] = value
      } else {
        map[key] = ''
      }
    }
  })
}

export const generateRandomOrdersList = () => {
  const orders = []

  for (let i = 0; i < 100; i++) {
    let deliveryService = ''
    let orderer = ''
    if (i % 2 === 0) {
      deliveryService = 'DPD'
      orderer = 'Jan Kowalski'
    } else if (i % 3 === 0) {
      deliveryService = 'UPC'
      orderer = 'Wojciech Nowak'
    } else {
      deliveryService = 'InPost'
      orderer = 'Janusz Wiśniewski'
    }

    orders.push({
      id: i,
      orderNumber: generateRandomNumber(10000000, 40000000),
      name: orderer,
      trackingNumber: generateRandomNumber(10000, 99999),
      deliveryService: deliveryService,
      orderDate: generateRandomDate(),
      realisationDate: generateRandomDate()
    })
  }

  return orders
}

export const generateRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min
}

export const generateRandomDate = () => {
  const year = generateRandomNumber(1999, 2027)
  let month = generateRandomNumber(1, 12)
  let day = generateRandomNumber(1, 28)

  if (day.toString().length < 2) {
    day = '0' + day
  }

  if (month.toString().length < 2) {
    month = '0' + month
  }

  return day + '.' + month + '.' + year
}

export const formatDate = (date) => {
  let month = date.getMonth() + 1
  let day = date.getDate()

  if (month < 10) {
    month = '0' + month
  }

  if (day < 10) {
    day = '0' + day
  }

  return day + '.' + month + '.' + date.getFullYear() + 'r.'
}

export const filterDate = (value, filter) => {
  if (filter === undefined || filter === null || (typeof filter === 'string' && filter.trim() === '')) {
    return 'true'
  }

  if (value === undefined || value === null) {
    return false
  }

  return value === formatDate(filter)
}

export const isObjectEmpty = (object) => {
  // noinspection LoopStatementThatDoesntLoopJS
  for (const i in object) return false
  return true
}

export function sleepOneSec () {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve('resolved')
    }, 3000)
  })
}

export async function handleRequestError (error) {
  let rejectError = ''
  if (error.response.data.message) {
    rejectError = { status: error.response.status, message: error.response.data.message }
  } else {
    rejectError = { status: error.response.status, message: 'Kod błędu: ' + error.response.status + ', wiadomość: ' + error.response.statusText }
  }

  if (error.response.status === 401) {
    // TODO Logout user
  }

  return Promise.reject(rejectError)
}

export function translateStatusRole (statusRole) {
  switch (statusRole) {
    case 'NEW':
      return 'Nie wysłany'
    case 'PENDING':
      return 'W trakcie'
    case 'SENT':
      return 'Wysłany'
    default:
      return ''
  }
}
export function parseDateArray (date) {
  if (date !== undefined) {
    return new Date(date[0], date[1] - 1, date[2], date[3], date[4]).toLocaleDateString() + ', ' + new Date(date[0], date[1] - 1, date[2], date[3], date[4]).toLocaleTimeString()
    // return date[2] + '-' + date[1] + '-' + date[0] + ' ' + date[3] + ':' + date[4] + ':' + date[5]
  }
}

export function parseUserRoles (rolesList) {
  if (rolesList.includes('admin')) {
    return 'Administrator'
  } else {
    return 'Klient'
  }
}

export function newsletterCategoriesListToMap (map) {
  const categoriesMap = {}
  for (const category of map) {
    const subcategoryList = []
    category.subcategories.forEach(singleSubcategory => subcategoryList.push({ id: singleSubcategory.id, subcategory: singleSubcategory.subcategory }))
    categoriesMap[category.name] = subcategoryList
  }
  return {
    categories: categoriesMap
  }
}

export function parseUserSubscriptionsMap (subMap) {
  const subcatList = []
  for (const [key, value] of Object.entries(subMap.categories)) {
    console.log(key)
    for (const singleSubcategory of value) {
      if (singleSubcategory.id !== null) {
        subcatList.push(singleSubcategory.id)
      }
    }
  }
  return subcatList
}

export function parseDropdownMapToList (selectedMap) {
  const selectedItemList = []
  for (let i = 0; i < selectedMap.length; i++) {
    selectedItemList.push(selectedMap[i].code)
  }
  return selectedItemList
}

export function parseDropdownListToMap (selectedList) {
  const selectedItemMap = []
  for (let i = 0; i < selectedList.length; i++) {
    if (selectedList[i] === 'NewsletterCategory.Furniture') {
      selectedItemMap.push({
        name: 'Meblowy',
        code: 'NewsletterCategory.Furniture'
      })
    } else if (selectedList[i] === 'NewsletterCategory.Building') {
      selectedItemMap.push({
        name: 'Budowlany',
        code: 'NewsletterCategory.Building'
      })
    }
  }
  return selectedItemMap
}

export function sanitizeQuillHtml (dirtyHTML) {
  return sanitizeHtml(dirtyHTML, {
    allowedTags: ['b', 'i', 'em', 'strong', 'a', 'p', 's', 'u', 'img', 'ol', 'li', 'ul', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span'],
    allowedAttributes: {
      a: ['href'],
      img: ['src'],
      p: ['style'],
      span: ['style']
    },
    allowedSchemesByTag: {
      img: ['data']
    },
    allowedStyles: {
      '*': {
        color: [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/]
      },
      p: {
        'font-size': [/^\d+rem$/]
      }
    },
    allowedClasses: {
      '*': ['ql-align-center', 'ql-align-right', 'ql-align-justify', 'ql-align-center', 'ql-indent-1', 'ql-indent-2', 'ql-indent-3', 'ql-indent-4', 'ql-indent-5', 'ql-indent-6', 'ql-indent-7', 'ql-indent-8']
    }
  })
}
