<template>
  <div class="p-d-flex p-jc-center">
    <div v-if="isLoading"></div>
    <div v-else class="p-col-12 p-md-10 p-lg-10 p-mt-3 newsletter-wrapper">
      <Carousel :value="images" :numVisible="1" :numScroll="1" :responsiveOptions="responsiveOptions" class="custom-carousel">
        <template #item="slotProps">
          <div class="product-item">
            <div class="product-item-content">
                <img class="carousel-image" src="https://www.abler.pl/uploads/images/eRozkr%C3%B3j/poziom1.jpg" :alt="slotProps.data.name"/>
<!--                <Button class="navigation-button" label="Więcej informacji" @click="handleNavigationToArticle(1)"></Button>-->
            </div>
          </div>
        </template>
      </Carousel>
      <div class="p-d-flex p-dir-col p-ai-center p-mt-6 messages-wrapper">
        <h2 class="p-mt-4">WIADOMOŚCI</h2>
        <h4 class="p-mt-4"> MASZ:</h4>
        <div class="p-m-4 p-p-4 p-d-flex p-dir-row p-jc-center p-ai-center message-outline">
          <div class="messages-icon p-text-secondary p-mr-2" style="font-size: 5rem"></div>
          <h1 class="p-mr-2">0</h1>
          <p class="p-mr-5">nowych wiadomości</p>
          <Button label="Czytaj"  @click="handleNavigationToMessages"></Button>
        </div>
      </div>

      <div class="p-d-flex p-dir-col p-ai-center p-mt-6 news-wrapper">
        <h2 class="p-mt-4 p-mb-5">AKTUALNOŚCI</h2>
        <div class="p-d-flex p-dir-row">
          <div class="p-d-flex p-dir-col p-jc-between border-column">
            <div v-for="(article, index) of firstArticlesList" :key="index">
              <div class="p-d-flex p-dir-col p-jc-center p-mb-3 article-wrapper">
                  <p class="p-mb-2">{{ article.publishDateStart }}</p>
                  <h3 class="p-mb-2">{{ article.title }}</h3>
                  <p class="p-mb-2">{{ article.lead }}<Button label="Czytaj więcej" class="p-button-link" @click="handleNavigationToArticle(article.id)"/></p>
              </div>
            </div>
          </div>
          <div class="p-d-flex p-dir-col p-jc-between">
            <div v-for="(article, index) of secondArticleList" :key="index">
              <div class="p-d-flex p-dir-col p-jc-center p-mb-3 p-pl-5 article-wrapper">
                <p class="p-mb-2">{{ article.publishDateStart }}</p>
                <h3 class="p-mb-2">{{ article.title }}</h3>
                <p class="p-mb-2">{{ article.lead }}<Button label="Czytaj więcej" class="p-button-link" @click="handleNavigationToArticle(article.id)"/></p>
              </div>
            </div>
          </div>
        </div>
        <Button class="p-mt-4 p-mb-4" label="Wszystkie aktualności" @click="handleNavigationToArticles"></Button>
      </div>
    </div>
  </div>

</template>

<script>
import { ref, onMounted, inject } from 'vue'
import Carousel from 'primevue/carousel'
import { CarouselService } from '@/services/carouselService'
import Button from 'primevue/button'
import { ArticleService } from '@/services/articleService'
import router from '@/router'

export default {
  name: 'HomeView',
  components: {
    Carousel,
    Button
  },
  setup (props) {
    onMounted(async () => {
      isLoading.value = true
      await carouselService.fetchCarouselImages().then(links => {
        images.value = links
      })

      await articleService.fetchArticles(0, 4).then(response => {
        firstArticlesList.value = response.articles.slice(0, 2)
        secondArticleList.value = response.articles.slice(2, 4)
      })

      isLoading.value = false
    })
    const keycloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const carouselService = new CarouselService(keycloak)
    const articleService = new ArticleService(keycloak)

    const isLoading = ref(false)

    const images = ref(null)
    const firstArticlesList = ref()
    const secondArticleList = ref()

    const responsiveOptions = ref([
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '600px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '480px',
        numVisible: 1,
        numScroll: 1
      }
    ])

    const handleNavigationToMessages = () => {
      router.push({ name: 'Chat' })
    }

    const handleNavigationToArticles = () => {
      router.push({ name: 'Articles' })
    }

    const handleNavigationToArticle = (id) => {
      router.push({ name: 'Article', params: { articleId: id } })
    }

    return { responsiveOptions, images, handleNavigationToMessages, handleNavigationToArticles, handleNavigationToArticle, firstArticlesList, secondArticleList, isLoading }
  }
}
</script>

<style lang="scss" scoped>
body{
  background-color: $light_grey;
}
.custom-carousel .navigation-button{
    position: relative;
    top: -100px;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}
.p-button.p-component.p-button-link{
  border: none;
  background-color: white;
  color: $primary_color;
  padding: 0px;
}
.p-button.p-component{
  background-color: $primary_color;
  border: 1px solid $primary_color;
  color: white;
  padding: 12px 30px;

  &:hover{
    background-color: white;
    border: 1px solid #B72937;
    color: #B72937;
  }
}

.messages-wrapper {
  background-color: white;

  .message-outline{
    border-style: solid;
    border-color: #D4D4D4;
    border-width: 1px;
    width: 90%;

    h1{
      color: $primary_color;
    }

    p{
      color: $primary_color;
      font-weight: 600;
    }

    .messages-icon{
      width: 42px;
      height: 32px;
      background-image: url("../assets/icons/messages_icon.svg");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.news-wrapper {
  background-color: white;

  .border-column{
    border-right-color: #A4A4A4;
    border-style: hidden solid hidden hidden;
    border-width: 0.5px;
  }

  .article-wrapper{
    width: 500px;
  }
}

</style>
