<template>
  <div class="productsWrapper" :key="router.currentRoute.fullPath">
    <BlockUI :blocked="true">
      <Breadcrumb :home="{ icon: 'pi pi-home' }" :model="breadCrumbItems"/>
    </BlockUI>
    <div class="p-grid">
      <div class="p-col-12 p-md-3">
        <h1>{{ currentCategoryHeader }}</h1>
        <Tree
          :value="treeMenuItems"
          :loading="productsState.isLoading"
          selectionMode="single"
          v-model:selectionKeys="selectionKeys"
          :metaKeySelection="false"
          @node-select="onNodeSelect">
        </Tree>
      </div>
      <div class="p-col-12 p-md-9">
        <ProductsGrid
          :products="productsState.products"
          :isLoadingProducts="productsState.isLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ProductsGrid from '@/components/products/ProductsGrid'
import Breadcrumb from 'primevue/breadcrumb'
import Tree from 'primevue/tree'
import { ref, onMounted, reactive } from 'vue'
import { treeMenuItems } from '@/utils/tempData'
import router from '@/router'
import { ProductsService } from '@/services/productsService'
import BlockUI from 'primevue/blockui'

export default {
  name: 'ProductsView',
  props: {
    category: {
      type: String,
      required: false
    }
  },
  components: {
    ProductsGrid,
    Breadcrumb,
    BlockUI,
    Tree
  },
  setup (props) {
    const productsService = new ProductsService()
    const categoryDisplayNamesRef = ref([])
    const currentCategoryHeader = ref('')
    const breadCrumbItems = ref([])
    const selectionKeys = ref({})
    const productsState = reactive({
      products: [],
      isLoading: true
    })

    const downloadProductsFromApi = async (category) => {
      productsState.isLoading = true
      productsState.products = []

      productsService.downloadProductsFromApi(category).then(value => {
        productsState.products = value
        productsState.isLoading = false
      })
    }

    const updateCurrentCategoryHeader = () => {
      currentCategoryHeader.value = categoryDisplayNamesRef.value[categoryDisplayNamesRef.value.length - 1]
    }

    const updateBreadCrumbItems = () => {
      breadCrumbItems.value = []

      const splitCategoryPaths = props.category.split('_')
      const mergedCategories = []

      for (let i = 0; i < categoryDisplayNamesRef.value.length; i++) {
        mergedCategories.push({
          categoryPath: splitCategoryPaths[i],
          categoryDisplayName: categoryDisplayNamesRef.value[i]
        })
      }

      for (let i = 0; i < categoryDisplayNamesRef.value.length; i++) {
        let category = ''
        const categoryDisplayNames = []

        for (let j = 0; j < mergedCategories.length - i; j++) {
          if (j === 0) {
            category += mergedCategories[j].categoryPath
          } else {
            category += '_' + mergedCategories[j].categoryPath
          }
          categoryDisplayNames.push(mergedCategories[j].categoryDisplayName)
        }

        breadCrumbItems.value.push({
          label: categoryDisplayNames[categoryDisplayNames.length - 1],
          category: category
        })
      }

      // TODO order is switched despite using push (bug with primevue?)
      breadCrumbItems.value = breadCrumbItems.value.reverse()
    }

    const onNodeSelect = async (node) => {
      if (!productsState.isLoading) {
        await router.push({
          name: 'Products',
          params: {
            category: node.key
          }
        })

        updateCategoryDisplayNames(treeMenuItems.value)
        updateCurrentCategoryHeader()
        updateBreadCrumbItems()
        updateTreeActiveItem()
        await downloadProductsFromApi(props.category)
      }
    }

    const updateTreeActiveItem = () => {
      const searchAndActiveElement = (elements, categoryKey) => {
        for (const element of elements) {
          const splitElements = element.key.split('_')
          if (splitElements[splitElements.length - 1] === categoryKey) {
            selectionKeys.value = { [element.key]: true }
            return true
          } else if (element.children) {
            const elementFound = searchAndActiveElement(element.children, categoryKey)
            if (elementFound) {
              return true
            }
          }
        }

        return false
      }

      const categoryKeys = props.category.split('_')
      searchAndActiveElement(treeMenuItems.value, categoryKeys[0])
      // TODO code below will select all categories in the order
      // TODO however doing it this way wont expand the tree visually..
      // for (const categoryKey of categoryKeys) {
      //   searchAndActiveElement(treeMenuItems.value, categoryKey)
      // }
    }

    const updateCategoryDisplayNames = (items) => {
      for (const item of items) {
        if (item.key === props.category) {
          categoryDisplayNamesRef.value = item.categoryDisplayNames
          return
        } else if (item.children) {
          updateCategoryDisplayNames(item.children)
        }
      }
    }

    onMounted(async () => {
      updateCategoryDisplayNames(treeMenuItems.value)
      updateCurrentCategoryHeader()
      updateBreadCrumbItems()
      updateTreeActiveItem()
      await downloadProductsFromApi(props.category)
    })

    return {
      breadCrumbItems,
      currentCategoryHeader,
      router,
      productsState,
      onNodeSelect,
      selectionKeys,
      treeMenuItems
    }
  }
}
</script>

<style lang="scss" scoped>

.absorbPointer {
  pointer-events: none;
}

.productsWrapper {
  padding: 60px 10% 100px 10%;

  h1 {
    font-weight: 500;
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 768px) {
  .productsWrapper {
    padding: 15px 3%;
  }
}

@media screen and (max-width: 1200px) {
  .productsWrapper {
    padding: 70px 4%;
  }
}

::v-deep(.p-panelmenu .p-panelmenu-header > a) {
  background: none;
  color: black;
  border-radius: 0;
  border: none;
  font-weight: 400;
}

::v-deep(.p-panelmenu .p-panelmenu-header.p-highlight > a) {
  background: $brown;
  border-color: $brown;
}

::v-deep(.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus) {
  box-shadow: none;
}

::v-deep(.p-panelmenu .p-panelmenu-header > a:focus) {
  box-shadow: none;
}

::v-deep(.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover) {
  border-color: $brown;
  background: $brown;
}

::v-deep(.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon) {
  display: none;
}

::v-deep(.p-tree) {
  border: none;
  background: none;
  padding: 0;
}

::v-deep(.p-blockui.p-component-overlay) {
  background: transparent;
  z-index: 99999 !important;
}

::v-deep(.p-component-overlay) {
  background-color: rgba(248, 248, 248, 0.35);

  .pi {
    display: none;
  }
}
</style>
