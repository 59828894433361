import { parseDateArray } from '@/utils/utils'

export class Article {
  constructor (element) {
    this.content = element.content
    this.id = element.id
    this.lead = element.lead
    this.publishDateStart = parseDateArray(element.publishDateStart)
    this.publishDateStop = parseDateArray(element.publishDateStop)
    this.title = element.title
  }
}
