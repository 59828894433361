<template>
  <div class="productDetailsWrapper">
    <div class="p-grid p-flex-row-reverse">
      <div class="p-col-12 p-md-4">
        <div class="detailsBox">
          <div v-if="!isLoading">
            <h2>{{ loadedProduct.name }}</h2>
            <div class="detailsContentBox">
              <div v-for="detail in loadedProduct.details" :key="detail.name" class="detailsInfo">
                <p>
                  {{ detail.name + (detail.unit !== undefined ? ' ('+ detail.unit+')' : '') + ': ' }}
                </p>
                <span>{{ detail.value }}</span>
              </div>
            </div>
          </div>
          <div v-else class="detailsLoading">
            <div class="loading" style="height: 32px; width: 200px;"></div>
            <div  class="detailsContentBox">
              <div v-for="n in 4" :key="n" class="detailsInfo">
                <div class="loading" style="height: 21px; width: 150px;"></div>
                <div class="loading" style="height: 21px; width: 30px;"></div>
              </div>
            </div>
          </div>

          <div class="amountBox">
            <p>Ilość:</p>
            <InputNumber
              v-if="!isLoading"
              class="amountInput"
              v-model="amount"
              mode="decimal"
              showButtons
              :min="1"
              :max="9999"
              buttonLayout="horizontal"
              decrementButtonClass="p-button-secondary"
              incrementButtonClass="p-button-secondary"
              incrementButtonIcon="pi pi-plus"
              decrementButtonIcon="pi pi-minus"
            />
            <div v-else class="loading" style="height: 25px; width: 150px;"></div>
          </div>
          <div class="priceBox">
            <p>Cena:</p>
            <h2 v-if="!isLoading">5405 zł</h2>
            <div v-else class="loading" style="height: 25px; width: 60px;"></div>
          </div>
          <button class="addToShoppingCartButton" @click="handleAddToShoppingCart" :disabled="isLoading">
            <i class="pi pi-shopping-cart"/>
            Dodaj do aktywnego koszyka
          </button>
        </div>
      </div>
      <div class="p-col-12 p-md-8">
        <button @click="handleBackButton" class="prevStepButton"><i class="pi pi-angle-left"/> Powrót</button>
        <div class="imagesBox">
          <div class="p-grid">
            <div v-if="isLoading" class="p-col-12 p-md-6">
              <div class="loadingBox loading"></div>
            </div>

            <div v-else v-for="(image, index) in loadedProduct.images" :key="image" class="p-col-12 p-md-6">
              <img @click="handleOpenGallery(index)" class="productImage" :src="require('@/assets/' + image)"
                   :alt="image"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ProductGallery
    v-if="!isLoading"
    :images="loadedProduct.images"
    v-model:visible="isGalleryVisible"
    v-model:activeIndex="activeIndex"
  />
</template>

<script>
import { ProductsService } from '@/services/productsService'
import { ref, onMounted } from 'vue'
import ProductGallery from '@/components/product_details/ProductGallery'
import InputNumber from 'primevue/inputnumber'
import router from '@/router'

export default {
  name: 'ProductDetailsView',
  components: { ProductGallery, InputNumber },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const loadedProduct = ref(null)
    const isLoading = ref(true)
    const productsService = new ProductsService()
    const isGalleryVisible = ref(false)
    const activeIndex = ref(0)
    const amount = ref(1)

    onMounted(async () => {
      isLoading.value = true
      loadedProduct.value = await productsService.getProductById(props.id)
      isLoading.value = false
    })

    const handleBackButton = () => {
      router.go(-1)
    }

    const handleOpenGallery = (index) => {
      activeIndex.value = index
      isGalleryVisible.value = true
    }

    const handleAddToShoppingCart = () => {
      console.log('adding')
    }

    return {
      isLoading,
      loadedProduct,
      handleBackButton,
      isGalleryVisible,
      handleOpenGallery,
      activeIndex,
      handleAddToShoppingCart,
      amount
    }
  }
}
</script>

<style lang="scss" scoped>

@import "src/styles/componentStyles/skeletonLoading";

.productDetailsWrapper {
  padding: 60px 10% 100px 10%;

  .prevStepButton {
    width: 20%;
    height: 40px;
    background-color: white;
    border: 1px solid #c8c8c8;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s background-color;
    font-weight: 500;
    font-size: 0.8rem;

    i {
      font-size: 11px;
      margin-right: 5px;
    }

    &:hover {
      background-color: #f8f8f8;
    }
  }

  .imagesBox {
    margin-top: 30px;
    border: 1px solid $grey;
    background-color: white;
    padding: 15px;

    .loadingBox {
      width: 100%;
      height: 450px;
      box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
      position: relative;
      overflow: hidden;
    }

    .productImage {
      width: 100%;
      height: 450px;
      object-fit: cover;
      box-shadow: 0 4px 10px 0 rgba(33, 33, 33, 0.15);
      cursor: pointer;

      @media screen and (max-width: 1500px) {
        height: 300px;
      }
    }
  }

  .detailsBox {
    margin-top: 70px;
    border: 1px solid $grey;
    background-color: white;
    padding: 15px;

    h2 {
      color: $brown;
      font-weight: 500;
    }

    .detailsContentBox {
      padding: 15px;

      .detailsInfo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        span {
          font-weight: 600;
        }
      }
    }

    .amountBox {
      height: 35px;
      margin: 5px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .priceBox {
      height: 35px;
      margin: 15px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        color: black;
        font-weight: 500;
      }
    }

    .addToShoppingCartButton {
      width: 100%;
      height: 40px;
      color: white;
      background-color: #dc2f2f;
      border: none;
      font-size: 0.8em;
      font-weight: 500;
      cursor: pointer;
      transition: 1s background-color;

      i {
        font-size: 11px;
        margin-left: 5px;
      }

      &:hover {
        background-color: #bf2828;
      }

      &:disabled {
        background-color: #e2e2e2;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .productDetailsWrapper {
    padding: 15px 3%;
  }
}

@media screen and (max-width: 1200px) {
  .productDetailsWrapper {
    padding: 70px 4%;
  }
}

::v-deep(.p-inputnumber-buttons-horizontal .p-inputnumber-input) {
  width: 32px;
  text-align: center;
  border: none;
  border-bottom: 1px solid $grey;
}
</style>
