<template>
  <div class="stepOneWrapper">
    <div class="p-grid p-flex-row-reverse">
      <div class="p-col-12 p-md-4 leftPadding30px">
        <ShoppingCartStepOneShoppingCartsColumn
          :shoppingCarts="shoppingCarts"
          :currentShoppingCart="currentShoppingCart"/>
      </div>
      <div class="p-col-12 p-md-8">
        <ShoppingCartStepOneTableColumn
          :shoppingCarts="shoppingCarts"
          :currentShoppingCart="currentShoppingCart"/>
      </div>
    </div>
  </div>
</template>

<script>

import { computed } from 'vue'
import { useStore } from 'vuex'
import { ShoppingCart } from '@/models/shoppingCart'
import ShoppingCartStepOneTableColumn from '@/components/shopping_cart/step_one/ShoppingCartStepOneTableColumn'
import ShoppingCartStepOneShoppingCartsColumn
  from '@/components/shopping_cart/step_one/ShoppingCartStepOneShoppingCartsColumn'

export default {
  name: 'ShoppingCartStepOne',
  components: {
    ShoppingCartStepOneShoppingCartsColumn,
    ShoppingCartStepOneTableColumn
  },
  setup () {
    const store = useStore()
    const shoppingCarts = computed(() => store.getters['shoppingCartStore/getShoppingCarts'])
    const currentShoppingCart = computed(() => new ShoppingCart(JSON.parse(JSON.stringify(store.getters['shoppingCartStore/getCurrentShoppingCart']))))
    return {
      shoppingCarts,
      currentShoppingCart
    }
  }
}
</script>

<style lang="scss" scoped>

.stepOneWrapper {
  padding: 55px;
  margin: 0;
  background-color: #f8f8f8;

  .leftPadding30px {
    padding-left: 30px;
  }

  @media screen and (max-width: 1200px) {
    padding: 15px;

    .leftPadding30px {
      padding-left: 8px;
    }
  }
}

</style>
