import { ShoppingCart } from '@/models/shoppingCart'
import { ShoppingCartService } from '@/services/shoppingCartService'
import { requestStateEnum } from '@/enums/requestStateEnum'

const shoppingCartService = new ShoppingCartService()

const state = {
  requestStatus: requestStateEnum.NOT_SENT,
  currentShoppingCartId: 'domyslny_koszyk',
  shoppingCarts: [
    new ShoppingCart(
      {
        id: 'domyslny_koszyk',
        name: 'Koszyk domyślny',
        date: Date.now(),
        products: []
      }
    )
  ]
}

const getters = {
  getShoppingCarts (state) {
    return state.shoppingCarts
  },
  getCurrentShoppingCart (state) {
    for (let i = 0; i < state.shoppingCarts.length; i++) {
      if (state.shoppingCarts[i].id === state.currentShoppingCartId) {
        return state.shoppingCarts[i]
      }
    }
    return undefined
  }
}

const actions = {
  fetchShoppingCarts: async (context, token) => {
    const shoppingCartsFromLocalStorage = localStorage.getItem('shoppingCarts')

    if (shoppingCartsFromLocalStorage === null) {
      context.commit('SET_REQUEST_STATUS', requestStateEnum.IN_PROGRESS)
      try {
        const basketsFromApi = await shoppingCartService.getBaskets(token)
        context.commit('SET_REQUEST_STATUS', requestStateEnum.SUCCESS)
        context.commit('SET_SHOPPING_CARTS', basketsFromApi)
      } catch (e) {
        context.commit('SET_REQUEST_STATUS', requestStateEnum.ERROR)
      }
    } else {
      const shoppingCartsList = []

      for (const shoppingCartFromLocalStorage of JSON.parse(shoppingCartsFromLocalStorage)) {
        shoppingCartsList.push(new ShoppingCart(shoppingCartFromLocalStorage))
      }

      context.commit('SET_SHOPPING_CARTS', shoppingCartsList)
    }
  },
  setCurrentShoppingCartId: (context, shoppingCartId) => {
    context.commit('SET_CURRENT_SHOPPING_CART_ID', shoppingCartId)
  },
  setShoppingCarts: (context, shoppingCarts) => {
    context.commit('SET_SHOPPING_CARTS', shoppingCarts)
  },
  saveShoppingCart: (context, payload) => {
    context.commit('SAVE_SHOPPING_CART', payload)
  },
  addShoppingCart: (context, shoppingCart) => {
    context.commit('ADD_SHOPPING_CART', shoppingCart)
  },
  removeShoppingCartById: (context, shoppingCartId) => {
    context.commit('REMOVE_SHOPPING_CART_BY_ID', shoppingCartId)
  },
  addProductToShoppingCart: (context, product) => {
    context.commit('ADD_PRODUCT_TO_SHOPPING_CART', product)
  },
  removeProductFromShoppingCart: (context, product) => {
    context.commit('REMOVE_PRODUCT_FROM_SHOPPING_CART', product)
  }
}

const mutations = {
  SET_REQUEST_STATUS: (state, status) => {
    state.requestStatus = status
  },
  SET_SHOPPING_CARTS: (state, shoppingCarts) => {
    for (const shoppingCart of shoppingCarts) {
      state.shoppingCarts.push(shoppingCart)
    }

    localStorage.setItem('shoppingCarts', JSON.stringify(shoppingCarts))
  },
  SET_CURRENT_SHOPPING_CART_ID: (state, shoppingCartId) => {
    state.currentShoppingCartId = shoppingCartId
  },
  SAVE_SHOPPING_CART: (state, shoppingCart) => {
    for (let i = 0; i < state.shoppingCarts.length; i++) {
      if (state.shoppingCarts[i].id === shoppingCart.id) {
        state.shoppingCarts[i] = new ShoppingCart(JSON.parse(JSON.stringify(shoppingCart)))
        return
      }
    }
  },
  ADD_SHOPPING_CART: (state, shoppingCart) => {
    state.shoppingCarts.push(shoppingCart)
  },
  REMOVE_SHOPPING_CART_BY_ID: (state, shoppingCartId) => {
    let indexOfElementToRemove
    for (let i = 0; i < state.shoppingCarts.length; i++) {
      if (state.shoppingCarts[i].id === shoppingCartId) {
        indexOfElementToRemove = i
        break
      }
    }
    state.shoppingCarts.splice(indexOfElementToRemove, 1)
  },
  ADD_PRODUCT_TO_SHOPPING_CART: (state, product) => {
    state.shoppingCarts.products.push(product)
  },
  REMOVE_PRODUCT_FROM_SHOPPING_CART: (state, product) => {
    state.shoppingCarts.products.push(product)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
