<template>
  <div class="field">
    <input type="checkbox" :name="name" v-model="localValue"/>
    <span> {{ errorMessage }}</span>
  </div>
</template>

<script>
import { useField } from 'vee-validate'
import { computed } from 'vue'

export default {
  name: 'CheckboxInput',
  props: {
    name: {
      type: String,
      required: true
    },
    rules: {
      type: String,
      required: true
    },
    modelValue: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const localValue = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })
    const { value, errorMessage } = useField(props.name, localValue => !localValue)

    return {
      localValue,
      value,
      errorMessage
    }
  }
}
</script>

<style lang="scss" scoped>

.field {
  position: relative;

  input, label {
    display: block;
  }

  label {
    color: black;
  }

  input {
    width: 20px;
    height: 20px;
    border: 1px solid #B72937;
    position: relative;
  }

  span {
    position: absolute;
    color: red;
    font-size: 0.75em;
    display: flex;
    justify-content: flex-end;
    right: 0;
  }
}
</style>
