import axios from 'axios'
import { UnknownException } from '@/utils/exceptions'
import { ShoppingCart } from '@/models/shoppingCart'

export class ShoppingCartService {
  constructor () {
    this.baseUrl = process.env.VUE_APP_BASE_BACKEND_URL
  }

  async getBaskets (token) {
    try {
      const response = await axios.get(this.baseUrl + '/basket', {
        headers: {
          accept: 'application/json',
          Authorization: 'Bearer ' + token
          // Authorization: 'Bearer ' + this.token
        }
      })

      const basketList = []
      for (const rawBasket of response.data) {
        basketList.push(new ShoppingCart(rawBasket))
      }

      return basketList
    } catch (e) {
      throw new UnknownException()
    }
  }

  async updateBaskets (token) {
    try {
      const response = await axios.post(this.baseUrl + '/basket', {
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
          Authorization: 'Bearer ' + token
        }
      })

      return response
    } catch (e) {
      console.log(e)
    }
  }
}
