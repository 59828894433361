export class UserData {
  constructor (userData = {}) {
    this.company = userData.company
    this.email = userData.email
    this.firstName = userData.firstName
    this.lastName = userData.lastName
    this.phoneNumber = userData.phoneNumber
    this.fiscalCode = userData.fiscalCode
    this.roles = userData.roles
    this.invoiceAddress = new Address(userData.invoiceAddress)
    this.shippingAddress = new Address(userData.shippingAddress)
  }

  cloneForSettings () {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email
    }
  }

  cloneForAddress () {
    return {
      phoneNumber: this.phoneNumber,
      street: this.shippingAddress.street,
      city: this.shippingAddress.city,
      zipCode: this.shippingAddress.zipCode,
      buildingNumber: this.shippingAddress.buildingNumber,
      apartmentNumber: this.shippingAddress.apartmentNumber
    }
  }

  cloneForInvoice () {
    return {
      company: this.company,
      fiscalCode: this.fiscalCode,
      street: this.invoiceAddress.street,
      city: this.invoiceAddress.city,
      zipCode: this.invoiceAddress.zipCode,
      buildingNumber: this.invoiceAddress.buildingNumber,
      apartmentNumber: this.invoiceAddress.apartmentNumber
    }
  }

  isSameMapForSettings (map) {
    return this.firstName === map.firstName && this.lastName === map.lastName && this.email === map.email
  }

  isSameMapForAddress (map) {
    return this.phoneNumber === map.phoneNumber &&
      this.shippingAddress.street === map.street &&
      this.shippingAddress.apartmentNumber === map.apartmentNumber &&
      this.shippingAddress.buildingNumber === map.buildingNumber &&
      this.shippingAddress.zipCode === map.zipCode &&
      this.shippingAddress.city === map.city
  }

  isSameMapForInvoice (map) {
    return this.company === map.company &&
      this.fiscalCode === map.fiscalCode &&
      this.invoiceAddress.street === map.street &&
      this.invoiceAddress.apartmentNumber === map.apartmentNumber &&
      this.invoiceAddress.buildingNumber === map.buildingNumber &&
      this.invoiceAddress.zipCode === map.zipCode &&
      this.invoiceAddress.city === map.city
  }

  createMapForSettingsRequest () {
    return {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email
    }
  }

  createMapForAddressRequest () {
    return {
      phoneNumber: this.phoneNumber,
      shippingAddress: {
        street: this.shippingAddress.street,
        apartmentNumber: this.shippingAddress.apartmentNumber,
        buildingNumber: this.shippingAddress.buildingNumber,
        zipCode: this.shippingAddress.zipCode,
        city: this.shippingAddress.city
      }
    }
  }

  createMapForInvoiceRequest () {
    return {
      company: this.company,
      fiscalCode: this.fiscalCode,
      invoiceAddress: {
        street: this.invoiceAddress.street,
        apartmentNumber: this.invoiceAddress.apartmentNumber,
        buildingNumber: this.invoiceAddress.buildingNumber,
        zipCode: this.invoiceAddress.zipCode,
        city: this.invoiceAddress.city
      }
    }
  }
}

export class Address {
  constructor (address = {}) {
    this.street = address.street
    this.city = address.city
    this.zipCode = address.zipCode
    this.buildingNumber = address.buildingNumber
    this.apartmentNumber = address.apartmentNumber
  }
}
