import axios from 'axios'
import authHeader from '@/services/auth_header'
import { handleRequestError } from '@/utils/utils'
import { RegulationsModel } from '@/models/regulationsModel'

export class RegulationsService {
  constructor (keyCloak) {
    this.keyCloak = keyCloak
    this.baseUrl = process.env.VUE_APP_BASE_BACKEND_URL
  }

  async fetchNewsletterRegulationsData (id) {
    const options = {
      method: 'get',
      url: this.baseUrl + '/regulations/' + id,
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(response => {
      return new RegulationsModel(response.data)
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }
}
