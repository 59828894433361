<template>
  <div class="productsGridWrapper">
    <DataView
      :value="filteredProducts === null ? products : filteredProducts"
      layout="grid"
      :paginator="products.length > 9"
      :rows="9"
      :sortOrder="sortOrder"
      :sortField="sortField">
      <template #header>
        <Button
          class="priceButton"
          type="button"
          label="Toggle"
          @click="togglePricePanel"
          :disabled="products.length === 0">
          {{
            selectedSliderValues.length > 0 ? 'W cenie od ' + selectedSliderValues[0] + 'zł do ' + selectedSliderValues[1] + 'zł' : 'Cena'
          }}
        </Button>

        <Dropdown
          v-model="sortKey"
          :options="sortOptions"
          optionLabel="label"
          placeholder="Sortuj"
          :disabled="products.length === 0"
          @change="onSortChange($event)"
        />

        <OverlayPanel ref="pricePanel">
          <div class="pricePanelBox">
            <div class="filteredPriceBox">
              <span>{{ sliderValues[0] }} zł</span>
              <span>-</span>
              <span>{{ sliderValues[1] }} zł</span>
            </div>
            <Slider v-model="sliderValues" :range="true" :min="1" :max="9999"/>
            <div class="priceButtonsBox">
              <button @click="handleClearPriceFilter">Wyczyść</button>
              <button @click="handlePriceFilter">Filtruj</button>
            </div>
          </div>
        </OverlayPanel>

      </template>

      <template #grid="slotProps">
        <div v-if="products.length !== 0 && !isLoadingProducts"
             class="productCardBox p-col-12 p-sm-6 p-md-6 p-lg-4">
          <ProductCard
            :id="slotProps.data.id"
            :name="slotProps.data.name"
            :price="slotProps.data.price"
            :available="slotProps.data.available"
            :description="slotProps.data.description"
            :images="slotProps.data.images"
          />
        </div>
      </template>
    </DataView>
    <div v-if="isLoadingProducts" class="loadingSpinnerBox">
      <ProgressSpinner class="spinner"/>
      <p>Wczytywanie produktów...</p>
    </div>
    <div v-else-if="products.length === 0 && !isLoadingProducts">
      <p class="emptyProductList">
        Nie znaleziono produktów danej kategorii.
      </p>
    </div>
  </div>
</template>

<script>
import DataView from 'primevue/dataview'
import Dropdown from 'primevue/dropdown'
import OverlayPanel from 'primevue/overlaypanel'
import Slider from 'primevue/slider'
import Button from 'primevue/button'

import { ref } from 'vue'
import ProductCard from '@/components/products/ProductCard'
import ProgressSpinner from 'primevue/progressspinner'

export default {
  name: 'ProductsGrid',
  components: {
    ProductCard,
    DataView,
    Dropdown,
    ProgressSpinner,
    OverlayPanel,
    Slider,
    Button
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    isLoadingProducts: {
      type: Boolean,
      required: true
    }
  },
  setup (props) {
    const filteredProducts = ref(null)
    const sortOrder = ref(null)
    const sortField = ref(null)
    const sortKey = ref(null)
    const sliderValues = ref([1, 9999])
    const selectedSliderValues = ref([])
    const pricePanel = ref(null)

    const togglePricePanel = (event) => {
      pricePanel.value.toggle(event ?? null)
    }

    const handlePriceFilter = () => {
      selectedSliderValues.value = sliderValues.value
      filteredProducts.value = props.products.filter(item => {
        return selectedSliderValues.value[0] < item.price && selectedSliderValues.value[1] > item.price
      })
      togglePricePanel()
    }

    const handleClearPriceFilter = () => {
      selectedSliderValues.value = []
      filteredProducts.value = null
      togglePricePanel()
    }

    const sortOptions = [
      {
        label: 'Domyślne sortowanie',
        value: []
      },
      {
        label: 'Cena od najniższej',
        value: 'price'
      },
      {
        label: 'Cena od najwyższej',
        value: '!price'
      }
    ]

    const onSortChange = (event) => {
      const value = event.value.value
      const sortValue = event.value

      if (value.indexOf('!') === 0) {
        sortOrder.value = -1
        sortField.value = value.substring(1, value.length)
        sortKey.value = sortValue
      } else {
        sortOrder.value = 1
        sortField.value = value
        sortKey.value = sortValue
      }
    }

    return {
      onSortChange,
      sortKey,
      sortOptions,
      sortOrder,
      sortField,
      togglePricePanel,
      pricePanel,
      sliderValues,
      selectedSliderValues,
      handlePriceFilter,
      handleClearPriceFilter,
      filteredProducts
    }
  }
}
</script>

<style lang="scss" scoped>

.productsGridWrapper {
  .productCardBox {
    padding: 15px;
  }

  .emptyProductList {
    padding: 15px;
  }

  .loadingSpinnerBox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .spinner {
      width: 40px;
    }

    p {
      margin-top: -20px;
      font-size: 12px;
    }
  }

  .priceButton {
    margin-right: 5px;
    border-radius: 0;
    background-color: white;
    color: #333;
    border: 1px solid #a8a8a8;

    &:hover {
      background-color: white;
      color: #666;
      border: 1px solid #666666;
    }

    &:disabled {
      border: 1px solid #a8a8a8;
    }
  }

  .pricePanelBox {
    width: 278px;
    padding: 15px;

    .filteredPriceBox {
      display: flex;
      align-items: center;
      margin: 15px 15px 28px 15px;
      text-align: center;

      span {
        flex: 1;
        font-size: 22px;
        font-weight: 500;
      }
    }

    .priceButtonsBox {
      display: flex;
      margin-top: 28px;

      button {
        height: 45px;
        width: 100%;
        background-color: #DC2F2F;
        border: none;
        color: white;
        cursor: pointer;
        font-size: 15px;
      }

      button:nth-child(1) {
        width: 45%;
        background-color: white;
        border: 1px solid $grey;
        margin-right: 15px;
        color: grey;
      }
    }
  }
}

::v-deep(.p-dataview .p-dataview-header) {
  background: none;
  color: black;
  border: none;
  font-weight: 400;
  padding: 15px 15px;
}

::v-deep(.p-dropdown) {
  border-radius: 0;
  margin-right: 5px;
  margin-top: 5px;
}

::v-deep(.p-dataview .p-dataview-content) {
  background: none;
}

::v-deep(.p-overlaypanel:before) {
  border: none;
  box-shadow: none;
}

</style>
