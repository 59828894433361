export class CarouselImage {
  constructor (image) {
    this.articleId = image.articleId
    this.id = image.id
    this.imageId = image.imageId
    this.url = image.url
  }

  // parseImages (images) {
  //   const imageList = []
  //   for (const image of images) {
  //     imageList.push(new ProductImage(image))
  //   }
  //   return imageList
  // }
}
