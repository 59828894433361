import { sleepOneSec } from '@/utils/utils'

export class ProductsService {
  // TODO constructor products

  async downloadProductsFromApi (category) {
    await sleepOneSec()
    return []
  }

  async getProductById (id) {
    await sleepOneSec()

    return []
  }
}
