<template>
  <Galleria
    :value="images"
    :responsiveOptions="responsiveOptions"
    :numVisible="7"
    containerStyle="max-width: 850px;"
    :circular="true"
    :fullScreen="true"
    :showItemNavigators="true"
    :showThumbnails="false"
    v-model:visible="galleryVisible"
    v-model:activeIndex="activeIndexImage"
  >
    <template #item="slotProps">
      <img :src="require('@/assets/' + slotProps.item)" :alt="slotProps.item.alt" style="width: 100%; display: block;" />
    </template>
  </Galleria>
</template>
<script>

import Galleria from 'primevue/galleria'
import { computed } from 'vue'

export default {
  name: 'ProductGallery',
  components: { Galleria },
  props: {
    images: {
      type: Array,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    }
  },
  setup (props, { emit }) {
    const galleryVisible = computed({
      get () {
        return props.visible
      },
      set (value) {
        emit('update:visible', value)
      }
    })

    const activeIndexImage = computed({
      get () {
        return props.activeIndex
      },
      set (value) {
        emit('update:activeIndex', value)
      }
    })

    const responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 5
      },
      {
        breakpoint: '768px',
        numVisible: 3
      },
      {
        breakpoint: '560px',
        numVisible: 1
      }
    ]

    return { galleryVisible, responsiveOptions, activeIndexImage }
  }
}
</script>

<style lang="scss" scoped>

</style>
