<template>
  <h4>Regulamin</h4>
</template>

<script>
export default {
  name: 'termsOfUseConfigView'
}
</script>

<style scoped>

</style>
