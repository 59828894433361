<template>
    <div class="booking-card" :style="`background-image: url(`+require('@/assets/' + images[0])+`)`">
      <div class="book-container">
        <div class="content">
          <button class="btn" @click="handleGoToProduct">Do produktu</button>
        </div>
      </div>
      <div class="info-container">
        <h3 class="title">{{ name }}</h3>
        <span class="price">{{ price.toString() }}zł</span> <span class="sub-price"> / szt.</span>
        <div class="more-info">
          <div v-if="available" class="stock-box">
            <i class="pi pi-check"/>
            <p>Towar dostępny na magazynie.</p>
          </div>
          <div v-else class="stock-box">
            <i class="pi pi-times"/>
            <p>Brak towaru na magazynie.</p>
          </div>
          <p class="description">{{ description }}</p>
        </div>
      </div>
    </div>
</template>

<script>
import router from '@/router'

export default {
  name: 'ProductCard',
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    price: {
      type: Number,
      required: true
    },
    available: {
      type: Boolean,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    images: {
      type: Array,
      required: true
    }
  },
  setup (props) {
    const handleGoToProduct = async () => {
      await router.push({
        name: 'ProductDetails',
        params: {
          id: props.id
        }
      })
    }

    return { handleGoToProduct }
  }
}
</script>

<style lang="scss" scoped>
@mixin cardsOpen() {
  &::before {
    background: rgba(112, 15, 10, 0.4);
  }
  .book-container {
    .content {
      opacity: 1;

      transform: translateY(0px);
    }
  }

  .info-container {
    transform: translateY(0px);
    .more-info {
      opacity: 1;
    }
  }
}

  .booking-card{
    position: relative;
    width: 100%;
    flex-direction: column;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    text-align: center;
    color: black;

    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(10,72,112, 0);
    }

    .book-container {
      height: 200px;
      .content {
        position: relative;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        transform: translateY(-200px);

        transition: .3s;

        .btn {
          border: 3px solid white;
          padding:8px 15px;
          background: none;
          text-transform: uppercase;
          color: white;
          cursor: pointer;
          transition: .3s;
          font-weight: 500;
          font-size: 1.3em;

          &:hover {
            background: white;
            color: black;
          }
        }
      }

      @media screen and (max-width:768px) {
        height: 100px;
      }
    }

    .info-container{
      flex: 1 0 auto;
      padding: 20px 15px 0 15px;
      height: 340px;
      background: white;
      transform: translateY(206px);
      transition: .3s;

      .title {
        position: relative;
        padding-bottom: 10px;
        font-weight: 500;
      }

      .price {
        font-weight: 600;
        font-size: 1.3em;
      }

      .sub-price {
        font-weight: 500;
        font-size: 0.7em;
      }

      .more-info {
        opacity: 0;
        transition: .3s;

        .stock-box {
          flex: 1 0;
          padding: 15px;
          margin-top: 20px;
          background: $light_grey;
          font-weight: bold;
          font-size: 0.9em;
          border-radius: 15px;

          .icon {
            margin-bottom: 5px;
          }
        }
        .description {
          margin-top: 20px;
          font-size: 0.8em;
          color: black;
        }
      }
    }

    &:hover {
      @include cardsOpen();
    }
  }

</style>
