<template>
  <NavigationBar />
  <NavigationDrawer/>
  <div class="router-wrapper" :style="{ 'margin-left': drawerWidth }">
    <router-view />
  </div>
  <Snackbar v-if="snackbarLabel" :label="snackbarLabel"/>
<!--  <Chat/>-->
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Snackbar from '@/components/Snackbar'
// import Chat from '@/components/Chat'
import NavigationBar from '@/components/NavigationBar'
import NavigationDrawer from '@/components/NavigationDrawer/NavigationDrawer'
import { drawerWidth } from '@/components/NavigationDrawer/drawerState'
// import { userRoleEnum } from '@/enums/userRoleEnum'

export default {
  name: 'App',
  components: {
    NavigationDrawer,
    NavigationBar,
    Snackbar
    // Chat
  },
  setup () {
    const store = useStore()
    const snackbarLabel = computed(() => store.state.snackbarStore.label)

    // const isAdmin = ref(false)

    // onMounted(() => {
    //   const keycloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE, undefined)
    //   isAdmin.value = keycloak.realmAccess.roles.includes(userRoleEnum.ADMIN)
    // })

    return {
      snackbarLabel,
      drawerWidth
    }
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Barlow&display=swap');

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  font-family: Barlow, serif;
}

body {
  font-family: Barlow, serif;
  overflow-x: auto;
  background-color: #f8f8f8;
}

.router-wrapper{
  transition: 0.3s ease;
}

.p-tooltip-text{
  background: #B72937 !important;
}
.p-tooltip-arrow{
  border-top-color: #B72937 !important;
}
</style>
