<template>
  <div class="addressWrapper">
    <Form @submit="onSubmit" @keydown.enter.prevent.self>
      <h3>Dane adresowe</h3>
      <div class="addressGrid">
        <LabeledInput
          name="phoneNumber"
          label="Numer telefonu*"
          rules="required|phoneNumber"
          v-model:modelValue="phoneNumber"
          placeholder="/(format: xxx-xxx-xxx)/"
        />
        <div class="spacer"/>
        <LabeledInput
          name="shippingStreet"
          label="Ulica*"
          rules="required|text"
          v-model:modelValue="shippingStreet"
        />
        <div class="addressGrid addressGridSmall">
          <LabeledInput
            name="shippingBuildingNumber"
            label="Numer domu*"
            rules="required|textNumber"
            v-model:modelValue="shippingBuildingNumber"
          />
          <LabeledInput
            name="shippingApartmentNumber"
            label="Numer mieszkania"
            rules="textNumber"
            v-model:modelValue="shippingApartmentNumber"
          />
        </div>
        <LabeledInput
          name="shippingCity"
          label="Miejscowość*"
          rules="required|text"
          v-model:modelValue="shippingCity"
        />
        <LabeledInput
          name="shippingZipCode"
          label="Kod pocztowy*"
          rules="required|postCode"
          placeholder="/(format: xx-xxx)/"
          v-model:modelValue="shippingZipCode"
        />
      </div>
      <div class="addressFooter">
        <button type="submit">Zapisz zmiany</button>
        <p>* Pole wymagane</p>
      </div>
    </Form>
    <hr/>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { Form } from 'vee-validate'
import LabeledInput from '@/components/LabeledInput'
import { createComputedWrapper } from '@/utils/utils'

export default {
  name: 'AccountAddressContent',
  components: {
    Form,
    LabeledInput
  },
  props: {
    userData: {
      type: Object,
      required: true
    },
    userService: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const userData = computed(() => props.userData)
    const userService = computed(() => props.userService).value
    const initialUserData = ref(userData.value.cloneForAddress())

    const onSubmit = async () => {
      if (userData.value !== false) {
        if (userData.value.isSameMapForAddress(initialUserData.value)) {
          await store.dispatch('snackbarStore/showMessage', 'Próba wysłania tych samych danych!')
          return
        }

        const response = await userService.updateUserData(userData.value.createMapForAddressRequest())

        if (response !== false) {
          initialUserData.value = userData.value.cloneForAddress()
          await store.dispatch('snackbarStore/showMessage', 'Zapisano zmiany!')
        }
      }
    }

    return {
      onSubmit,
      shippingStreet: createComputedWrapper(userData.value.shippingAddress, 'street'),
      shippingBuildingNumber: createComputedWrapper(userData.value.shippingAddress, 'buildingNumber'),
      shippingApartmentNumber: createComputedWrapper(userData.value.shippingAddress, 'apartmentNumber'),
      shippingCity: createComputedWrapper(userData.value.shippingAddress, 'city'),
      shippingZipCode: createComputedWrapper(userData.value.shippingAddress, 'zipCode'),
      phoneNumber: createComputedWrapper(userData.value, 'phoneNumber')
    }
  }
}
</script>

<style lang="scss" scoped>

.addressWrapper{
  h3 {
    color: $brown
  }

  hr {
    width: 90%;
    margin: 20px 0;
  }

  .addressGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2% 2% 2% 2%;
    grid-column-gap: 2%;
  }

  .addressGrid .addressGridSmall {
    padding: 1% 0;
  }

  .addressFooter {
    padding: 0 2%;
  }

  button {
    font-weight: bold;
    height: 2.5em;
    width: 9em;
    background-color: $brown;
    color: white;
    border: none;
    font-size: 1em;
    cursor: pointer;
  }

  button:hover {
    border: none;
    background-color: $dark_brown;
    transition: background-color 0.1s ease-out;
  }

  p {
    margin-top: 20px;
    width: 90%;
    text-align: end;
    font-size: 12px;
    color: $brown;
  }

  @media (max-width: 968px) {
    .spacer {
      display: none;
    }

    hr {
      width: 100%;
    }

    .addressGrid{
      grid-template-columns: 1fr;
      grid-gap: 0;
      padding: 2% 0;
    }
  }
}
</style>
