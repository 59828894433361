<template>
  <div class="p-d-flex p-jc-center newsletter-categories-wrapper" v-if="!isLoading">
    <div class="p-col-12 p-md-11 p-lg-11">
      <div class="buttons-section p-d-flex p-jc-center">
        <Button :icon="state.isNewCategoryNewsletter ? 'pi pi-times' : 'pi pi-plus'" :class="state.isNewCategoryNewsletter ? 'p-button-outlined add-new-newsletter-button' : 'add-new-newsletter-button'" @click="state.isNewCategoryNewsletter = !state.isNewCategoryNewsletter; state.isNewSubcategoryNewsletter = false" type="button" :label="state.isNewCategoryNewsletter ? 'Zamknij' : 'Dodaj kategorię'"/>
        <Button :icon="state.isNewSubcategoryNewsletter ? 'pi pi-times' : 'pi pi-plus'" :class="state.isNewSubcategoryNewsletter ? 'p-button-outlined add-new-newsletter-button p-ml-3' : 'add-new-newsletter-button p-ml-3'" @click="state.isNewSubcategoryNewsletter = !state.isNewSubcategoryNewsletter; state.isNewCategoryNewsletter = false" type="button" :label="state.isNewSubcategoryNewsletter ? 'Zamknij' : 'Dodaj podkategorię'"/>
      </div>
      <div class="new-categories-section" v-if="state.isNewCategoryNewsletter">
        <Form @submit="addNewCategory">
          <h4> NOWA KATEGORIA</h4>
          <div class="new-category">
            <LabeledInput name="newCategory"
                          placeholder="Wpisz nazwę kategorii"
                          rules="required"
                          v-model:modelValue="newCategory"/>
          </div>
          <div class="p-d-flex p-jc-start buttons-section">
            <Button type="submit" label="Dodaj kategorię" :loading="isSaving"/>
          </div>
        </Form>
      </div>
      <div class="new-categories-section" v-if="state.isNewSubcategoryNewsletter">
        <Form v-slot="{ errors }" @submit="addNewSubCategory">
          <div class="p-d-flex p-dir-col">
            <div>
              <h4> KATEGORIA </h4>
              <Field
                v-slot="{ field }"
                v-model="dropDownCategory"
                name="categoriesName"
                value="value"
                rules="required"
              >
                <Dropdown
                  class="p-mt-4 p-mb-4"
                  :options="categoryList"
                  option-value="name"
                  option-label="name"
                  placeholder="Wybierz kategorię"
                  :model-value="field.value"
                  :class="{ 'p-invalid': errors.field }"
                  @input="field.onInput.forEach((fn) => fn($event.value))"
                  @change="field.onChange.forEach((fn) => fn($event.value))"
                />
                <ErrorMessage
                  name="categoriesName"
                  class="error"
                />
              </Field>
            </div>
            <div>
              <h4> NOWA PODKATEGORIA </h4>
              <div class="new-category">
                <LabeledInput name="newCategory"
                              placeholder="Wpisz nazwę podkategorii"
                              rules="required"
                              v-model:modelValue="newSubcategory"/>
              </div>
            </div>
          </div>
          <div class="p-d-flex p-jc-start buttons-section">
            <Button type="submit" label="Dodaj podkategorię" :loading="isSaving"/>
          </div>
        </Form>
      </div>
      <Form @submit="handleSavingNewsletterData">
        <div class="editing-section p-mt-6">
          <h4 style="text-align: center"> KATEGORIE </h4>
          <div v-for="(newsletterCategory, categoryIndex) of newsletterCategories" :key="newsletterCategory.key">
            <div class="p-d-flex p-dir-row p-ai-center">
              <LabeledInput :name="'category' + categoryIndex"
                            placeholder="Wpisz nazwę kategorii"
                            rules="required"
                            :isDisabled="newsletterCategory.disabled"
                            v-model:modelValue="newsletterCategory.name"/>
              <i class="pi pi-pencil p-ml-3" style="font-size: 20px" v-tooltip.top="'EDYTUJ'" @click="editCategory(categoryIndex)"/>
              <i class="pi pi-trash p-ml-3" style="font-size: 20px" v-tooltip.top="'USUŃ'" @click="removeCategory(categoryIndex)"/>
            </div>
            <div v-for="(subcategoryItem, subcategoryIndex) of newsletterCategory.subcategories" :key="subcategoryItem.id">
              <div class="p-d-flex p-dir-row p-ai-center p-ml-6 subcategory-inputs">
                <LabeledInput :name="'subcategory' + subcategoryItem.id"
                              placeholder="Wpisz nazwę pokategorii"
                              rules="required"
                              :isDisabled="subcategoryItem.disabled"
                              v-model:modelValue="subcategoryItem.subcategory"/>
                <i class="pi pi-pencil p-ml-3" style="font-size: 20px" v-tooltip.top="'EDYTUJ'" @click="editSubcategory(categoryIndex, subcategoryIndex)"/>
                <i class="pi pi-trash p-ml-3" style="font-size: 20px" v-tooltip.top="'USUŃ'" @click="removeSubCategory(categoryIndex, subcategoryIndex)"/>
              </div>
            </div>
          </div>
        </div>
        <div class="p-d-flex p-jc-start button-section p-mt-3">
          <Button type="button" @click="handleSavingNewsletterData" label="Zapisz zmiany" :loading="isSaving"/>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import LabeledInput from '@/components/LabeledInput'
import Button from 'primevue/button'
import { ref, onMounted, computed, inject, reactive } from 'vue'
import { useStore } from 'vuex'
import { NewsletterService } from '@/services/newsletterService'
import Tooltip from 'primevue/tooltip'
import Dropdown from 'primevue/dropdown'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { NewsletterCategoriesModel } from '@/models/newsletterCategories'
import { newsletterCategoriesListToMap } from '@/utils/utils'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'NewsletterCategoriesConfigView',
  components: {
    Form,
    Field,
    ErrorMessage,
    LabeledInput,
    Button,
    Dropdown
  },
  directives: {
    tooltip: Tooltip
  },
  setup () {
    const keycloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const store = useStore()
    const newsletterService = new NewsletterService(keycloak)

    const isSaving = ref(false)
    const isLoading = ref(false)

    const state = reactive({
      isNewCategoryNewsletter: false,
      isNewSubcategoryNewsletter: false
    })

    const newSubcategory = ref('')
    const newCategory = ref('')
    const dropDownCategory = ref('')

    // Parse categories for vue templates
    const newslettersCategories = ref(JSON.parse(JSON.stringify(store.state.newsletterStore.categories)))
    const newsletterCategories = computed(() => {
      const newsletterList = []
      for (const [key, value] of Object.entries(newslettersCategories.value.categories)) {
        const subcategoryList = []
        for (const singleSubcategory of value) {
          subcategoryList.push({ id: singleSubcategory.id, subcategory: singleSubcategory.subcategory, disabled: true })
        }
        newsletterList.push(new NewsletterCategoriesModel({ id: uuidv4(), name: key, disabled: true, subcategories: subcategoryList }))
      }

      return newsletterList
    })
    const categoryList = computed(() => {
      const categoryListFromComputed = []
      for (const [key, value] of Object.entries(newsletterCategories.value)) {
        console.log('value w petli:' + key + value.name)
        categoryListFromComputed.push({ name: value.name, id: key })
      }
      return categoryListFromComputed
    })

    onMounted(() => {
      // TODO refactor to separate component for reloading
      isLoading.value = true
      store.dispatch('newsletterStore/fetchNewsletterCategoriesData', { newsletterService: newsletterService }).then(
        async response => {
          isLoading.value = false
        }
      )
    })

    const editCategory = (categoryIndex) => {
      isSaving.value = true
      newsletterCategories.value[categoryIndex].disabled = !newsletterCategories.value[categoryIndex].disabled
      isSaving.value = false
    }

    const editSubcategory = (categoryIndex, subcategoryIndex) => {
      isSaving.value = true

      newsletterCategories.value[categoryIndex].subcategories[subcategoryIndex].disabled = !newsletterCategories.value[categoryIndex].subcategories[subcategoryIndex].disabled
      isSaving.value = false
    }

    const addNewCategory = () => {
      isSaving.value = true
      newsletterCategories.value.push(new NewsletterCategoriesModel({ id: newCategory.value, name: newCategory.value, disabled: true, subcategories: [] }))
      newCategory.value = ''
      state.isNewCategoryNewsletter = !state.isNewCategoryNewsletter
      isSaving.value = false
    }

    const addNewSubCategory = () => {
      isSaving.value = true
      for (let i = 0; i < newsletterCategories.value.length; i++) {
        if (newsletterCategories.value[i].name === dropDownCategory.value) {
          newsletterCategories.value[i].subcategories.push({ subcategory: newSubcategory.value, disabled: true })
        }
      }
      newSubcategory.value = ''
      dropDownCategory.value = ''
      state.isNewSubcategoryNewsletter = !state.isNewSubcategoryNewsletter
      isSaving.value = false
    }

    const removeCategory = (index) => {
      isSaving.value = true
      newsletterCategories.value.splice(index, 1)
      isSaving.value = false
    }

    const removeSubCategory = (categoryIndex, subcategoryIndex) => {
      isSaving.value = true
      newsletterCategories.value[categoryIndex].subcategories.splice(subcategoryIndex, 1)
      isSaving.value = false
    }

    const handleSavingNewsletterData = async () => {
      console.log(newsletterCategories.value)
      isSaving.value = true
      const newsletterCategoriesToSend = await newsletterCategoriesListToMap(newsletterCategories.value)
      await store.dispatch('newsletterStore/sendNewsletterCategories', { newsletterService: newsletterService, newsletterMap: newsletterCategoriesToSend }).then(
        async response => {
          await store.dispatch('snackbarStore/showMessage', 'Wysłano newsletter.')
          isSaving.value = false
        }
      )
    }

    return {
      state,
      newsletterCategories,
      categoryList,
      addNewCategory,
      removeCategory,
      addNewSubCategory,
      removeSubCategory,
      handleSavingNewsletterData,
      newCategory,
      dropDownCategory,
      newSubcategory,
      editCategory,
      editSubcategory,
      isSaving,
      isLoading
    }
  }
}
</script>

<style lang="scss" scoped>
.newsletter-categories-wrapper{
  margin: 15px 15px 5px 15px;
  padding: 30px;
}
.buttons-section{
  .p-button.p-component{
    background-color: black;
    border: 1px solid black;
    color: white;
    padding: 12px 30px;
    &:hover{
      background-color: white;
      border: 1px solid #B72937;
      color: #B72937;
    }
  }

  Button{
    height: 3rem;
  }
}
.new-categories-section{
  margin-top: 15px;
  padding: 30px;
  background-color: white;
}
.editing-section{
  padding: 30px;
  background-color: white;

  .subcategory-inputs{
    .field{
      padding-top: 1%;
      padding-bottom: 1%;
    }
  }
}
</style>
