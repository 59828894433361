import { Product } from '@/models/product'

export class ShoppingCart {
  constructor (shoppingCart) {
    this.id = shoppingCart.id
    this.name = shoppingCart.name
    this.date = shoppingCart.date
    this.products = this.parseProducts(shoppingCart.products)
  }

  parseProducts (products) {
    const productsList = []
    for (const product of products) {
      productsList.push(new Product(product))
    }
    return productsList
  }

  getTotalPrice () {
    if (this.products === null || this.products.length === 0) return 0

    let totalPrice = 0
    this.products.forEach((product) => {
      totalPrice += product.price * product.amount
    })
    return parseFloat(totalPrice.toString()).toFixed(2)
  }
}
