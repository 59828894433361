import { ChatMessage } from '@/models/chatMessage'

export class MessagesList {
  constructor (element) {
    this.count = element.count
    this.chats = this.parseChats(element.chats)
  }

  parseChats (messages) {
    const messagesList = []
    for (const message of messages) {
      messagesList.push(new ChatMessage(message))
    }
    return messagesList
  }
}
