<template>
  <div class="stepTwoWrapper">
    <div class="p-grid p-flex-md-row-reverse" style="padding:0;">

      <!-- FIRST SECTION -->

      <div class="p-col-12 p-md-12 p-lg-3">
        <div class="totalPriceBox">
          <div class="totalPriceHeader">
            <h3>Kwota do zapłaty:</h3>
            <h2>123zł</h2>
          </div>
          <CustomButton class="customButton" label="Do podsumowania" @click="goToNextStep"/>
        </div>
      </div>
      <div class="p-col-12 p-md-12 p-lg-6">
        <div class="contentBox">
          <h3>Wybierz adres</h3>
          <div class="cardsBox">
            <AddressGrid
              :items="addressItems"
              v-model:visible="visibleModal"
              v-model:modelValue="addressItemSelection"
            />
          </div>
        </div>
      </div>
      <div class="p-col-12 p-md-12 p-lg-3">
        <CheckboxList
          title="Zamawiam jako:"
          :items="orderAsItemsCheckbox"
          v-model:modelValue="orderAsValueCheckbox"
        />
      </div>

      <!-- SECOND SECTION -->

      <div class="p-col-12 p-md-12 p-lg-3"></div>
      <div class="p-col-12 p-md-12 p-lg-6">
        <div class="contentBox">
          <h3>Płatność</h3>
          <CheckboxList
            :items="paymentItemsCheckbox"
            v-model:modelValue="paymentValueCheckbox"
          />
        </div>
      </div>
      <div class="p-col-12 p-md-12 p-lg-3">
        <CheckboxList
          title="Sposób dostawy:"
          :items="shipmentItemsCheckbox"
          v-model:modelValue="shipmentValueCheckbox"
        />
      </div>
    </div>
    <button @click="goToPrevStep" class="prevStepButton"><i class="pi pi-angle-left" /> Powrót</button>
  </div>
</template>

<script>
import CheckboxList from '@/components/shopping_cart/step_two/CheckboxList'
import AddressGrid from '@/components/shopping_cart/step_two/AddressGrid'
import CustomButton from '@/components/CustomButton'

import { ref } from 'vue'
import router from '@/router'

export default {
  name: 'ShoppingCartStepTwo',
  components: { CheckboxList, AddressGrid, CustomButton },
  setup () {
    const orderAsItemsCheckbox = [{ key: 'osoba_fizyczna', name: 'Osoba fizyczna' }, { key: 'firma', name: 'Firma' }]
    const orderAsValueCheckbox = ref(orderAsItemsCheckbox[0])
    const shipmentItemsCheckbox = [{ key: 'kurier', name: 'Kurier' }, { key: 'odbior_osobisty', name: 'Odbiór osobisty' }]
    const shipmentValueCheckbox = ref(shipmentItemsCheckbox[0])
    const addressItems = [
      { key: 1, name: 'Adam Kowalski', address: 'Koscielna 5b', cityWithPostcode: '87-100 Toruń', phoneNumber: '721 323 543' },
      { key: 2, name: 'Jan Nowacki', address: 'Mostowa 13/2', cityWithPostcode: '69-100 Warszawa', phoneNumber: '544 342 477' }
    ]
    const addressItemSelection = ref(addressItems[0])
    const paymentItemsCheckbox = [{ key: 'platnosc_przy_odbiorze', name: 'Płatność przy odbiorze' }, { key: 'przelew', name: 'Przelew' }, { key: 'przelew_blyskawiczny', name: 'Przelew błyskawiczny' }, { key: 'karta_platnicza', name: 'Karta płatnicza' }]
    const paymentValueCheckbox = ref(paymentItemsCheckbox[0])

    const visibleModal = ref(false)

    const goToNextStep = async () => {
      // await store.dispatch('shoppingCartStore/setCurrentShoppingCartId', localCurrentShoppingCart.value.id)
      await router.push('/shopping-cart/shopping-summary')
    }

    const goToPrevStep = async () => {
      await router.push('/shopping-cart')
    }

    return {
      orderAsItemsCheckbox,
      orderAsValueCheckbox,
      shipmentItemsCheckbox,
      shipmentValueCheckbox,
      addressItems,
      addressItemSelection,
      paymentItemsCheckbox,
      paymentValueCheckbox,
      visibleModal,
      goToNextStep,
      goToPrevStep
    }
  }
}
</script>

<style lang="scss" scoped>
.stepTwoWrapper {
  background-color: #f8f8f8;
  padding:55px;

  .contentBox {
    background: white;
    border: 1px solid #c8c8c8;
    padding: 15px;

    h3 {
      font-weight: 500;
      color: $brown;
      margin-bottom: 15px;
    }

    .cardsBox {
        padding: 0 15px 15px 15px;
    }
  }

  .totalPriceBox {
    border: 1px solid #c8c8c8;
    background-color: white;
    padding: 15px;

    .totalPriceHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      h3 {
        font-weight: normal;
      }
    }
  }

  .prevStepButton {
    width: 15%;
    height: 40px;
    background-color: white;
    border:1px solid #c8c8c8;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s background-color;
    font-weight: 500;
    font-size: 0.8rem;

    i {
      font-size: 11px;
      margin-right: 5px;
    }

    &:hover {
      background-color: #f8f8f8;
    }
  }

  @media screen and (max-width:768px) {
    padding:15px;

    .prevStepButton {
      width: 100%;
      margin-right: 8px;
    }
  }

  ::v-deep(.p-grid){
    padding:0;
  }
}
</style>
