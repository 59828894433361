<template>
  <div class="p-d-flex p-jc-center">
    <div class="p-d-flex p-dir-col p-ai-center p-col-11 p-m-6 article-wrapper">
      <div v-if="isLoading"></div>
      <div v-else class="p-d-flex p-dir-col p-jc-center p-ai-center p-m-4">
        <p class="p-mt-3 p-mb-3">{{ article.publishDateStart }}</p>
        <h2 class="p-mb-5"> {{ article.title }}</h2>
        <p class="p-mb-2"> {{ article.lead }}</p>
        <div class="html-wrapper" v-html="article.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted } from 'vue'
import { ArticleService } from '@/services/articleService'

export default {
  name: 'ArticleView',
  props: {
    articleId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const keyCloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const articleService = new ArticleService(keyCloak)
    const isLoading = ref(true)
    const article = ref()

    onMounted(async () => {
      isLoading.value = true
      await articleService.fetchArticle(props.articleId).then(response => {
        article.value = response
        isLoading.value = false
      })
    })

    return {
      isLoading,
      article
    }
  }
}
</script>

<style lang="scss" scoped>
html-wrapper{
  object-fit: contain;
  max-width: 500px;
  max-height: 500px;
}
.article-wrapper{
  background-color: white;
}
</style>
