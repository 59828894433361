<template>
  <div class="p-col-12 p-md-10 p-lg-11 newsletter-wrapper">
    Dokumenty
  </div>
</template>

<script>

export default {
  name: 'DocumentsView',
  setup (props) {
  }
}
</script>

<style lang="scss" scoped>
body{
  background-color: $light_grey;
}
</style>
