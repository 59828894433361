let timer

const state = {
  label: ''
}

const getters = {}

const actions = {
  showMessage: (context, payload) => {
    context.commit('ADD_MESSAGE', payload)

    if (timer !== undefined) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
      context.commit('REMOVE_MESSAGE')
      timer = undefined
    }, 3000)
  }
}

const mutations = {
  ADD_MESSAGE: (state, payload) => {
    state.label = payload
  },
  REMOVE_MESSAGE: (state) => {
    state.label = ''
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
