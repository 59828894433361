<template>
  <div class="FAQ-Item">
    <form @submit.prevent="editFAQItem">
    <div @click="state.is_show = !state.is_show" class="FAQ-Item_Header">
      <div style="width: calc(100% - 90px)">
        <div class="FAQ-Item_Header-Title" v-bind:class="{Bold: state.is_show || state.is_edit}">
          <span v-show="!state.is_edit">{{faq_item.question}}</span>
          <textarea id="question-edit-area" rows="1" v-show="state.is_edit" v-model="state.tmp_question"></textarea>
        </div>
        <div class="FAQ-Item_Answer" v-bind:class="state.is_show || state.is_edit ? 'Open' : 'Closed'">
          <span v-show="!state.is_edit">{{faq_item.answer}}</span>
          <textarea id="answer-edit-area" rows="5" v-if="state.is_edit" v-model="state.tmp_answer"></textarea>
          <div class="FAQ-Item_Answer-Buttons">
            <Button icon="pi pi-undo" label="Wyczyść" @click="clearFAQBoxes" v-if="state.is_edit" />
            <Button class="SaveFAQButton" label="Zapisz zmiany" @click="saveFAQItem" v-if="state.is_edit" />
          </div>
        </div>
      </div>
      <div style="display: flex; align-items: start">
        <div class="FAQ-Item_Header-Icons">
          <i class="pi pi-pencil" v-tooltip.top="'EDYTUJ'" @click="editFAQItem"/>
          <i class="pi pi-trash" v-tooltip.top="'USUŃ'" @click="deleteItem"/>
        </div>
      </div>
    </div>
    </form>
  </div>
</template>

<script>
import { reactive } from 'vue'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'
export default {
  name: 'faqAdminItem',
  components: {
    Button
  },
  directives: {
    tooltip: Tooltip
  },
  props: {
    faq_item: {
      type: Object,
      required: true
    }
  },

  setup (props, context) {
    const state = reactive({
      is_show: false,
      is_edit: false,
      tmp_question: '',
      tmp_answer: ''
    })

    function deleteItem () {
      context.emit('remove', props.faq_item)
    }

    function saveFAQItem () {
      if (state.tmp_question && state.tmp_answer) {
        context.emit('edit', { id: props.faq_item.id, question: state.tmp_question, answer: state.tmp_answer })
        state.tmp_question = ''
        state.tmp_answer = ''
        state.is_edit = false
      }
    }

    const editFAQItem = () => {
      state.tmp_question = props.faq_item.question
      state.tmp_answer = props.faq_item.answer
      state.is_edit = !state.is_edit; state.is_show = true
    }

    const clearFAQBoxes = () => {
      state.tmp_question = ''
      state.tmp_answer = ''
    }

    return {
      state,
      deleteItem,
      saveFAQItem,
      editFAQItem,
      clearFAQBoxes
    }
  }
}

</script>

<style lang="scss" scoped>
.FAQ-Item{
  margin: 20px 0;
  cursor: pointer;
  padding: 10px 30px;
  width: 100%;
  max-width: 100%;
  background-color: white;
  transition: all 0.25s ease-in-out;
  @media only screen and (max-width: 768px){
    padding: 5px 15px;
  }
  .FAQ-Item_Header{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    @media only screen and (max-width: 768px){
      padding: 5px 0;
    }
    textarea{
      width: 100%;
      padding: 8px;
      margin: 10px 0;
      border: 1px solid #B72937;
      resize: none;
      &:focus{
        outline-style: solid;
        box-shadow: #B72937;
      }
    }
    .FAQ-Item_Header-Title{
      min-width: 100%;
      width: calc(100% - 90px);
      word-wrap: break-word;
      font-weight: normal;
      font-size: 20px;
      @media only screen and (max-width: 768px){
        font-size: 15px;
      }
      textarea{
        max-height: 45px;
        font-weight: bold;
        font-size: 20px;
        @media only screen and (max-width: 768px){
          font-size: 15px;
        }
      }
    }
    .Bold{
      font-weight: bold;
    }
    .FAQ-Item_Header-Icons{
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      height: 100%;
      width: 90px;
      i{
        cursor: pointer;
        margin: 10px;
        font-size: 20px;
        &:hover{
          color: #B72937;
        }
      }
    }
  }

  .FAQ-Item_Answer{
    font-size: 17px;
    white-space: pre-wrap;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    min-width: 100%;
    word-wrap: break-word;
    @media only screen and (max-width: 768px){
      font-size: 15px;
    }
    textarea{
      font-size: 15px;
    }
    .p-button.p-component{
      background-color: black;
      border: 1px solid black;
      color: white;
      padding: 12px 30px;
      margin: 0 20px 0 0;
      &:hover{
        background-color: white;
        border: 1px solid #B72937;
        color: #B72937;
      }
    }
    .FAQ-Item_Answer-Buttons{
      margin-top: 10px;
      .SaveFAQButton{
        background-color: #B72937;
        border: 1px solid #B72937;
      }
      @media only screen and (max-width: 525px){
        display: flex;
        justify-content: center;
        .p-button.p-component{
          margin: 0 5px;
          padding: 10px;
          width: 120px;
          font-size: 10px;
        }
        ::v-deep(.p-button .p-button-icon-left){
          margin: 2px;
          font-size: 10px;
        }
      }
    }
  }
  .Closed{
    height: 0;
    overflow: hidden;
    padding: 0 0;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
  }
  .Open{
    height: auto;
    padding: 20px 0;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    @media only screen and (max-width: 768px){
      padding: 10px 0 0 0;
    }
  }
}

textarea{
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  resize: none;
}

</style>
