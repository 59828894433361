<template>
  <div class="stepThreeWrapper">
    <div class="p-grid p-flex-row-reverse">
      <div class="p-col-12 p-md-4 leftPadding30px">
        <ShoppingCartStepThreeCardColumn />
      </div>
      <div class="p-col-12 p-md-8">
        <ShoppingCartStepThreeTableColumn />
      </div>
    </div>
    <button @click="goToPrevStep" class="prevStepButton"><i class="pi pi-angle-left" /> Powrót</button>
  </div>
</template>

<script>
import ShoppingCartStepThreeTableColumn from '@/components/shopping_cart/step_three/ShoppingCartStepThreeTableColumn'
import ShoppingCartStepThreeCardColumn from '@/components/shopping_cart/step_three/ShoppingCartStepThreeCardColumn'
import router from '@/router'

export default {
  name: 'ShoppingCartStepThree',
  components: { ShoppingCartStepThreeTableColumn, ShoppingCartStepThreeCardColumn },
  setup () {
    const goToPrevStep = async () => {
      await router.push('/shopping-cart/shopping-invoice')
    }

    return { goToPrevStep }
  }
}
</script>

<style lang="scss" scoped>
.stepThreeWrapper {
  padding: 55px;
  margin: 0;
  background-color: #f8f8f8;

  .leftPadding30px {
    padding-left: 30px;
  }

  .prevStepButton {
    margin-top:22px;
    width: 15%;
    height: 40px;
    background-color: white;
    border:1px solid #c8c8c8;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.5s background-color;
    font-weight: 500;
    font-size: 0.8rem;

    i {
      font-size: 11px;
      margin-right: 5px;
    }

    &:hover {
      background-color: #f8f8f8;
    }
  }

  @media screen and (max-width:768px) {
    padding:15px;

    .prevStepButton {
      width: 100%;
      margin-right: 8px;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 15px;

    .leftPadding30px {
      padding-left: 8px;
    }
  }
}
</style>
