
const state = {
  regulations: [],
  newsletterRegulation: null
}

const getters = {
}

const actions = {
  async fetchNewsletterRegulationById ({ commit }, payload) {
    return payload.regulationsService.fetchNewsletterRegulationsData(payload.regulationId).then(
      response => {
        commit('fetchNewsletterRegulationByIdSuccess', response.data)
        return response
      },
      error => {
        commit('fetchNewsletterRegulationByIdFailure', error)
      }
    )
  }
}

const mutations = {
  fetchNewsletterRegulationByIdSuccess (state, data) {
    state.newsletterRegulation = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
