const state = {
  faqCategory: []
}

const mutations = {
  FETCH_FAQ (state, data) {
    state.faqCategory = data
  },
  UPDATE_FAQ_LIST (state, payload) {
    state.faqCategory[findIndexById(payload.id)].faqDtoList = payload.value
  },
  ADD_ITEM (state, payload) {
    state.faqCategory[findIndexById(payload.id)].faqDtoList.push(payload.item)
  },
  EDIT_ITEM (state, payload) {
    state.faqCategory[findIndexById(payload.id)].faqDtoList[payload.itemIndex].question = payload.item.question
    state.faqCategory[findIndexById(payload.id)].faqDtoList[payload.itemIndex].answer = payload.item.answer
  },
  REMOVE_ITEM (state, payload) {
    state.faqCategory[findIndexById(payload.id)].faqDtoList.splice(payload.itemIndex, 1)
  },
  UPDATE_CATEGORY_LIST (state, value) {
    state.faqCategory = value
  },
  ADD_CATEGORY (state, item) {
    state.faqCategory.push(item)
  },
  EDIT_CATEGORY (state, payload) {
    state.faqCategory[findIndexById(payload.id)].name = payload.name
  },
  REMOVE_CATEGORY (state, id) {
    state.faqCategory.splice(findIndexById(id), 1)
  },
  UPDATE_ALL_ORDERS (state) {
    state.faqCategory.forEach((element, index) => {
      element.order = index
      element.faqDtoList.forEach((element, index) => {
        element.order = index
      })
    })
  }
}

const actions = {
  fetchFromServer ({ commit }, faqService) {
    return faqService.fetchFaqData().then(data => {
      commit('FETCH_FAQ', data)
      return Promise.resolve(data)
    }, error => {
      return Promise.reject(error)
    })
  },
  sendToServer ({ commit }, payload) {
    return payload.faqService.sendFaqData(payload.data).then(response => {
      return Promise.resolve(response)
    }, error => {
      return Promise.reject(error)
    })
  },
  updateFAQList ({ commit }, payload) {
    commit('UPDATE_FAQ_LIST', payload)
  },
  addItem ({ commit }, payload) {
    commit('ADD_ITEM', payload)
  },
  editItem ({ commit }, payload) {
    commit('EDIT_ITEM', payload)
  },
  removeItem ({ commit }, payload) {
    commit('REMOVE_ITEM', payload)
  },
  updateCategoryList ({ commit }, value) {
    commit('UPDATE_CATEGORY_LIST', value)
  },
  addCategory ({ commit }, newCategory) {
    commit('ADD_CATEGORY', newCategory)
  },
  editCategory ({ commit }, payload) {
    commit('EDIT_CATEGORY', payload)
  },
  removeCategory ({ commit }, id) {
    commit('REMOVE_CATEGORY', id)
  },
  updateAllOrders ({ commit }) {
    commit('UPDATE_ALL_ORDERS')
  }
}

const findIndexById = (id) => {
  return state.faqCategory.findIndex(element => element.id === id)
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  findIndexById
}
