import { parseDateArray } from '@/utils/utils'

export class ChatMessage {
  constructor (element) {
    this.id = element.id
    this.status = element.status
    this.read = element.read
    this.lastUpdateDate = parseDateArray(element.lastUpdateDate)
    this.startDate = parseDateArray(element.startDate)
  }
}
