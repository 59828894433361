<template>
    <svg xmlns="http://www.w3.org/2000/svg"
         :width="width"
         :height="height"
         :viewBox="viewBox"
         :aria-labelledby="iconName"
    >
      <g :fill="iconColor" :transform="transformCords">
        <slot />
      </g>
    </svg>
</template>

<script>
export default {
  name: 'CustomIcon',
  props: {
    viewBox: {
      required: true,
      type: String
    },
    transformCords: {
      required: false,
      type: String
    },
    width: {
      required: true,
      type: String
    },
    height: {
      required: true,
      type: String
    },
    iconName: {
      required: true,
      type: String
    },
    iconColor: {
      required: true,
      type: String
    }
  },
  setup () {
    return { }
  }
}
</script>

<style scoped>

</style>
