<template>
  <div class="invoiceWrapper">
    <Form @submit="onSubmit" @keydown.enter.prevent.self>
      <h3>Dane do faktury</h3>
      <div class="invoiceGrid">
        <LabeledInput
          name="company"
          label="Nazwa firmy*"
          rules="required|textNumber"
          v-model:modelValue="company"
        />
        <LabeledInput
          name="nip"
          label="NIP*"
          rules="required|fiscalCode"
          v-model:modelValue="fiscalCode"
          placeholder="/(format: xxx-xxx-xx-xx)/"
        />
        <LabeledInput
          name="invoiceStreet"
          label="Ulica*"
          rules="required|text"
          v-model:modelValue="invoiceStreet"
        />
        <div class="invoiceGrid invoiceGridSmall">
          <LabeledInput
            name="invoiceBuildingNumber"
            label="Numer domu*"
            rules="required|textNumber"
            v-model:modelValue="invoiceBuildingNumber"
          />
          <LabeledInput
            name="invoiceApartmentNumber"
            label="Numer mieszkania"
            rules="textNumber"
            v-model:modelValue="invoiceApartmentNumber"
          />
        </div>
        <LabeledInput
          name="invoiceCity"
          label="Miejscowość*"
          rules="required|text"
          v-model:modelValue="invoiceCity"
        />
        <LabeledInput
          name="invoiceZipCode"
          label="Kod pocztowy*"
          rules="required|postCode"
          placeholder="/(format: xx-xxx)/"
          v-model:modelValue="invoiceZipCode"
        />
      </div>
      <div class="invoiceFooter">
        <button type="submit">Zapisz zmiany</button>
        <p>* Pole wymagane</p>
      </div>
    </Form>
    <hr/>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { Form } from 'vee-validate'
import LabeledInput from '@/components/LabeledInput'
import { createComputedWrapper } from '@/utils/utils'

export default {
  name: 'AccountInvoiceContent',
  components: {
    Form,
    LabeledInput
  },
  props: {
    userData: {
      type: Object,
      required: true
    },
    userService: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const userData = computed(() => props.userData)
    const userService = computed(() => props.userService).value
    const initialUserData = ref(userData.value.cloneForInvoice())

    const onSubmit = async () => {
      if (userData.value !== false) {
        if (userData.value.isSameMapForInvoice(initialUserData.value)) {
          await store.dispatch('snackbarStore/showMessage', 'Próba wysłania tych samych danych!')
          return
        }

        const response = await userService.updateUserData(userData.value.createMapForInvoiceRequest())

        if (response !== false) {
          initialUserData.value = userData.value.cloneForInvoice()
          await store.dispatch('snackbarStore/showMessage', 'Zapisano zmiany!')
        }
      }
    }

    return {
      onSubmit,
      invoiceStreet: createComputedWrapper(userData.value.invoiceAddress, 'street'),
      invoiceBuildingNumber: createComputedWrapper(userData.value.invoiceAddress, 'buildingNumber'),
      invoiceApartmentNumber: createComputedWrapper(userData.value.invoiceAddress, 'apartmentNumber'),
      invoiceCity: createComputedWrapper(userData.value.invoiceAddress, 'city'),
      invoiceZipCode: createComputedWrapper(userData.value.invoiceAddress, 'zipCode'),
      company: createComputedWrapper(userData.value, 'company'),
      fiscalCode: createComputedWrapper(userData.value, 'fiscalCode')
    }
  }
}
</script>

<style lang="scss" scoped>

.invoiceWrapper{
  h3 {
    color: $brown
  }

  hr {
    width: 90%;
    margin: 20px 0;
  }

  .invoiceGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 2% 2% 2% 2%;
    grid-column-gap: 2%;
  }

  .invoiceGrid .invoiceGridSmall {
    padding: 1% 0;
  }

  .invoiceFooter {
    padding: 0 2%;
  }

  button {
    font-weight: bold;
    height: 2.5em;
    width: 9em;
    background-color: $brown;
    color: white;
    border: none;
    font-size: 1em;
    cursor: pointer;
  }

  button:hover {
    border: none;
    background-color: $dark_brown;
    transition: background-color 0.1s ease-out;
  }

  p {
    margin-top: 20px;
    width: 90%;
    text-align: end;
    font-size: 12px;
    color: $brown;
  }

  @media (max-width: 968px) {
    hr {
      width: 100%;
    }

    .invoiceGrid{
      grid-template-columns: 1fr;
      grid-gap: 0;
      padding: 2% 0;
    }
  }
}
</style>
