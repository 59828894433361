import { ref, computed } from 'vue'

export const collapsed = ref(false)
export const toggleDrawer = () => (collapsed.value = !collapsed.value)

export const DRAWER_WIDTH = 300
export const DRAWER_WIDTH_COLLAPSED = 100
export const drawerWidth = computed(
  () => {
    const mediaQuery = window.matchMedia('(min-width: 768px)')
    const width = ref('300')

    function handleTabletChange (e) {
      if (e.matches) {
        width.value = `${collapsed.value ? DRAWER_WIDTH_COLLAPSED : DRAWER_WIDTH}px`
      } else {
        width.value = '0'
      }
    }

    mediaQuery.addEventListener('change', handleTabletChange)
    handleTabletChange(mediaQuery)

    return width.value
  }
)
