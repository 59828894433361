<template>
  <div class="p-d-flex p-jc-center">
    <div v-if="isLoading"></div>
    <div v-else class="p-col-12 p-md-11 p-lg-11 newsletter-wrapper">
      <div class="buttons-section p-d-flex p-jc-center p-mt-3 p-mb-3">
        <Button :icon="isNewCarouselItem ? 'pi pi-times' : 'pi pi-plus'" :class="isNewCarouselItem ? 'p-button-outlined add-new-newsletter-button' : 'add-new-newsletter-button'" @click="handleAddingCarouselItem" type="button" :label="isNewCarouselItem ? 'Zamknij' : 'Dodaj zdjęcie do karuzeli'"/>
      </div>
      <div class="p-d-flex p-flex-row p-jc-center newsletter-cards-box">
        <div v-for="(link, index) in carouselLinks"
             :key="index">
<!--          <p>{{ link.thumbnail }}</p>-->
<!--          <div-->
<!--            class="image"-->
<!--            :style="{backgroundImage:`url(${link.thumbnail})`}">-->
<!--          </div>-->
<!--          <img :src="link.thumbnail"/>-->
          <!--          <NewsletterCard :selectedId="selectedId" @selectNewsletter="selectNewsletterData" @deleteNewsletter="handleDeleteNewsletterData"  :index="index" :newsletter="newsletter"/>-->
        </div>
      </div>
      <div class="newsletter-config-wrapper" v-if="isSelected">
        <h3 style="text-align: center">Dodawanie zdjęcia</h3>
        <FileUpload class="p-mt-3 p-mb-3" :customUpload="true" @uploader="customUpload" accept="image/*" :maxFileSize="2000000" :auto="true" :fileLimit="1" invalidFileSizeMessage="{0}: Nieprawidłowy rozmiar pliku, plik nie może przekraczać {1}" invalidFileLimitMessage="Przekroczono maksymalną ilość plików" :showUploadButton="false" :showCancelButton="false" chooseLabel="Przeglądaj" uploadLabel="Wyślij" cancelLabel="Anuluj"/>
        <div class="p-d-flex p-dir-row p-jc-between">
          <div class="p-col-6">
            <h4>Wprowadź zewnętrzny adres</h4>
            <LabeledInput
              name="custom_url_input"
              rules="required"
              type="text"
              v-model:modelValue="currentlyEditing.url"
              placeholder="Wpisz adres URL przycisku"
            />
          </div>
          <div class="p-col-5">
            <h4>Wybierz artykuł</h4>
            <Dropdown class="p-mt-4" v-model="currentlyEditing.articleId" :options="articlesList.articles" optionLabel="title" :filter="true" placeholder="Wybierz artykuł" :showClear="true">
              <template #value="slotProps">
                <div v-if="slotProps.value">
                  <div>{{slotProps.value.id}}</div>
                </div>
                <span v-else>
                      {{slotProps.placeholder}}
                  </span>
              </template>
              <template #option="slotProps">
                <div>{{slotProps.option.title}}</div>
              </template>
            </Dropdown>
          </div>
        </div>

        <div class="p-d-flex p-jc-start button-section">
          <Button type="button" @click="handleSavingCarouselData" label="Zapisz zmiany" :loading="isSaving"/>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { onMounted, inject, ref, reactive } from 'vue'
import { CarouselService } from '@/services/carouselService'
import FileUpload from 'primevue/fileupload'
import LabeledInput from '@/components/LabeledInput'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import { ArticleService } from '@/services/articleService'
import { useStore } from 'vuex'

export default {
  name: 'carouselConfigView',
  components: {
    FileUpload,
    Button,
    LabeledInput,
    Dropdown
  },
  setup () {
    const keycloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const carouselService = new CarouselService(keycloak)
    const articleService = new ArticleService(keycloak)
    const store = useStore()

    const isLoading = ref(true)
    const isSaving = ref(false)

    const isSelected = ref(false)
    const isNewCarouselItem = ref(false)

    const carouselLinks = ref()
    const articlesList = ref()

    const selectedId = ref()

    const currentlyEditing = reactive({
      articleId: '',
      id: '',
      imageId: '',
      url: ''
    })

    onMounted(async () => {
      isLoading.value = true
      await carouselService.fetchCarouselImages().then(res => {
        carouselLinks.value = res
      })

      // for (const link of carouselLinks.value.links) {
      //   carouselService.fetchCarouselThumbnail(link.id).then(response => {
      //     const binaryData = []
      //     binaryData.push(response.data)
      //
      //     link.thumbnail = URL.createObjectURL(new Blob([binaryData]))
      //     console.log(link.thumbnail)
      //   })
      // }

      await articleService.fetchAdminArticles(0, 9999).then(res => {
        articlesList.value = res
        console.log(articlesList.value)
        isLoading.value = false
      })
    })

    const handleAddingCarouselItem = async () => {
      if (isNewCarouselItem.value) {
        isSelected.value = false
        isNewCarouselItem.value = !isNewCarouselItem.value
      } else {
        isSelected.value = false
        isNewCarouselItem.value = true
        selectedId.value = ''
        await clearCarouselData()
        isSelected.value = true
      }
    }

    const clearCarouselData = () => {
      currentlyEditing.articleId = ''
      currentlyEditing.id = ''
      currentlyEditing.imageId = ''
      currentlyEditing.url = ''
    }

    const customUpload = async (event) => {
      isSaving.value = true
      const file = event.files[0]

      const formData = new FormData()
      formData.append('file', file)
      formData.append('fileName', file.name)
      formData.append('id', file.name)

      await carouselService.postCarouselImage(formData).then(res => {
        currentlyEditing.imageId = ''
        isSaving.value = false
      },
      (error) => {
        console.log(error)
        store.dispatch('snackbarStore/showMessage', 'Wystąpił błąd. Proszę, wybrać inny obraz.')
        isSaving.value = false
      })
    }

    const handleSavingCarouselData = async () => {
      isSaving.value = true
      console.log(currentlyEditing)
      carouselLinks.value.links.push(currentlyEditing)
      await carouselService.putCarouselImages(carouselLinks).then(res => {
        isSaving.value = false
      })
    }

    return {
      handleSavingCarouselData,
      handleAddingCarouselItem,
      currentlyEditing,
      articlesList,
      carouselLinks,
      customUpload,
      isLoading,
      isSaving,
      isSelected,
      isNewCarouselItem
    }
  }
}
</script>

<style lang="scss" scoped>
.newsletter-categories-wrapper{
  margin: 15px 15px 5px 15px;
  padding: 30px;
}
.image{
  width: 200px;
  height: 200px;
}
.buttons-section{
  .p-button.p-component{
    background-color: black;
    border: 1px solid black;
    color: white;
    padding: 12px 30px;
    &:hover{
      background-color: white;
      border: 1px solid #B72937;
      color: #B72937;
    }
  }

  Button{
    height: 3rem;
  }
}
.new-categories-section{
  margin-top: 15px;
  padding: 30px;
  background-color: white;
}
.editing-section{
  padding: 30px;
  background-color: white;

  .subcategory-inputs{
    .field{
      padding-top: 1%;
      padding-bottom: 1%;
    }
  }
}
</style>
