import { createStore } from 'vuex'
import keycloakStore from '@/store/modules/keycloakStore'
import snackbarStore from '@/store/modules/snackbarStore'
import shoppingCartStore from '@/store/modules/shoppingCartStore'
import newsletterStore from '@/store/modules/newsletterStore'
import faqStore from '@/store/modules/faqStore'
import regulationsStore from '@/store/modules/regulationsStore'

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  modules: {
    keycloakStore,
    snackbarStore,
    shoppingCartStore,
    newsletterStore,
    faqStore,
    regulationsStore
  },
  strict: debug
})
