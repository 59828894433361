<template>
  <div class="p-col-12 p-md-10 p-lg-11 newsletter-wrapper">
    <div v-if="!isLoading">
      <NewsletterProfileComponent :userSubscriptions="userSubscriptions" :isSaving="isSaving" :newslettersCategories="newslettersCategories" :userProfile="userProfile" @showRegulationsError="showRegulationsError" @sendEmailSubscribeData="subscribeToNewsletter" @sendEmailUnsubscribeData="unsubscribeToNewsletter"/>
    </div>
  </div>
</template>

<script>
import { ref, inject, computed, onMounted } from 'vue'
import NewsletterProfileComponent from '@/components/NewsletterProfileComponent'
import { useStore } from 'vuex'
import { NewsletterService } from '@/services/newsletterService'
import { UserService } from '@/services/userService'
import { parseUserSubscriptionsMap } from '@/utils/utils'
import { RegulationsService } from '@/services/regulationsService'
export default {
  name: 'NewsletterView',
  components: { NewsletterProfileComponent },
  setup (props) {
    const keycloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const store = useStore()
    const newsletterService = new NewsletterService(keycloak)
    const regulationsService = new RegulationsService(keycloak)
    const userService = new UserService(keycloak)

    const isLoading = ref(true)
    const isSaving = ref(false)
    const userProfile = ref('')
    const userSubscriptions = ref()
    const newslettersCategories = computed(() => store.state.newsletterStore.categories)

    onMounted(async () => {
      isLoading.value = true

      userProfile.value = await keycloak.loadUserProfile()
      await store.dispatch('newsletterStore/fetchNewsletterCategoriesData', { newsletterService: newsletterService })
      await store.dispatch('regulationsStore/fetchNewsletterRegulationById', { regulationsService: regulationsService, regulationId: 1 })
      const subscriptionRequest = await userService.fetchActiveSubscriptions()
      userSubscriptions.value = await parseUserSubscriptionsMap(subscriptionRequest)
      isLoading.value = false
    })

    const subscribeToNewsletter = async (userMap) => {
      const response = await userService.subscribeToNewsletter(userMap)

      if (response !== false) {
        await store.dispatch('snackbarStore/showMessage', 'Zapisano do newslettera!')
      }
    }
    const unsubscribeToNewsletter = async (userMap) => {
      const response = await userService.unsubscribeToNewsletter(userMap)

      if (response !== false) {
        await store.dispatch('snackbarStore/showMessage', 'Wypisano z newslettera!')
      }
    }
    const showRegulationsError = async () => {
      await store.dispatch('snackbarStore/showMessage', 'Zaakceptuj regulamin aby zapisać się do newslettera!')
    }

    return {
      newslettersCategories,
      showRegulationsError,
      subscribeToNewsletter,
      unsubscribeToNewsletter,
      isLoading,
      userSubscriptions,
      userProfile,
      isSaving
    }
  }
}
</script>

<style lang="scss" scoped>
body{
  background-color: $light_grey;
}
</style>
