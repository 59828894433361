import { CarouselImage } from '@/models/carouselImage'

export class CarouselLinks {
  constructor (images) {
    this.links = this.parseLinks(images.links)
  }

  parseLinks (images) {
    const imagesList = []
    for (const image of images) {
      imagesList.push(new CarouselImage(image))
    }
    return imagesList
  }
}
