<template>
  <div class="accountWrapper">
    <div class="mobile-top-bar">
      <div class="navigation">
        <span
          @click="dataReady ? handleChangeContent(accountContentEnum.ACCOUNT_SETTINGS) : null"
          :class="accountContent === accountContentEnum.ACCOUNT_SETTINGS ? 'active' : ''">
          Ustawienia konta
        </span>
        <div>&nbsp;|&nbsp;</div>
        <span
          :class="accountContent === accountContentEnum.ACCOUNT_ADDRESS ? 'active' : ''"
          @click="dataReady ? handleChangeContent(accountContentEnum.ACCOUNT_ADDRESS) : null">
          Dane adresowe
        </span>
        <div>&nbsp;|&nbsp;</div>
        <span
          :class="accountContent === accountContentEnum.ACCOUNT_INVOICE ? 'active' : ''"
          @click="dataReady ? handleChangeContent(accountContentEnum.ACCOUNT_INVOICE) : null">
          Dane do faktury
        </span>
      </div>
    </div>
    <div class="titleContainer">
      <div class="title">
        <h1>Moje konto</h1>
      </div>
      <div class="logout" >
        <span @click="logout">Wyloguj się</span>
      </div>
    </div>
    <div class="container">
      <div class="navigation">
        <div class="navigation-container">
          <div
            class="navigation-item"
            :class="accountContent === accountContentEnum.ACCOUNT_SETTINGS ? 'active' : ''"
            @click="dataReady ? handleChangeContent(accountContentEnum.ACCOUNT_SETTINGS) : null">
            USTAWIENIA KONTA
          </div>
          <div
            class="navigation-item"
            :class="accountContent === accountContentEnum.ACCOUNT_ADDRESS ? 'active' : ''"
            @click="dataReady ? handleChangeContent(accountContentEnum.ACCOUNT_ADDRESS) : null">
            DANE ADRESOWE
          </div>
          <div
            class="navigation-item"
            :class="accountContent === accountContentEnum.ACCOUNT_INVOICE ? 'active' : ''"
            @click="dataReady ? handleChangeContent(accountContentEnum.ACCOUNT_INVOICE) : null">
            DANE DO FAKTURY
          </div>
        </div>
      </div>
      <div class="content">
        <p v-if="!dataReady">
          Wczytywanie danych...
        </p>
        <AccountSettingsContent
          v-else-if="accountContent === accountContentEnum.ACCOUNT_SETTINGS"
          :userData="userData"
          :userService="userService"
        />
        <AccountAddressContent
          v-else-if="accountContent === accountContentEnum.ACCOUNT_ADDRESS"
          :userData="userData"
          :userService="userService"
        />
        <AccountInvoiceContent
          v-else-if="accountContent === accountContentEnum.ACCOUNT_INVOICE"
          :userData="userData"
          :userService="userService"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { inject, ref, onMounted } from 'vue'
import { UserService } from '@/services/userService'
import { UserData } from '@/models/userData'
import AccountSettingsContent from '@/components/account/AccountSettingsContent'
import AccountAddressContent from '@/components/account/AccountAddressContent'
import AccountInvoiceContent from '@/components/account/AccountInvoiceContent'
import { accountContentEnum } from '@/enums/accountContentEnum'
import router from '@/router'

export default {
  name: 'AccountView',
  components: {
    AccountAddressContent,
    AccountSettingsContent,
    AccountInvoiceContent
  },
  setup () {
    const keyCloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const userService = new UserService(keyCloak)
    const dataReady = ref(false)
    const userData = ref(new UserData())
    const accountContent = ref()

    onMounted(async () => {
      await userService.readUserData().then(async (value) => {
        await new Promise(resolve => setTimeout(resolve, 1000))
        userData.value = value
        dataReady.value = true
        accountContent.value = accountContentEnum.ACCOUNT_SETTINGS
      })
    })

    const logout = () => {
      keyCloak.logout()
      router.push({ name: 'Home' })
    }

    const handleChangeContent = (content) => {
      accountContent.value = content
    }

    return {
      dataReady,
      userService,
      userData,
      accountContent,
      accountContentEnum,
      handleChangeContent,
      logout
    }
  }
}
</script>

<style lang="scss" scoped>
.accountWrapper {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  position: relative;
}

hr {
  border: none;
  background-color: $brown;
  color: $brown;
  height: 1px;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-content: flex-end;
}

.titleContainer {
  display: flex;
  flex-direction: row;

  .title {
    padding-left: 4%;
    padding-top: 2%;
    order: 1;
    flex-basis: 30%;
    color: #707070;
    background-color: $light_brown;

    h1 {
      margin-bottom: 2%;
      font-size: 42px;
    }

    hr {
      width: 94%;
      position: absolute;
    }
  }

  .logout {
    padding: 0 4% 1% 0;
    margin-bottom: 2%;
    display: flex;
    order: 2;
    flex-basis: 70%;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    span {
      cursor: pointer;
      font-weight: bold;
      color: $brown;
    }
  }

  @media (max-width: 968px) {
    display: none;
  }
}

.navigation {
  padding-left: 5%;
  padding-top: 2%;
  order: 1;
  background-color: $light_brown;
  flex-basis: 30%;

  @media (max-width: 968px) {
    display: none;
  }

  .navigation-container {
    display: flex;
    flex-direction: column;

    .navigation-item {
      color: $grey;
      font-size: 20px;
      cursor: pointer;
      margin-bottom: 5%;
      padding-bottom: 2%;
      font-weight: 400;
    }

    .navigation-item:hover {
      color: $brown;
    }

    .active {
      width: 100%;
      font-weight: bold;
      color: $brown;
      border-bottom: 1px solid transparent;
      border-image: linear-gradient(to right, $brown 80%, $light_brown 20%) 1;
    }
  }
}

.mobile-top-bar {
  display: none;
  background-color: $light_brown;

  @media (max-width: 968px) {
    display: flex;
    flex-direction: column;
  }

  .logout {
    display: flex;
    padding: 1% 4% 1% 0;
    align-items: flex-end;
    justify-content: flex-end;

    span {
      background-color: transparent;
      border: none;
      color: $brown;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .navigation {
    padding: 6% 4% 2% 4%;
    display: flex;
    color: $brown;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: white;

    span {
      cursor: pointer;
    }

    .active {
      font-weight: bold;
    }
  }
}

.content {
  padding: 2% 4% 0 4%;
  order: 2;
  flex-basis: 70%;

  @media (max-width: 968px) {
    flex-basis: 100%;
  }
}

</style>
