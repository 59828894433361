<template>
  <div class="p-d-flex p-jc-center">
    <div class="p-col-11 p-mt-6 new-article-wrapper">
      <Form @submit="handleSavingArticle">
      <div class="p-d-flex p-dir-col p-jc-center p-ai-center">
          <h3 class="p-mt-4">NOWY ARTYKUŁ</h3>
          <div class="p-col-8 name-section">
            <h4>Tytuł</h4>
            <LabeledInput
              name="title_name_input"
              rules="required"
              type="text"
              v-model:modelValue="currentlyEditing.title"
              placeholder="Wprowadź tytuł artykułu"
            />
          </div>
          <div class="p-field p-col-6 p-md-2 p-sm-5">
            <label for="basic1">Data publikacji</label>
            <Calendar id="basic1" v-model="currentlyEditing.publishDateStart" />
          </div>
          <div class="p-field p-col-6 p-md-2 p-sm-5">
            <label for="basic2">Data zakończenia publikacji</label>
            <Calendar id="basic2" v-model="currentlyEditing.publishDateStop" />
          </div>
          <div class="p-col-12 p-md-8 quil-section">
            <h4>Lead</h4>
            <LabeledInput
              name="lead_name_input"
              rules="required"
              type="text"
              v-model:modelValue="currentlyEditing.lead"
              placeholder="Wprowadź lead artykułu"
            />
          </div>
          <div class="p-col-12 p-md-8 quil-section">
            <h4 class="p-pb-4">Treść</h4>
            <QuillEditor class="wysiwyg-editor" theme="snow" :toolbar="EditorConfig" contentType="html" v-model:content="currentlyEditing.content" :enable="!isSaving"/>
          </div>
        <Button class="p-mb-3 p-mt-3" type="submit" label="Zapisz"/>
      </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { reactive, ref, inject } from 'vue'
import Calendar from 'primevue/calendar'
import LabeledInput from '@/components/LabeledInput'
import { Form } from 'vee-validate'
import Button from 'primevue/button'
import { v4 as uuidv4 } from 'uuid'
import { ArticleService } from '@/services/articleService'
import { useStore } from 'vuex'

export default {
  name: 'ArticleConfigView',
  components: {
    Calendar,
    LabeledInput,
    Form,
    Button
  },
  setup (props) {
    const keycloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const articleService = new ArticleService(keycloak)
    const store = useStore()
    const isSaving = ref(false)
    const EditorConfig = [
      ['bold', 'italic', 'underline', 'strike', { color: [] }],
      [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link', 'image']
    ]
    const currentlyEditing = reactive({
      id: '',
      title: '',
      lead: '',
      content: '',
      publishDateStart: '',
      publishDateStop: ''
    })

    const handleSavingArticle = async () => {
      isSaving.value = true

      if (currentlyEditing.title !== '' && currentlyEditing.title !== '' && currentlyEditing.lead !== '' && currentlyEditing.content !== '' && currentlyEditing.publishDateStart !== '' && currentlyEditing.publishDateStop !== '') {
        currentlyEditing.publishDateStart.toLocaleDateString().split('.').map(Number)
        currentlyEditing.publishDateStop.toLocaleDateString().split('.').map(Number)
        currentlyEditing.id = uuidv4()

        await articleService.postArticle(currentlyEditing).then(response => {
          store.dispatch('snackbarStore/showMessage', 'Dodano artykuł.')
          isSaving.value = false
        })
      } else {
        await store.dispatch('snackbarStore/showMessage', 'Uzupełnij wszystkie pola!')
        isSaving.value = false
      }
    }

    return { isSaving, currentlyEditing, EditorConfig, handleSavingArticle }
  }
}
</script>

<style lang="scss" scoped>
.p-button.p-component{
  background-color: $primary_color;
  border: 1px solid $primary_color;
  color: white;
  padding: 12px 30px;

  &:hover{
    background-color: white;
    border: 1px solid #B72937;
    color: #B72937;
  }
}
.new-article-wrapper{
  background-color: white;
}
</style>
