<template>
  <div class="shoppingCartsColumnWrapper">
    <div class="shoppingCartsSection">
      <div class="shoppingCartHeader">
        <h4>Wybrany koszyk: </h4>
        <h3>{{ localCurrentShoppingCart.name }}</h3>
      </div>
      <div v-if="!isAddingNewShoppingCartModeEnabled" class="shoppingCartYourShoppingCarts">
        <h3>Twoje koszyki</h3>
        <i @click="enableAddingNewShoppingCartMode" class="pi pi-plus"></i>
      </div>
      <div v-else class="shoppingCartYourShoppingCarts">
        <input type="text" maxlength="18" v-model="addingNewShoppingCartInput"
               placeholder="Podaj nazwę koszyka (max. 18)"/>
        <div class="shoppingCartYourShoppingCartsIcons">
          <i @click="saveNewShoppingCart" class="pi pi-check"/>
          <i @click="disableAddingNewShoppingCartMode" class="pi pi-times"/>
        </div>
      </div>
      <DataView
        :pageLinkSize="5"
        :value="shoppingCarts"
        layout="grid"
        :paginator="true"
        :rows="5">
        <template #grid="slotProps">
          <div
            class="shoppingCartItem"
            v-if="shoppingCartInEditNameMode === null || shoppingCartInEditNameMode.id !== slotProps.data.id">
                <span>
                <h4>{{ slotProps.data.name }}</h4>
                <p>{{ formatDate(new Date(slotProps.data.date)) }} </p>
              </span>
            <i @click="showShoppingCartItemOptionsDropdown($event, slotProps.data)" aria-haspopup="true"
               aria-controls="overlay_tmenu"
               class="pi pi-ellipsis-v"></i>
          </div>
          <div v-else class="shoppingCartItem shoppingCartItemRename">
            <input
              type="text"
              minlength="1"
              maxlength="18"
              v-model="shoppingCartInEditNameMode.name"
              placeholder="Podaj nową nazwę (max. 18)"/>
            <div class="shoppingCartYourShoppingCartsIcons">
              <i @click="saveShoppingCartName" class="pi pi-check"/>
              <i @click="disableShoppingCartEditNameMode" class="pi pi-times"/>
            </div>
          </div>
        </template>
      </DataView>
      <TieredMenu id="overlay_tmenu" ref="shoppingCartItemOptionsDropdownRef"
                  :model="shoppingCartItemDropdownOptionItems"
                  :popup="true"/>
    </div>
    <div class="shoppingCartsPrice">
      <div class="shoppingCartsPriceHeader">
        <h3>Łączna kwota</h3>
        <h2>{{ localCurrentShoppingCart.getTotalPrice() }}zł</h2>
      </div>
      <CustomButton class="customButton" label="Wybierz dostawę" @click="goToNextStep"/>
      <span><p>Zrealizuj kupon promocyjny</p></span>
    </div>
  </div>
</template>

<script>
import DataView from 'primevue/dataview'
import CustomButton from '@/components/CustomButton'
import TieredMenu from 'primevue/tieredmenu'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { ShoppingCart } from '@/models/shoppingCart'
import { generateRandomNumber, formatDate } from '@/utils/utils'
import router from '@/router'

export default {
  name: 'ShoppingCartStepOneShoppingCartsColumn',
  components: {
    DataView,
    CustomButton,
    TieredMenu
  },
  props: {
    shoppingCarts: {
      type: Array,
      required: true
    },
    currentShoppingCart: {
      type: Object,
      required: true
    }
  },
  setup (props) {
    const store = useStore()
    const localShoppingCarts = computed(() => props.shoppingCarts)
    const localCurrentShoppingCart = computed(
      {
        get: () => new ShoppingCart(JSON.parse(JSON.stringify(props.currentShoppingCart))),
        set: (value) => {
          store.dispatch('shoppingCartStore/setCurrentShoppingCartId', value.id)
        }
      })
    const shoppingCartItemOptionsDropdownRef = ref(null)
    const selectedDropdownShoppingCartItem = ref(null)
    const shoppingCartInEditNameMode = ref(null)
    const addingNewShoppingCartInput = ref('')
    const isAddingNewShoppingCartModeEnabled = ref(false)
    const shoppingCartItemDropdownOptionItems = [
      {
        label: 'Aktywuj koszyk',
        icon: 'pi pi-fw pi-shopping-cart',
        command: async () => {
          localCurrentShoppingCart.value = new ShoppingCart(JSON.parse(JSON.stringify(selectedDropdownShoppingCartItem.value)))
          await store.dispatch('shoppingCartStore/setCurrentShoppingCartId', selectedDropdownShoppingCartItem.value.id)
        }
      },
      {
        label: 'Zmień nazwę',
        icon: 'pi pi-fw pi-pencil',
        command: () => {
          shoppingCartInEditNameMode.value = new ShoppingCart(JSON.parse(JSON.stringify(selectedDropdownShoppingCartItem.value)))
        }
      },
      {
        label: 'Usuń koszyk',
        icon: 'pi pi-fw pi-trash',
        command: async () => {
          if (selectedDropdownShoppingCartItem.value.id === localCurrentShoppingCart.value.id) {
            await store.dispatch('snackbarStore/showMessage', 'Nie możesz usunąć koszyka w którym obecnie się znajdujesz!')
            return
          }

          await store.dispatch('shoppingCartStore/setCurrentShoppingCartId', localCurrentShoppingCart.value.id)
          await store.dispatch('shoppingCartStore/removeShoppingCartById', selectedDropdownShoppingCartItem.value.id)
        }
      }
    ]

    const disableShoppingCartEditNameMode = () => {
      shoppingCartInEditNameMode.value = null
    }

    const saveShoppingCartName = async () => {
      await store.dispatch('shoppingCartStore/saveShoppingCart', shoppingCartInEditNameMode.value)
      if (localCurrentShoppingCart.value.id === shoppingCartInEditNameMode.value.id) {
        localCurrentShoppingCart.value = shoppingCartInEditNameMode.value
      }
      shoppingCartInEditNameMode.value = null
      await store.dispatch('snackbarStore/showMessage', 'Nazwa koszyka została zmieniona!')
    }

    const showShoppingCartItemOptionsDropdown = (event, item) => {
      shoppingCartItemOptionsDropdownRef.value.toggle(event)
      selectedDropdownShoppingCartItem.value = item
    }

    const enableAddingNewShoppingCartMode = () => {
      isAddingNewShoppingCartModeEnabled.value = true
    }
    const disableAddingNewShoppingCartMode = () => {
      isAddingNewShoppingCartModeEnabled.value = false
    }

    const saveNewShoppingCart = async () => {
      if (addingNewShoppingCartInput.value !== '') {
        const newShoppingCart = new ShoppingCart(
          {
            id: generateRandomNumber(20, 1500),
            name: addingNewShoppingCartInput.value,
            date: Date.now(),
            products: []
          }
        )
        disableAddingNewShoppingCartMode()
        addingNewShoppingCartInput.value = ''
        localCurrentShoppingCart.value = new ShoppingCart(JSON.parse(JSON.stringify(newShoppingCart)))
        await store.dispatch('shoppingCartStore/addShoppingCart', new ShoppingCart(JSON.parse(JSON.stringify(newShoppingCart))))
        await store.dispatch('shoppingCartStore/setCurrentShoppingCartId', newShoppingCart.id)
        await store.dispatch('snackbarStore/showMessage', 'Pomyślnie dodano nowy koszyk!')
      } else {
        await store.dispatch('snackbarStore/showMessage', 'Podaj nazwę koszyka przed zapisem!')
      }
    }

    const goToNextStep = async () => {
      await store.dispatch('shoppingCartStore/setCurrentShoppingCartId', localCurrentShoppingCart.value.id)
      await router.push('/shopping-cart/shopping-invoice')
    }

    return {
      localShoppingCarts,
      localCurrentShoppingCart,
      isAddingNewShoppingCartModeEnabled,
      addingNewShoppingCartInput,
      enableAddingNewShoppingCartMode,
      disableAddingNewShoppingCartMode,
      saveNewShoppingCart,
      shoppingCartItemDropdownOptionItems,
      shoppingCartItemOptionsDropdownRef,
      showShoppingCartItemOptionsDropdown,
      shoppingCartInEditNameMode,
      disableShoppingCartEditNameMode,
      saveShoppingCartName,
      goToNextStep,
      formatDate
    }
  }
}
</script>

<style lang="scss" scoped>

.shoppingCartItem {
  margin-bottom: 5px;
  width: 100%;
  min-height: 59px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #c8c8c8;
  padding: 10px 15px;

  p {
    font-size: 12px;
    color: #848484;
  }

  i {
    cursor: pointer;
  }
}

.shoppingCartsColumnWrapper {
  .shoppingCartsSection {
    padding: 30px 30px 10px 30px;
    border: 1px solid #c8c8c8;
    background-color: white;

    h3:nth-child(2) {
      padding: 10px 0;
    }

    .shoppingCartHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #222;
      border-radius: 12px;
      padding: 5px 15px;
      color: white;

      h3 {
        font-weight: lighter;
      }

      h2 {
        font-weight: normal;
      }

      i {
        color: white;
        cursor: pointer;
      }
    }

    .shoppingCartYourShoppingCarts {
      margin: 15px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        cursor: pointer;
      }

      input {
        width: 70%;
        height: 25px;
        border: none;
        border-bottom: 1px solid black;
        padding: 0 4px;
      }

      .shoppingCartYourShoppingCartsIcons {
        i:nth-child(2) {
          margin-left: 10px;
        }
      }
    }

    .shoppingCartItemRename {
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        cursor: pointer;
      }

      input {
        width: 70%;
        height: 25px;
        border: none;
        border-bottom: 1px solid black;
        padding: 0 4px;
      }

      .shoppingCartYourShoppingCartsIcons {
        i:nth-child(2) {
          margin-left: 10px;
        }
      }
    }

    ::v-deep(.p-paginator-first),
    ::v-deep(.p-paginator-last) {
      display: none;
    }

    ::v-deep(.pi-angle-right:before), ::v-deep(.pi-angle-left:before) {
      font-size: 12px;
    }

    ::v-deep(.p-paginator .p-paginator-pages .p-paginator-page.p-highlight) {
      background: none;
      border: none;
      color: black;
    }

    ::v-deep(.p-paginator .p-paginator-pages .p-paginator-page) {
      font-size: 12px;
    }

    ::v-deep(.p-link:focus) {
      box-shadow: none;
    }
  }

  .shoppingCartsPrice {
    margin-top: 30px;
    padding: 20px 30px 20px 30px;
    border: 1px solid #c8c8c8;
    background: white;

    .shoppingCartsPriceHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .customButton {
      margin: 10px 0;
    }

    span {
      display: grid;
      justify-content: end;

      p {
        cursor: pointer;
      }
    }
  }
}
</style>
