<template>
  <OrdersComponent />
</template>

<script>
import OrdersComponent from '@/components/orders/OrdersComponent'

export default {
  name: 'OrdersView',
  components: {
    OrdersComponent
  }
}
</script>

<style scoped>

</style>
