import axios from 'axios'
import authHeader from '@/services/auth_header'
import { handleRequestError } from '@/utils/utils'
import { Article } from '@/models/articleModel'
import { ArticleList } from '@/models/articleList'

export class ArticleService {
  constructor (keyCloak) {
    this.keyCloak = keyCloak
    this.baseUrl = process.env.VUE_APP_BASE_BACKEND_URL
  }

  async fetchArticles (start, end) {
    const options = {
      method: 'get',
      url: this.baseUrl + '/article/?from=' + start + '&to=' + end
    }

    const response = await axios(options).then(res => {
      return new ArticleList(res.data)
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async fetchAdminArticles (start, end) {
    const options = {
      method: 'get',
      url: this.baseUrl + '/article/admin?from=' + start + '&to=' + end
    }

    const response = await axios(options).then(res => {
      return new ArticleList(res.data)
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async updateArticle (articleData) {
    const options = {
      method: 'put',
      url: this.baseUrl + '/article',
      data: articleData,
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(res => {
      return new Article(articleData)
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async postArticle (articleData) {
    const options = {
      method: 'post',
      url: this.baseUrl + '/article',
      data: articleData,
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async fetchArticle (id) {
    const options = {
      method: 'get',
      url: this.baseUrl + '/article/' + id,
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(response => {
      return new Article(response.data)
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }
}
