<template>
  <div class="basketItemWrapper">
    <div class="firstItemSection">
      <p>{{ name.length > 23 ? name.slice(0,23) + ' ...' : name }}</p>
      <p>Ilość: {{ amount }}szt.</p>
      <span>Przejdź do produktu</span>
    </div>
    <div class="secondItemSection">
      <p>{{ price }}zł/szt.</p>
      <span>Przenieś do</span>
      <span>Usuń</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShoppingCartProductItem',
  props: {
    name: {
      type: String,
      required: true
    },
    amount: {
      type: Number,
      required: true
    },
    price: {
      type: Number,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>

.basketItemWrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cfcfcf;
  padding-top: 15px;
  padding-bottom: 10px;

  p {
    font-weight: bold;
  }

  span {
    font-weight: lighter;
    cursor: pointer;
  }

  .firstItemSection {
    text-align: left;
  }

  .secondItemSection {
    text-align: right;
    display: grid;
  }
}
</style>
