<template>
  <div class="FAQ-Component">
    <div class="FAQ-Component_Navigation">
      <p> FAQ &nbsp;</p>
    </div>
    <div class="FAQ-Component_MainButtons">
        <Button icon="pi pi-undo" label="Cofnij zmiany" @click="backFromServer" />
        <Button icon="pi pi-save" label="Zapisz na serwerze" @click="sendToServer" />
        <Button :icon="state.isAddCategory ? 'pi pi-times' : 'pi pi-plus'" :label="state.isAddCategory ? 'Zamknij' : 'Dodaj kategorię'" @click="state.isAddCategory = !state.isAddCategory; state.isAddFAQ = false" />
        <Button :icon="state.isAddFAQ ? 'pi pi-times' : 'pi pi-plus'" :label="state.isAddFAQ ? 'Zamknij' : 'Dodaj pytanie'" @click="state.isAddFAQ = !state.isAddFAQ; state.isAddCategory = false" />
        <router-link :to="{ name: 'FAQ', query: { isPreview: true }}" target="_blank">
          <Button icon="pi pi-search" label="Podgląd" @click="toLocalStorage"/>
        </router-link>
    </div>
    <div class="FAQ-Component_AddField" v-show="state.isAddFAQ || state.isAddCategory">
      <div class="FAQ-Component_AddField_AddCategory" v-show="state.isAddCategory">
        <label for="Category_Title_Box">NOWA KATEGORIA</label>
        <textarea id="Category_Title_Box" rows="1" placeholder="Wpisz kategorię" v-model="tmp.categoryName"></textarea>
      </div>
      <div class="FAQ-Component_AddField_AddFAQ" v-show="state.isAddFAQ">
        <div>
          <div class="FAQ-Component_AddField_AddFAQ-QuestionField">
            <label for="Question_Box">PYTANIE</label>
            <textarea id="Question_Box" rows="1" placeholder="Wpisz pytanie" v-model="tmp.question"></textarea>
          </div>
          <div class="FAQ-Component_AddField_AddFAQ-CategoryField">
            <label for="Category_Box">KATEGORIA</label>
            <Dropdown id="Category_Box" v-model="tmp.category" :options="categoryList" optionLabel="name" optionvalue="id" placeholder="Wybierz kategorię" />
          </div>
        </div>
        <div>
          <div class="FAQ-Component_AddField_AddFAQ-AnswerField">
            <label for="Answer_Box">ODPOWIEDŹ</label>
            <textarea id="Answer_Box" rows="3" placeholder="Wpisz odpowiedź na pytanie" v-model="tmp.answer"></textarea>
          </div>
        </div>
      </div>
      <div class="FAQ-Component_AddButtons" v-show="state.isAddCategory || state.isAddFAQ">
        <Button icon="pi pi-undo" label="Wyczyść" @click="clearTextBox" />
        <Button class="SaveSingleItemButton" label="Zapisz zmiany" @click="saveItem" />
      </div>
    </div>
    <div class="FAQ-Component_Categories">
      <draggable v-model="categoryList" item-key="id" tag="transition-group" :component-data="{ tag: 'div' }" ghost-class="shadow" width="100%">
        <template #item="{element}">
          <div class="FAQ-Component_Categories-Elements">
            <faqAdminCategory :faqCategoryItem="element"/>
          </div>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import faqAdminCategory from './FAQAdminCategory'
import { useStore } from 'vuex'
import { computed, reactive } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'

export default {
  name: 'FAQAdminComponent',
  components: {
    faqAdminCategory,
    Button,
    Dropdown,
    draggable
  },
  props: {
    faq: {
      type: Array,
      required: true
    }
  },
  setup (props, context) {
    const state = reactive({
      isAddCategory: false,
      isAddFAQ: false
    })
    const store = useStore()
    const categoryList = computed({
      get: function () {
        return store.state.faqStore.faqCategory
      },
      set: function (value) {
        store.dispatch('faqStore/updateCategoryList', value)
      }
    })
    const list = computed({
      get: function () {
        return store.state.faqStore.faqCategory[0].faqDtoList
      },
      set: function (value) {
        store.dispatch('faqStore/updateFAQList', { id: store.state.faqStore.faqCategory[0].id, value: value })
      }
    })
    const tmp = reactive({
      category: {
        id: '',
        order: 0,
        name: '',
        faqDtoList: []
      },
      categoryName: '',
      id: '',
      question: '',
      answer: ''
    })

    const saveItem = () => {
      if (state.isAddCategory) addCategory()
      else if (state.isAddFAQ) addFAQItem()
    }

    const addFAQItem = () => {
      if (tmp.question && tmp.answer && tmp.category.id) {
        const addItem = {
          id: uuidv4(),
          order: 0,
          question: tmp.question,
          answer: tmp.answer
        }
        clearAllBoxes()
        state.isAddFAQ = false
        store.dispatch('faqStore/addItem', { id: tmp.category.id, item: addItem })
      }
    }

    const addCategory = () => {
      const newCategory = {
        id: uuidv4(),
        name: tmp.categoryName,
        order: 0,
        faqDtoList: []
      }
      clearAllBoxes()
      state.isAddCategory = false
      store.dispatch('faqStore/addCategory', newCategory)
    }

    const clearAllBoxes = () => {
      tmp.id = ''
      tmp.categoryName = ''
      tmp.question = ''
      tmp.answer = ''
    }

    const sendToServer = () => {
      store.dispatch('faqStore/updateAllOrders')
      context.emit('sendtoserver')
    }

    const backFromServer = () => {
      context.emit('backfromserver')
    }

    const toLocalStorage = async () => {
      await localStorage.setItem('abler-faq', JSON.stringify(categoryList.value))
    }
    return {
      categoryList,
      state,
      tmp,
      list,
      saveItem,
      addFAQItem,
      addCategory,
      clearTextBox: clearAllBoxes,
      sendToServer,
      backFromServer,
      toLocalStorage
    }
  }
}
</script>

<style lang="scss" scoped>
.shadow{
  opacity: 0.5 !important;
  background: #B72937 !important;
}
.FAQ-Component{
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 360px;
  @media only screen and (max-width: 768px){
    padding: 10px;
  }
  .FAQ-Component_Navigation{
    display: flex;
    justify-content: center;
    font-size: 13px;
    p:after{
      content: '> Dodaj';
      color: #B72937;
    }
  }
  .FAQ-Component_MainButtons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0 40px 0;
    @media only screen and (max-width: 1085px){
      .p-button.p-component{
        font-size: 10px;
      }
    }
    @media only screen and (max-width: 917px){
      .p-button.p-component{
        height: 65px;
      }
    }
    @media only screen and (max-width: 768px){
      margin: 10px 0 20px 0;
      .p-button.p-component{
        width: 80px;
        height: 50px;
        font-size: 0.6rem;
        padding: 5px 10px;
        margin-left: 5px;
      }
      ::v-deep(.p-button-icon-left){
        font-size: 10px;
        margin: 0;
      }
    }
  }
  .FAQ-Component_AddField{
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    font-weight: bold;
    .FAQ-Component_AddField_AddCategory{
      display: flex;
      flex-direction: column;
      width: 100%;
      textarea{
        min-height: 40px;
        max-height: 40px;
      }
    }
    .FAQ-Component_AddField_AddFAQ{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: #ffffff;
      width: 100%;
      font-weight: bold;
      div{
        width: 100%;
        display: flex;
        flex-direction: row;
        .FAQ-Component_AddField_AddFAQ-QuestionField{
          display: flex;
          flex-direction: column;
          width: 60%;
          textarea{
            max-height: 40px;
          }
        }
        .FAQ-Component_AddField_AddFAQ-CategoryField{
          display: flex;
          flex-direction: column;
          width: 40%;
          justify-content: center;
          padding: 0 0 0 20px;
          #Category_Box{
            margin: 10px 0;
            min-height: 40px;
            max-height: 40px;
            color: #484848;
            border: 1px solid #B72937;
            &:focus{
              outline-style: solid;
              outline-width: thin;
              box-shadow: #B72937;
            }
          }
          ::v-deep(.p-dropdown .p-dropdown-label.p-placeholder){
            font-size: 15px;
            color: #a6a6a6;
          }
          ::v-deep(.p-dropdown .p-dropdown-trigger){
            color: #B72937;
          }
        }
        .FAQ-Component_AddField_AddFAQ-AnswerField{
          display: flex;
          flex-direction: column;
        }
      }
    }
    .FAQ-Component_AddButtons{
      padding: 15px 0;
      .SaveSingleItemButton{
          background-color: #B72937;
          border: 1px solid #B72937;
      }
      @media only screen and (max-width: 420px){
        display: flex;
        justify-content: center;
        .p-button.p-component{
          margin: 0 20px;
          padding: 10px;
          width: 100px;
          font-size: 12px;
        }
      }
  }
  }.p-button.p-component{
     background-color: black;
     border: 1px solid black;
     color: white;
     padding: 12px 30px;
     margin-left: 20px;
     &:hover{
       background-color: white;
       border: 1px solid #B72937;
       color: #B72937;
     }
  }
  .FAQ-Component_Categories{
    .FAQ-Component_Categories-Elements{
      transition: all 0.25s ease-in-out;
    }
    .shadow{
      opacity: 0.5;
      background: #B72937;
    }
  }
}

textarea{
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #B72937;
  resize: none;
  font-size: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  &:focus{
    outline-style: solid;
    box-shadow: #B72937;
  }
}

</style>
