<template>
  <div class="p-d-flex p-jc-center">
    <div class="p-col-12 p-md-11 p-lg-11 faq-wrapper">
       <faqAdminComponent v-if="!isLoading" :faq="faq" @sendtoserver="sendToServer" @backfromserver="backFromServer"/>
<!--  <faqClientCategory v-if="!isLoading" :faq="faq" />-->
    </div>
  </div>
</template>

<script>
import faqAdminComponent from '../components/FAQ/FAQAdminComponent'
// import faqClientCategory from '../components/FAQ/FAQClientCategory'
import { useStore } from 'vuex'
import { computed, inject, onMounted, ref } from 'vue'
import { FaqService } from '../services/faqService'
export default {
  name: 'faqConfigView',
  components: {
    faqAdminComponent
    // faqClientCategory
  },

  setup () {
    const store = useStore()
    const keyCloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const faqService = new FaqService(keyCloak)
    const isLoading = ref(true)
    const faq = computed(() => store.state.faqStore.faqCategory)

    onMounted(async () => {
      await store.dispatch('faqStore/fetchFromServer', faqService).then(() => {
        isLoading.value = false
      })
    })

    async function sendToServer () {
      isLoading.value = true
      await store.dispatch('faqStore/sendToServer', { data: store.state.faqStore.faqCategory, faqService: faqService }).then(() => {
        isLoading.value = false
      })
    }

    async function backFromServer () {
      isLoading.value = true
      await store.dispatch('faqStore/fetchFromServer', faqService).then(() => {
        isLoading.value = false
      })
    }
    return {
      faq,
      isLoading,
      sendToServer,
      backFromServer
    }
  }
}
</script>

<style scoped>
body{
  background-color: #f2f2f2;
}
.faq-wrapper{
  margin-top: 30px;
}
</style>
