import axios from 'axios'
import authHeader from '@/services/auth_header'
import { handleRequestError } from '@/utils/utils'
import { MessagesList } from '@/models/messagesList'
import { SingleChatMessage } from '@/models/singleChatMessage'

export class ChatService {
  constructor (keyCloak) {
    this.keyCloak = keyCloak
    this.baseUrl = process.env.VUE_APP_BASE_BACKEND_URL
  }

  async fetchInProgressChats (start, end) {
    const options = {
      method: 'get',
      url: this.baseUrl + '/chat/show/in-progress?from=' + start + '&to=' + end,
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(res => {
      return new MessagesList(res.data)
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async fetchClosedChats (start, end) {
    const options = {
      method: 'get',
      url: this.baseUrl + '/chat/show/closed?from=' + start + '&to=' + end,
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(res => {
      return new MessagesList(res.data)
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async fetchChat (id) {
    const options = {
      method: 'get',
      url: this.baseUrl + '/chat/' + id,
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(res => {
      // TODO MODEL
      const chatMessages = []
      for (const message of res.data) {
        chatMessages.push(new SingleChatMessage(message))
      }
      return chatMessages
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async postChatByUser (chatData) {
    const options = {
      method: 'get',
      data: chatData,
      url: this.baseUrl + '/chat'
    }

    const response = await axios(options).then(res => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async postChatByAdmin (chatData) {
    const options = {
      method: 'get',
      data: chatData,
      url: this.baseUrl + '/chat/new'
    }

    const response = await axios(options).then(res => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async closeChat (id) {
    const options = {
      method: 'put',
      url: this.baseUrl + '/article/' + id,
      // TODO add header
      headers: { Authorization: 'Bearer ' + this.keyCloak.token, accept: '*/*' }
    }

    const response = await axios(options).then(res => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async sendMessage (chatId, message) {
    const options = {
      method: 'post',
      url: this.baseUrl + '/chat/' + chatId,
      data: message,
      headers: authHeader(this.keyCloak.token)
    }
    console.log(message)

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }
}
