import axios from 'axios'
import authHeader from '@/services/auth_header'
import { handleRequestError } from '@/utils/utils'
import { CarouselLinks } from '@/models/carouselLinks'
import authMultipartHeader from '@/services/auth_multipart_header'

export class CarouselService {
  constructor (keyCloak) {
    this.keyCloak = keyCloak
    this.baseUrl = process.env.VUE_APP_BASE_BACKEND_URL
  }

  async fetchCarouselImages () {
    const options = {
      method: 'get',
      url: this.baseUrl + '/carousel',
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(res => {
      // TODO Add model
      return new CarouselLinks(res.data)
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async putCarouselImages (data) {
    const options = {
      method: 'put',
      url: this.baseUrl + '/carousel',
      data: data,
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async postCarouselImage (carouselData) {
    const options = {
      method: 'post',
      url: this.baseUrl + '/carousel-image',
      data: carouselData,
      headers: authMultipartHeader(this.keyCloak.token)
    }
    console.log(carouselData)
    const response = await axios(options).then(res => {
      return res
    }).catch(async (error) => {
      return Promise.reject(error)
      // await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async fetchCarouselThumbnail (id) {
    const options = {
      method: 'get',
      url: this.baseUrl + '/carousel-image/thumbnail/' + id,
      headers: { accept: '*/*' }
    }

    const response = await axios(options).then(response => {
      return response
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async fetchCarouselImage (id) {
    const options = {
      method: 'get',
      url: this.baseUrl + '/carousel-image/' + id,
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(response => {
      return response
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async deleteCarouselImage (id) {
    const options = {
      method: 'delete',
      url: this.baseUrl + '/carousel-image/' + id,
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(response => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }
}
