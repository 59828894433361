<template>
    <div class="p-col-12 newsletter-wrapper">
      <h3 class="p-mb-3">Drogi kliencie,</h3>
      <p class="p-mb-3">Tylko jedno kliknięcie dzieli Cię, od tego, aby na Twoją skrzynkę pocztową trafiały newsy, ciekawostki z branży, czy informacje o ofertach specjalnych.</p>
      <Form @submit="handleSaving">
        <div class="p-col-7 p-md-6 p-lg-3">
            <LabeledInput name="newsletter-email" rules="required|email" label="Wpisz adres e-mail:" v-model:modelValue="userEmail"/>
        </div>
        <div class="categories-section">
          <div class="p-d-flex p-dir-row p-flex-wrap p-mb-1">
            <div v-for="[key, value] of Object.entries(newslettersCategories.categories)" :key="key" class="p-mr-6">
              <h4 class="p-mt-2 p-mb-3">{{ key }}</h4>
              <div v-for="subcategory of value" :key="subcategory.key" class="p-field-checkbox p-mb-3">
                <Checkbox :id="subcategory.key" name="category" :value="subcategory.id" v-model="userSubscriptionsList"/>
                <label :for="subcategory.key">{{ subcategory.subcategory }}</label>
              </div>
            </div>
          </div>
        </div>
        <div class="regulations-section p-mb-3">
          <div class="p-d-flex p-dir-row p-ai-start">
<!--            <Checkbox as="termsAndConditions" rules="required" name="acceptRegulations" v-model="isRegulationsAccepted"/>-->
            <CheckboxInput name="checkbox" rules="accept" v-model:modelValue="isRegulationsAccepted"/>
            <div class="p-ml-2">
              <h3 class="p-mb-2">Zgoda na przetwarzanie danych</h3>
              <p class="p-col-10 p-md-8 p-lg-8">Vestibulum scelerisque sollicitudin orci non accumsan. Aenean vitae laoreet nulla. Sed sagittis tempus augue condimentum vehicula. Aliquam lobortis, nunc id facilisis feu, ipsum elit faucibus urna
                sit amet viverra nunc neque vitae dolor. Nullam et pretium quam, quis volutpat orci. Donec et turpis a odio euismod convallis nec ac nisi. Phasellus porta neque. Nullam vel dapibus lectus. Mauris at facilisis dui.
                Vestibulum scelerisque sollicitudin orci non accumsan. Aenean vitae laoreet nulla. Sed sagittis tempus augue condimentum vehicula. Aliquam lobortis, nunc id facilisis feugiat, ipsum elit faucibus urna, sit amet
                viverra nunc neque vitae dolor.</p>
            </div>
          </div>
        </div>
        <div class="buttons-section">
          <Button type="submit" label="Zapisz"></Button>
        </div>
      </Form>
    </div>
</template>

<script>
import { ref } from 'vue'
import LabeledInput from '@/components/LabeledInput'
import { Form } from 'vee-validate'
import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import CheckboxInput from '@/components/CheckboxInput'

export default {
  name: 'newsletterProfileComponent',
  components: {
    CheckboxInput,
    LabeledInput,
    Button,
    Checkbox,
    Form
  },
  props: {
    isSaving: {
      type: Boolean,
      required: true
    },
    newslettersCategories: {
      required: true
    },
    userProfile: {
      required: true
    },
    userSubscriptions: {
      required: false
    }
  },
  emits: ['sendEmailSubscribeData', 'sendEmailUnsubscribeData', 'showRegulationsError'],
  setup (props, { emit }) {
    const userEmail = ref(JSON.parse(JSON.stringify(props.userProfile.email)))
    const initialUserSubscriptionsList = JSON.parse(JSON.stringify(props.userSubscriptions))
    const userSubscriptionsList = ref(JSON.parse(JSON.stringify(props.userSubscriptions)))
    const isRegulationsAccepted = ref(false)

    const handleSaving = () => {
      const emailData = {
        email: userEmail?.value,
        categories: userSubscriptionsList.value.length === 0 ? initialUserSubscriptionsList : userSubscriptionsList?.value
      }
      if (isRegulationsAccepted.value === true) {
        if (userSubscriptionsList.value.length === 0) {
          emit('sendEmailUnsubscribeData', emailData)
        } else {
          emit('sendEmailSubscribeData', emailData)
        }
      } else {
        emit('showRegulationsError')
      }
    }

    return {
      userEmail,
      userSubscriptionsList,
      isRegulationsAccepted,
      handleSaving
    }
  }
}
</script>

<style lang="scss" scoped>
.newsletter-wrapper{
  margin: 15px 15px 5px 15px;
  padding: 30px;
  background-color: white;
}
.regulations-section{
  p{
    font-size: 11px;
  }
}
</style>
