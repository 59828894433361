<template>
  <div v-if="isLoading"></div>
  <div v-else class="p-d-flex p-dir-col p-ai-center p-mt-6">
    <div class="buttons-section p-d-flex p-jc-center p-mt-3 p-mb-3">
      <Button :icon="isNewChat ? 'pi pi-times' : 'pi pi-plus'" :class="isNewChat ? 'p-button-outlined add-new-newsletter-button p-mr-3' : 'add-new-newsletter-button p-mr-3'" @click="handleAddingChat" type="button" :label="isNewChat ? 'Zamknij' : 'Rozpocznij nową rozmowę'"/>
      <Button @click="handleEndConversation" type="button" label="Zakończ wątek"/>
    </div>
    <div class="p-col-11">
      <div :class="userRole === 'Administrator' ? 'p-d-flex p-dir-col p-ai-end' : 'p-d-flex p-dir-col p-ai-start'">
        <div v-for="(message, index) of messages" :key="index">
          <h4 class="p-mt-3">{{ message.author.firstName + ' ' + message.author.lastName }}</h4>
          <div :class=" userRole === 'Administrator' ? 'p-mt-3 p-p-3 admin-wrapper' : 'p-mt-3 p-p-3 client-wrapper'">
            <p class="p-mb-2"> {{ message.sendDate }}</p>
            <p> {{ message.message }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="chatFooter p-shadow-1">
      <input @keyup.enter="sendMessage" v-model="inputValue" type="text" placeholder="Wpisz treść wiadomości">
      <div class=""></div>
      <i @click="sendMessage"  class="pi pi-chevron-right"/>
    </div>
  </div>
</template>

<script>
import { inject, ref, onMounted } from 'vue'
import { ChatService } from '@/services/chatService'
import { parseUserRoles } from '@/utils/utils'
import Button from 'primevue/button'

export default {
  name: 'ChatRoomView',
  components: {
    Button
  },
  props: {
    chatRoomId: {
      type: String,
      required: true
    }
  },
  setup (props) {
    const keyCloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const chatService = new ChatService(keyCloak)

    const isLoading = ref(true)
    const isSending = ref(false)
    const isNewChat = ref(false)
    const userRole = ref()

    const article = ref()
    const messages = ref()
    const inputValue = ref()

    onMounted(async () => {
      isLoading.value = true

      userRole.value = parseUserRoles(keyCloak.realmAccess.roles)
      await chatService.fetchChat(props.chatRoomId).then(response => {
        messages.value = response
        isLoading.value = false
      })
    })

    const sendMessage = async () => {
      isSending.value = true

      if (inputValue.value !== null || inputValue.value !== undefined) {
        await chatService.sendMessage(props.chatRoomId, inputValue.value).then(res => {
          chatService.fetchChat(props.chatRoomId).then(response => {
            messages.value = response
          })
          isSending.value = false
        })
      }
    }

    const handleAddingChat = async () => {
    }

    const handleEndConversation = async () => {
      isSending.value = true
      await chatService.closeChat(props.chatRoomId).then(res => {
        isSending.value = false
      })
    }

    return {
      handleAddingChat,
      handleEndConversation,
      isNewChat,
      sendMessage,
      inputValue,
      userRole,
      messages,
      isLoading,
      article
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-wrapper{
  background-color: white;
}

.buttons-section{
  .p-button.p-component{
    background-color: black;
    border: 1px solid black;
    color: white;
    padding: 12px 30px;
    &:hover{
      background-color: white;
      border: 1px solid #B72937;
      color: #B72937;
    }
  }

  Button{
    height: 3rem;
  }
}
.client-wrapper{
  background-color: #E1F3FC;
}

.chatFooter {
  position: absolute;
  bottom: 30px;
  width: 40%;
  margin-top: 10px;
  padding: 15px 20px;
  font-weight: 400;
  background-color: white;
  display: flex;
  justify-self: auto;
  justify-content: space-between;
  align-items: center;

  input {
    padding: 5px;
    width: 100%;
    color: black;
    outline: 0;
    border-width: 0 0 1px;
    border-color: white;
    margin-right: 5px;

    &::placeholder {
      color: gray;
    }

    &:focus {
      border-width: 0 0 1px;
      border-color: #007bff;
    }
  }

  i {
    color: black;
    font-size: 18px;
    cursor: pointer;
  }
}
</style>
