<template>
  <div class="FAQ">
    <div class="FAQ-Category">
      <div class="FAQ-Category_Header" @click="state.isShow = !state.isShow">
        <div class="FAQ-Category_Header-Title">
          <span v-if="!state.isEdit" v-bind:class="{Bold: state.isShow}">{{ categoryTitle }}</span>
          <div v-if="state.isEdit">
            <textarea row="1" v-model="categoryName"/>
            <div class="FAQ-Category_Header-Title_Buttons">
              <Button icon="pi pi-undo" label="Wyczyść" @click="clearCategoryBox" />
              <Button class="SaveCategoryNameButton" label="Zapisz zmiany" @click="saveCategoryName" />
            </div>
          </div>
        </div>
        <div class="FAQ-Category_Header-Icons">
          <i class="pi pi-pencil" v-tooltip.top="'EDYTUJ'" @click="editCategory"/>
          <i class="pi pi-trash" v-tooltip.top="'USUŃ'" @click="removeCategory"/>
        </div>
      </div>
      <div v-if="state.isShow && !state.isEdit" style="padding-left: 20px">
        <draggable v-model="list" item-key="id" tag="transition-group" :component-data="{ tag: 'div' }" group="FAQ" ghost-class="ghost" width="100%">
          <template #item="{element}">
            <faqAdminItem :faq_item="element" @remove="removeFAQItem" @edit="editFAQItem"/>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import faqAdminItem from './FAQAdminItem.vue'
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'
export default {
  inheritAttrs: false,
  name: 'faqAdminCategory',
  components: {
    faqAdminItem,
    Button,
    draggable
  },
  directives: {
    tooltip: Tooltip
  },
  props: {
    faqCategoryItem: {
      id: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      order: {
        type: Number,
        required: true
      },
      faqDtoList: {
        type: Array,
        required: true
      }
    }
  },
  setup (props) {
    const store = useStore()
    const list = computed({
      get: function () {
        const index = store.state.faqStore.faqCategory.findIndex(element => element.id === props.faqCategoryItem.id)
        return store.state.faqStore.faqCategory[index].faqDtoList
      },
      set: function (value) {
        store.dispatch('faqStore/updateFAQList', { id: props.faqCategoryItem.id, value: value })
      }
    })

    const removeFAQItem = (el) => {
      const index = list.value.indexOf(el)
      store.dispatch('faqStore/removeItem', { id: props.faqCategoryItem.id, itemIndex: index })
    }

    const editFAQItem = (item) => {
      const index = list.value.findIndex(element => element.id === item.id)
      if (index >= 0) {
        store.dispatch('faqStore/editItem', { id: props.faqCategoryItem.id, item: item, itemIndex: index })
      }
    }

    const categoryName = ref(props.faqCategoryItem.name)
    const categoryTitle = ref(null)
    categoryTitle.value = categoryName.value

    const state = reactive({
      isEdit: false,
      isShow: false
    })

    const clearCategoryBox = () => {
      categoryName.value = ''
    }

    const saveCategoryName = () => {
      categoryTitle.value = categoryName.value
      store.dispatch('faqStore/editCategory', { id: props.faqCategoryItem.id, name: categoryName.value })
      state.isEdit = false
    }

    const editCategory = () => {
      categoryName.value = categoryTitle.value
      state.isEdit = !state.isEdit
    }

    const removeCategory = () => {
      store.dispatch('faqStore/removeCategory', props.faqCategoryItem.id)
    }

    return {
      categoryName,
      categoryTitle,
      state,
      list,
      removeFAQItem,
      editFAQItem,
      clearCategoryBox,
      saveCategoryName,
      editCategory,
      removeCategory
    }
  }
}
</script>

<style lang="scss" scoped>

.FAQ{
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  background-color: #f2f2f2;
  .FAQ-Category{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: auto;
    width: 100%;
    .FAQ-Category_Header{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 15px 30px;
      width: 100%;
      max-width: 100%;
      background-color: white;
      transition: all 0.25s ease-in-out;
      word-wrap: break-word;
      @media only screen and (max-width: 768px){
        padding: 10px 20px;
      }
      .FAQ-Category_Header-Title{
        min-height: 100%;
        width: calc(100% - 90px);
        word-wrap: break-word;
        font-weight: normal;
        font-size: 20px;
        @media only screen and (max-width: 768px){
          font-size: 15px;
        }
        .Bold{
          font-weight: bold;
        }
        textarea{
          max-height: 45px;
          width: 100%;
          padding: 8px;
          margin: 10px 0;
          border: 1px solid #B72937;
          resize: none;
          font-weight: bold;
          font-size: 20px;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          &:focus{
            outline-style: solid;
            box-shadow: #B72937;
          }
          @media only screen and (max-width: 768px){
            font-size: 15px;
          }
        }
        .p-button.p-component{
          background-color: black;
          border: 1px solid black;
          color: white;
          padding: 12px 30px;
          margin: 0 20px 0 0;
          &:hover{
            background-color: white;
            border: 1px solid #B72937;
            color: #B72937;
          }
        }
        .FAQ-Category_Header-Title_Buttons{
          margin-top: 10px;
          .SaveCategoryNameButton{
            background-color: #B72937;
            border: 1px solid #B72937;
          }
          @media only screen and (max-width: 525px){
            display: flex;
            justify-content: center;
            .p-button.p-component{
              margin: 0 5px;
              padding: 10px;
              width: 120px;
              font-size: 10px;
            }
            ::v-deep(.p-button .p-button-icon-left){
              margin: 2px;
              font-size: 10px;
            }
          }
        }
      }
      .FAQ-Category_Header-Icons{
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        height: 100%;
        width: 90px;
        i{
          cursor: pointer;
          margin: 10px;
          font-size: 20px;
          &:hover{
            color: #B72937;
          }
        }
        .p-tooltip.p-component.p-tooltip-top{
          background-color: red;
        }
      }
    }
    div{
      width: 100%;
    }
  }
  .ghost{
    opacity: 0.5;
    background: #B72937;
  }
}

</style>
