import axios from 'axios'
import authHeader from '@/services/auth_header'
import { handleRequestError } from '@/utils/utils'
import authContentTypeHeader from '@/services/auth_content_type_header'
import { NewsletterModel } from '@/models/newsletterModel'

export class NewsletterService {
  constructor (keyCloak) {
    this.keyCloak = keyCloak
    this.baseUrl = process.env.VUE_APP_BASE_BACKEND_URL
  }

  async fetchNewsletter () {
    const options = {
      method: 'get',
      url: this.baseUrl + '/newsletter',
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(response => {
      const newsletterList = []
      response.data.newsletters.forEach(value => {
        newsletterList.push(new NewsletterModel(value))
      })
      return newsletterList
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async fetchSelectedNewsletter (id) {
    const options = {
      method: 'get',
      url: this.baseUrl + '/newsletter/' + id,
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(response => {
      return response
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async fetchNewsletterCategories () {
    const options = {
      method: 'get',
      url: this.baseUrl + '/newsletter/categories',
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(response => {
      return response
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async addNewsletter (newsletterData) {
    const options = {
      method: 'post',
      url: this.baseUrl + '/newsletter',
      data: newsletterData,
      headers: authContentTypeHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(res => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async sendNewsletter (newsletterData, id) {
    const options = {
      method: 'post',
      url: this.baseUrl + '/newsletter/send/' + id,
      data: newsletterData,
      headers: authContentTypeHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(res => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async updateNewsletter (newsletterData) {
    const options = {
      method: 'put',
      url: this.baseUrl + '/newsletter',
      data: newsletterData,
      headers: authContentTypeHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(response => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async sendNewsletterCategory (newsletterCategory) {
    const options = {
      method: 'post',
      url: this.baseUrl + '/newsletter/categories',
      data: newsletterCategory,
      headers: authContentTypeHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(response => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }

  async deleteNewsletter (id) {
    const options = {
      method: 'delete',
      url: this.baseUrl + '/newsletter/' + id,
      headers: authHeader(this.keyCloak.token)
    }

    const response = await axios(options).then(response => {
      return true
    }).catch(async (error) => {
      await handleRequestError(error)
    })

    return Promise.resolve(response)
  }
}
