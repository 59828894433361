<template>
  <div class="shipmentInfoBox">
    <div class="p-grid">
      <div class="p-col-12 p-lg-6">
        <h3>Dane odbiorcy</h3>
        <div class="stock-box">
          <h4>Adam Kowalski</h4>
          <p>Teczowa 13/2</p>
          <p>58-532 Toruń</p>
          <p>723-322-654</p>
          <span>Zmień</span>
        </div>
      </div>
      <div class="p-col-12 p-lg-6">
        <h3> Sposób dostawy</h3>
        <div class="stock-box">
          <h4>Kurier 24h</h4>
          <span>Zmień</span>
        </div>
      </div>
    </div>
  </div>
  <div class="priceInfoBox">
    <div class="priceBox">
      <h3>Łączna kwota</h3>
      <h2>1982zł</h2>
    </div>
    <div class="checkboxTermsOfService">
      <Checkbox v-model="isTermsOfServiceChecked" :binary="true" />
      <p @click="toggleModal">Akceptuję regulamin sklepu (zgoda wymagana)</p>
    </div>
    <CustomButton label="Zamawiam i płacę"/>
  </div>

  <!--
    DIALOG WITH TERMS
  -->

  <Dialog v-model:visible="isModalDisplayed" modal="true">
    <template #header>
      <h3>Regulamin sklepu</h3>
    </template>
    <div class="modalContent">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
        ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
        culpa qui officia deserunt mollit anim id est laborum.</p>
      <br />
      <p>"Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae
        dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
        qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam,
        quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur,
        vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?</p>
      <br />
      <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident,
        similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio
        cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe
        eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.</p>
    </div>
  </Dialog>
</template>

<script>
import CustomButton from '@/components/CustomButton'
import Checkbox from 'primevue/checkbox'
import Dialog from 'primevue/dialog'
import { ref } from 'vue'

export default {
  name: 'ShoppingCartStepThreeCardColumn',
  components: { CustomButton, Checkbox, Dialog },
  setup () {
    const isTermsOfServiceChecked = ref(false)
    const isModalDisplayed = ref(false)

    const toggleModal = () => {
      isModalDisplayed.value = true
    }

    return { isTermsOfServiceChecked, isModalDisplayed, toggleModal }
  }
}
</script>

<style lang="scss" scoped>
.shipmentInfoBox {
  border: 1px solid $grey;
  background-color: white;
  padding: 15px;

  .stock-box {
    padding: 15px;
  }

  h3 {
    font-weight: 500;
    color: $brown;
  }

  h4 {
    font-weight: 500;
    margin-bottom: 5px;
  }

  span {
    text-transform: uppercase;
    color: blue;
    font-size: 12px;
    cursor: pointer;
  }
}

.priceInfoBox {
  margin-top: 30px;
  border: 1px solid $grey;
  background-color: white;
  padding: 15px;

  .priceBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h3 {
      font-weight: 500;
    }
  }

  .checkboxTermsOfService {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;

    p {
      margin-left: 5px;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.modalContent {
  padding: 15px;
}

::v-deep(.p-dialog .p-dialog-header) {
  color: $brown;
}

::v-deep(.p-dialog) {
  width: 50vw;
  z-index: 1100;

  @media screen and (max-width: 768px) {
    width: 90vw;
  }
}
</style>
