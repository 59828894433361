<template>
  <div v-if="!isLoading" class="navbar">
    <div class="card p-d-flex p-jc-between p-ai-center" v-if="!isMobile">
      <div class="logo-button-section p-d-flex p-jc-center p-ai-center" :style="{ width: drawerWidth }">
        <router-link to="/">
          <img alt="logo" src="../assets/abler_logo.png" class="navbarLogo" :class="{'resize-image-collapsed': collapsed}">
        </router-link>
      </div>
      <div class="text-section p-d-flex p-jc-start p-pl-6" :class="{'text-section-collapsed': collapsed}">
        <h3>{{ navigatorText }}</h3>
      </div>
      <div class="shoppingCartAndProfileWrapper p-d-flex p-jc-center p-ai-center">
        <div class="p-d-flex p-jc-center p-ai-center">
          <div class="new-messages-button p-mr-3" >
              <div class="p-overlay-badge">
                <div class="messages-icon p-text-secondary" style="font-size: 1.5rem" v-badge="currentMessages.length"></div>
<!--                <span-->
<!--                  v-if="currentMessages.length >= 1"-->
<!--                  class="p-badge p-badge-sm">-->
<!--                    {{ currentMessages.length }}-->
<!--                </span>-->
              </div>
          </div>
          <div class="shoppingCartBasket p-mr-3" @click="toggleShoppingBasketOverlayPanel">
              <div class="p-overlay-badge">
                <div class="shopping-cart-icon p-text-secondary" style="font-size: 1.5rem"></div>
                <span
                  v-if="currentShoppingCart.products.length >= 1"
                  class="p-badge p-badge-sm">
                    {{ currentShoppingCart.products.length }}
                </span>
              </div>
          </div>
        </div>
        <div class="user-row p-d-flex p-ai-center p-jc-center">
<!--          <img class="p-mr-2" alt="avatar" src="@/assets/avatar.png">-->
<!--          <p>{{ userProfile.firstName + ' ' + userProfile.lastName }}</p>-->
<!--          <Button icon="pi pi-angle-down" class="p-button-rounded p-button-help p-button-text" />-->
          <Menubar :model="items"></Menubar>
<!--          <SplitButton :label="userProfile.firstName + ' ' + userProfile.lastName" icon="pi user-avatar" :model="userMenu">-->
<!--          </SplitButton>-->
        </div>
      </div>
    </div>
    <div v-else>
      <Menubar :model="itemsMobile">
        <template #start>
            <router-link to="/">
              <img alt="logo" src="../assets/abler_logo.png" class="navbarLogo p-mr-2">
            </router-link>
        </template>
        <template #end>
          <div class="shoppingCartAndProfileWrapper p-d-flex p-jc-center p-ai-center">
            <div class="p-d-flex p-jc-center p-ai-center">
              <div class="new-messages-button p-mr-3" >
                <div class="p-overlay-badge">
                  <div class="messages-icon p-text-secondary" style="font-size: 1.5rem" v-badge="currentMessages.length"></div>
                  <!--                <span-->
                  <!--                  v-if="currentMessages.length >= 1"-->
                  <!--                  class="p-badge p-badge-sm">-->
                  <!--                    {{ currentMessages.length }}-->
                  <!--                </span>-->
                </div>
              </div>
              <div class="shoppingCartBasket" @click="toggleShoppingBasketOverlayPanel">
                <div class="p-overlay-badge">
                  <div class="shopping-cart-icon p-text-secondary" style="font-size: 1.5rem"></div>
                  <span
                    v-if="currentShoppingCart.products.length >= 1"
                    class="p-badge p-badge-sm">
                    {{ currentShoppingCart.products.length }}
                </span>
                </div>
              </div>
            </div>
          </div>
        </template>
      </Menubar>
    </div>
  </div>
  <OverlayPanel ref="overlayPanelRef">
    <div class="shoppingBasketContainer">
      <div class="shoppingBasketHeader">
        <div class="addNewShoppingBasket">
          <i
            @click="toggleAddingNewShoppingCart"
            class="pi"
            :class="addingNewShoppingCart === false ? 'pi-plus' : 'pi-minus'"
          ></i>
        </div>
        <div v-if="addingNewShoppingCart === false" class="changeShoppingBasket">
          <i @click="previousShoppingCart" class="pi pi-chevron-left"
             :class="shoppingCarts.indexOf(currentShoppingCart) === 0 ? 'disabled' : ''"></i>
          <h3>{{ currentShoppingCart.name }}</h3>
          <i @click="nextShoppingCart" class="pi pi-chevron-right"
             :class="shoppingCarts.indexOf(currentShoppingCart) === shoppingCarts.length - 1 ? 'disabled' : ''"></i>
        </div>
        <div v-else>
          <input label="nowy koszyk" class="addingNewShoppingCartInput" type="text" placeholder="Podaj nazwę koszyka..."/>
        </div>
        <div class="lastDivShoppingBasket">
          <i v-if="addingNewShoppingCart === true"
             class="pi pi-check"
          ></i>
        </div>
      </div>
      <div class="shoppingBasketContent">
        <div v-if="currentShoppingCart.products.length > 0">
          <div v-for="product in currentShoppingCart.products.slice(0,3)" :key="product.id">
            <ShoppingCartProductItem
              :name="product.name"
              :amount="product.amount"
              :price="product.price"
            />
          </div>
        </div>

        <p v-if="currentShoppingCart.products.length > 3">Przejdź do koszyka, aby zobaczyć resztę produktów.</p>

        <div class="noProductsText" v-if="currentShoppingCart.products.length === 0">
          Wybrany koszyk jest pusty!
        </div>
      </div>
      <div class="shoppingBasketFooter">
        <Button @click="routeToShoppingCart" label="Koszyk"/>
        <div class="summary">
          <p>Łączna kwota</p>
          <h3>{{ currentShoppingCart.getTotalPrice() }}zł</h3>
        </div>
      </div>
    </div>
  </OverlayPanel>
</template>

<script>
import OverlayPanel from 'primevue/overlaypanel'
import Button from 'primevue/button'
// import SplitButton from 'primevue/splitbutton'
import Menubar from 'primevue/menubar'
import BadgeDirective from 'primevue/badgedirective'
import ShoppingCartProductItem from '@/components/shopping_cart/step_one/ShoppingCartProductItem'
import { drawerWidth, collapsed, toggleDrawer } from '@/components/NavigationDrawer/drawerState'
import { ref, computed, inject, onMounted } from 'vue'
import { navigationItems, productCategoryNavigationItems } from '@/utils/tempData'
import { useStore } from 'vuex'
import router from '@/router'

export default {
  directives: {
    badge: BadgeDirective
  },
  setup () {
    const isLoading = ref(true)
    const overlayPanelRef = ref(null)
    const store = useStore()
    const shoppingCarts = computed(() => store.getters['shoppingCartStore/getShoppingCarts'])
    const currentShoppingCart = computed(() => store.getters['shoppingCartStore/getCurrentShoppingCart'])
    // TODO implement messages
    const currentMessages = ref(['1', '2'])
    const addingNewShoppingCart = ref(false)
    const newShoppingCartName = ref(null)
    const navigatorText = ref('')
    const userProfile = ref('')
    const isMobile = computed(() => {
      if (screen.width >= 768) {
        return false
      } else {
        return true
      }
    })

    const keycloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)

    const userMenu = ref([
      {
        key: 1,
        label: 'Ustawienia konta',
        icon: 'pi product-icon'
      },
      {
        key: 2,
        label: 'Dane adresowe',
        name: 'cut-icon',
        icon: 'pi cut-icon',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Cutting boards' })
        }
      },
      {
        label: 'Dane do faktury',
        icon: 'pi help-icon',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'FAQ config' })
        }
      }])

    const items = ref([])
    const itemsMobile = ref([
      {
        key: 1,
        label: 'Katalog produktów',
        icon: 'pi product-icon',
        items: productCategoryNavigationItems
      },
      {
        key: 2,
        label: 'Rozkrój płyt',
        name: 'cut-icon',
        icon: 'pi cut-icon',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Cutting boards' })
        }
      },
      {
        label: 'FAQ',
        icon: 'pi help-icon',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'FAQ config' })
        }
      },
      {
        label: 'Dokumenty',
        icon: 'pi document-icon',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Documents' })
        }
      },
      {
        label: 'Program lojalnościowy',
        icon: 'pi handshake-icon',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Loyalty program' })
        }
      },
      {
        label: 'Newsletter',
        icon: 'pi newsletter-icon',
        items: [
          {
            label: 'Tworzenie newslettera',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Newsletter config' })
            }
          }
        ],
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Newsletter' })
        }
      }
    ])

    onMounted(async () => {
      isLoading.value = true
      if (keycloak.token !== undefined) {
        await store.dispatch('shoppingCartStore/fetchShoppingCarts', keycloak.token)
      }

      userProfile.value = await keycloak.loadUserProfile()

      switch (router.currentRoute.value.name) {
        case 'Home':
          navigatorText.value = 'Witaj ' + userProfile.value.firstName + ' ' + userProfile.value.lastName + '!'
          break
        case 'Cutting boards':
          navigatorText.value = 'Rozkrój płyt'
          break
        case 'FAQ':
          navigatorText.value = 'FAQ'
          break
        case 'Documents':
          navigatorText.value = 'Dokumenty'
          break
        case 'Loyalty program':
          navigatorText.value = 'Program lojalnościowy'
          break
        case 'Newsletter':
          navigatorText.value = 'Newsletter'
          break
        case 'FAQ config':
          navigatorText.value = 'Konfiguracja FAQ'
          break
        case 'Newsletter config':
          navigatorText.value = 'Konfiguracja newslettera'
          break
        case 'Loyalty program config':
          navigatorText.value = 'Konfiguracja programu lojalnościowego'
          break
        case 'Orders':
          navigatorText.value = 'Zamówienia'
          break
        case 'Account':
          navigatorText.value = 'Ustawienia konta'
          break
        case 'ProductDetails':
          navigatorText.value = 'Informacje o produkcie'
          break
        case 'ShoppingCart':
          navigatorText.value = 'Koszyk'
          break
        default:
          navigatorText.value = 'Witaj w Ablerze'
          break
      }

      router.beforeEach(to => {
        switch (to.name) {
          case 'Home':
            navigatorText.value = 'Witaj ' + userProfile.value.firstName + ' ' + userProfile.value.lastName + '!'
            break
          case 'Cutting boards':
            navigatorText.value = 'Rozkrój płyt'
            break
          case 'FAQ':
            navigatorText.value = 'FAQ'
            break
          case 'Documents':
            navigatorText.value = 'Dokumenty'
            break
          case 'Loyalty program':
            navigatorText.value = 'Program lojalnościowy'
            break
          case 'Newsletter':
            navigatorText.value = 'Newsletter'
            break
          case 'FAQ config':
            navigatorText.value = 'Konfiguracja FAQ'
            break
          case 'Newsletter config':
            navigatorText.value = 'Konfiguracja newslettera'
            break
          case 'Loyalty program config':
            navigatorText.value = 'Konfiguracja programu lojalnościowego'
            break
          case 'Orders':
            navigatorText.value = 'Zamówienia'
            break
          case 'Account':
            navigatorText.value = 'Ustawienia konta'
            break
          case 'ProductDetails':
            navigatorText.value = 'Informacje o produkcie'
            break
          case 'ShoppingCart':
            navigatorText.value = 'Koszyk'
            break
          default:
            navigatorText.value = 'Witaj w Ablerze'
            break
        }
      })

      items.value = [
        {
          key: 1,
          label: userProfile.value.firstName + ' ' + userProfile.value.lastName,
          icon: 'pi user-avatar',
          items: [
            {
              label: 'Ustawienia konta',
              command: () => {
                router.push({ name: 'Account' })
              }
            },
            {
              label: 'Dane adresowe',
              command: () => {
                router.push({ name: 'Account' })
              }
            },
            {
              label: 'Dane do faktury',
              command: () => {
                router.push({ name: 'Account' })
              }
            }
          ]
        }
      ]
      isLoading.value = false
    })

    const toggleShoppingBasketOverlayPanel = (event) => {
      if (!router.currentRoute.value.path.includes('shopping-cart')) {
        if (screen.width >= 768) {
          overlayPanelRef.value.toggle(event)
        } else {
          router.push('/shopping-cart')
        }
      } else {
        store.dispatch('snackbarStore/showMessage', 'Aktualnie jesteś już w koszyku!')
      }
    }

    const toggleAddingNewShoppingCart = () => {
      addingNewShoppingCart.value = !addingNewShoppingCart.value
    }

    const previousShoppingCart = () => {
      const currentIndex = shoppingCarts.value.indexOf(currentShoppingCart.value)

      if (currentIndex !== 0) {
        store.dispatch('shoppingCartStore/setCurrentShoppingCartId', shoppingCarts.value[currentIndex - 1].id)
      }
    }

    const nextShoppingCart = () => {
      const currentIndex = shoppingCarts.value.indexOf(currentShoppingCart.value)

      if (currentIndex !== shoppingCarts.value.length - 1) {
        store.dispatch('shoppingCartStore/setCurrentShoppingCartId', shoppingCarts.value[currentIndex + 1].id)
      }
    }

    const routeToShoppingCart = () => {
      toggleShoppingBasketOverlayPanel()

      router.push('/shopping-cart')
    }

    const onUserClick = async () => {
      if (keycloak.token === undefined || keycloak.isTokenExpired()) {
        await keycloak.login()
      } else {
        await router.push('/account')
      }
    }

    return {
      isLoading,
      navigationItems,
      isMobile,
      items,
      itemsMobile,
      userMenu,
      navigatorText,
      userProfile,
      toggleShoppingBasketOverlayPanel,
      overlayPanelRef,
      toggleAddingNewShoppingCart,
      addingNewShoppingCart,
      currentShoppingCart,
      currentMessages,
      previousShoppingCart,
      nextShoppingCart,
      shoppingCarts,
      newShoppingCartName,
      routeToShoppingCart,
      onUserClick,
      drawerWidth,
      collapsed
    }
  },
  name: 'NavigationBar',
  components: {
    OverlayPanel,
    Button,
    Menubar,
    // SplitButton,
    ShoppingCartProductItem
  }
}
</script>
<style lang="scss" scoped>

.navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #ffffff;

  ::v-deep(.pi){
    font-size: 1.5rem;
  }

  .card{
   height: 100px;

    .logo-button-section{
      border-right-width: 2px;
      border-right-color: #DFDFDF;
      border-right-style: solid;
      height: 100px;
      transition: 0.3s ease;

      @media screen and (max-width: 768px) {
      width: 75px !important;
      }
    }

    .text-section{
      width: calc( 100% - 700px);

      @media screen and (max-width: 768px) {
        display: none !important;
      }
    }
    .text-section-collapsed{
      width: calc( 100% - 550px);
      padding-left: 2rem !important;

      @media screen and (max-width: 768px) {
        width: calc( 100% - 250px);
        padding-left: 0.1rem !important;
      }
    }
  }
  img {
    width: 133.94px;
    height: 66.33px;
    transition: 0.3s ease;

    @media screen and (max-width: 768px) {
      width: calc(133.94px/2);
      height: calc(66.33px/2);
    }
  }

  .resize-image-collapsed{
    width: calc(133.94px/2);
    height: calc(66.33px/2);
  }
}

.disabled {
  opacity: 30%;
  cursor: default !important;
}

.noProductsText {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 100%;
}

.shoppingCartAndProfileWrapper {
  max-width: 400px;
  padding-right: 30px;

  @media screen and (max-width: 768px) {
    margin-right: 5px;
    padding-right: 0;
  }

  .shoppingCartBasket {
    cursor: pointer;
    padding: 5px 8px;
    font-size: 9px;
    font-weight: 500;
    border-radius: 10px;
    color: black;
    display: flex;

    .shopping-cart-icon{
      width: 31px;
      height: 31px;
      background-image: url("../assets/icons/shopping_basket_icon.svg");
    }

    .shoppingCartNameSpan {
      width: 100%;
      margin-left: 15px;
      align-self: center;
      text-transform: uppercase;
    }
  }
  .new-messages-button{
    padding: 5px 8px;

    .messages-icon{
      width: 24px;
      height: 18px;
      background-image: url("../assets/icons/messages_icon.svg");
    }
  }

  .shoppingCartProfile {
    color: black;
    cursor: pointer;
  }

  .user-row{
    ::v-deep(.p-menubar){
      background: #ffffff;
      border: none;

      .p-submenu-list{
        width: 15rem;
      }
    }

    ::v-deep(.user-avatar){
      width: 50px;
      height: 30px;
      background-image: url("../assets/avatar.png");
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}

.shoppingBasketContainer {
  width: 350px;
  background-color: white;

  .shoppingBasketHeader {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    padding: 1rem 1rem 0 1rem;

    .addNewShoppingBasket {
      flex: 1;

      i {
        cursor: pointer;
      }
    }

    .changeShoppingBasket {
      display: flex;
      flex: auto;
      align-items: center;
      justify-content: space-evenly;

      i {
        font-size: 12px;
        cursor: pointer;
      }
    }

    .lastDivShoppingBasket {
      flex: 1;
      text-align: end;

      i {
        cursor: pointer;
      }
    }

    .addingNewShoppingCartInput {
      height: 24px;
      border: none;
      border-bottom: 1px solid black;
      padding: 0 4px;
    }
  }

  .shoppingBasketContent {
    padding: 0 1rem 1rem 1rem;
    height: 320px;

    p {
      font-size: 12px;
      text-align: center;
      margin-top: 16px;
    }
  }

  .shoppingBasketFooter {
    border-top: 1px solid #c8c8c8;
    display: flex;
    padding: 0.6rem 1rem;

    Button {
      flex: 1;
      border-radius: 0;
      border: none;
      color: white;
    }

    Button:hover {
      background-color: $dark_brown;
    }

    .summary {
      flex: 2;
      justify-content: center;
      align-items: center;
      align-self: center;
      text-align: right;
    }
  }
}
</style>
