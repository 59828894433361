<template>
  <div class="drawer" :style="{ width: drawerWidth }">
    <div class="user-section p-d-flex p-jc-center" :class="{'resize-user-section-collapsed': collapsed}">
    <!--      TODO Create User Profile Photos-->
      <div class="p-d-flex p-dir-col p-ai-center p-jc-center">
        <div class="avatar-box p-d-flex p-ai-center p-jc-center">
          <img alt="avatar" src="../../assets/avatar.png" :class="{'resize-image-collapsed': collapsed}">
        </div>
        <div v-if="collapsed" class="p-d-flex p-dir-col p-ai-center p-jc-center">
        </div>
        <div v-else class="p-d-flex p-dir-col p-ai-center p-jc-center">
          <h4>{{ userProfile.firstName + ' ' + userProfile.lastName }}</h4>
          <p>{{  userRole }}</p>
        </div>
        <i class="pi pi-bars" @click="toggleDrawer" style="color: #ffffff;"/>
      </div>
    </div>
    <div class="menu-section">
      <PanelMenu class="custom-panel-menu" :model="items" :class="{'resize-text-collapsed': collapsed}"/>
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue'
import router from '@/router'
import PanelMenu from 'primevue/panelmenu'
import { productCategoryNavigationItems } from '@/utils/tempData'
import { drawerWidth, toggleDrawer, collapsed } from '@/components/NavigationDrawer/drawerState'
import { parseUserRoles } from '@/utils/utils'

export default {
  name: 'NavigationDrawer',
  components: {
    PanelMenu
  },
  setup () {
    const menu = ref()
    const keyCloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const userProfile = ref('')
    const userRole = ref('')
    const items = ref([
      {
        label: 'Konfiguracja karuzeli',
        name: 'cut-icon',
        icon: 'pi cut-icon',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Carousel config' })
        }
      },
      {
        label: 'Dodaj artykuł',
        name: 'cut-icon',
        icon: 'pi newsletter-icon',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Article config' })
        }
      },
      {
        key: 1,
        label: 'Katalog produktów',
        icon: 'pi product-icon',
        items: productCategoryNavigationItems
      },
      {
        key: 2,
        label: 'Rozkrój płyt',
        name: 'cut-icon',
        icon: 'pi cut-icon',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Cutting boards' })
        }
      },
      {
        label: 'FAQ',
        icon: 'pi help-icon',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'FAQ' })
        },
        items: [
          {
            label: 'Edycja FAQ',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'FAQ config' })
            }
          }
        ]
      },
      {
        label: 'Dokumenty',
        icon: 'pi document-icon',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Documents' })
        }
      },
      {
        label: 'Program lojalnościowy',
        icon: 'pi handshake-icon',
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Loyalty program' })
        }
      },
      {
        label: 'Newsletter',
        icon: 'pi newsletter-icon',
        items: [
          {
            label: 'Tworzenie newslettera',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Newsletter config' })
            }
          },
          {
            label: 'Zarządzanie kategoriami',
            command: () => {
              if (collapsed.value) {
                toggleDrawer()
              }
              router.push({ name: 'Newsletter categories config' })
            }
          }
        ],
        command: () => {
          if (collapsed.value) {
            toggleDrawer()
          }
          router.push({ name: 'Newsletter' })
        }
      }
    ])

    onMounted(async () => {
      userProfile.value = await keyCloak.loadUserProfile()
      userRole.value = parseUserRoles(keyCloak.realmAccess.roles)
    })
    return { items, menu, drawerWidth, toggleDrawer, collapsed, userProfile, userRole }
  }
}
</script>

<style lang="scss" scoped>
.drawer{
  background-color: $navigation_black;
  position: fixed;
  top: 100px;
  bottom: 0;
  overflow-y: auto;
  transition: 0.3s ease;

  .user-section{
    padding: 45px 0 30px 0;
    transition: 0.3s ease;

    .avatar-box{
      width: 81px;
      height: 81px;
    }

    img{
      margin-bottom: 25px;
      width: 81px;
      height: 81px;
      transition: 0.2s linear;
    }

    .resize-image-collapsed{
      width: 40px;
      height: 40px;
      transition: 0.2s linear;
    }

    .user-info-wrapper{
      height: 65px;
    }
    h4{
      color: white;
    }

    p{
      margin: 5px;
      color: white;
      font-size: 0.9em;
    }
  }

  .resize-user-section-collapsed{
    padding: 15px 0 10px 0;
    transition: 0.3s ease;
  }
  .resize-text-collapsed{
    display: flex;
    flex-direction: column;
    margin-left: 0;
    font-size: 0.6rem !important;
    transition: 0.3s ease;

    ::v-deep(.p-panelmenu-header-link){
      .p-menuitem-text{
        display: none;
      }
    }
  }

  ::v-deep(.p-panelmenu) {
    background: $navigation_black;

    .p-panelmenu-header:hover{
      color: black;
    }

    .p-panelmenu-header > a {
      background: $navigation_black;
      border: none;
    }
    .p-panelmenu-header > a > .p-menuitem-icon {
      margin-right: 1rem;
      margin-left: 1rem;
    }

    .p-panelmenu-content{
      background: $navigation_black;
      border: none;

      a[aria-expanded="true"]{
        background-color: #242424;

        .p-menuitem-text{
          color: #FFCBD5;
        }
      }

      .p-menuitem-link{
        color: #ffffff;

        .p-menuitem-text{
          color: #ffffff;
        }
      }
    }

    .product-icon{
      height: 20px;
      width: 18px;
      background-image: url("../../assets/icons/products_icon.svg");
    }

      .cut-icon{
      width: 17.692px;
      height: 19.108px;
      background: url("../../assets/icons/cut_icon.svg");
    }

    .help-icon{
      height: 21px;
      width: 21px;
      background-image: url("../../assets/icons/help_icon.svg");
    }

    .document-icon{
      height: 23px;
      width: 17.692px;
      background-image: url("../../assets/icons/document_icon.svg");
    }

    .handshake-icon{
      height: 18px;
      width: 22px;
      background-image: url("../../assets/icons/handshake_icon.svg");
    }
    .newsletter-icon{
      height: 18px;
      width: 20px;
      background-image: url("../../assets/icons/newsletter_icon.svg");
    }

    .p-menuitem-text{
      margin-left: 0.5rem;
      transition: 0.3s ease;
    }

    .p-panelmenu-icon{
      position: absolute;
      right: 0.5em;
    }

    .p-panelmenu-header.p-highlight > a {
      background: #565656;
      border-color: #565656;
    }
  }
}

</style>
