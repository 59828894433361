<template>
  <div class="ordersWrapper">
    <div
      class="p-grid  p-flex-row-reverse">
      <div class="p-col-12 p-lg-3">
        <div class="p-grid">
          <div class="p-col-12 p-md-6 p-lg-12">
            <Card>
              <template v-slot:header>
                <img alt="user header" src="../../assets/meble.jpg">
              </template>
              <template v-slot:title>
                Moje dane
              </template>
              <template v-slot:content>
                <p><b>Imię:</b> Jan Kowalski</p>
                <p><b>Miasto:</b> Toruń 87-100</p>
                <p><b>Ulica:</b> Szosa Chełmińska 14</p>
              </template>
            </Card>
          </div>

          <div class="p-col-12 p-md-6 p-lg-12">
            <Card>
              <template v-slot:title>Moje statystyki</template>
              <template v-slot:content>
                <Chart type="doughnut" :data="chartAccountStats" :options="chartAccountStats.options" :height="250"/>
              </template>
            </Card>
          </div>

          <div class="p-col-12 p-lg-12">
            <Card>
              <template v-slot:title>
                Najczęściej kupowane przedmioty
              </template>
              <template v-slot:content>
                <Carousel
                  :value="carouselBestProducts"
                  :numVisible="1"
                  :numScroll="1"
                  :autoplayInterval="2000"
                  :circular="true">
                  <template #item="slotProps">
                    <div class="product-item">
                      <div class="product-item-content">
                        <div class="p-mb-3">
                          <img :src="require('@/assets/' + slotProps.data.image)" :alt="slotProps.data.name"
                               class="productImage"/>
                        </div>
                        <div>
                          <h4 class="p-mb-1">{{ slotProps.data.name }}</h4>
                          <h6 class="p-mt-0 p-mb-3">{{ slotProps.data.price }}zł.</h6>
                        </div>
                      </div>
                    </div>
                  </template>
                </Carousel>
              </template>
            </Card>
          </div>
        </div>
      </div>
      <div
        class="p-col-12 p-lg-9">
        <DataTable
          :value="ordersTable1"
          v-model:expandedRows="expandedRowsTable1"
          currentPageReportTemplate="( {currentPage} z {totalPages} )"
          :paginator='true'
          :class="(mobileExtraOptionsToggledTable1 ? 'mobileExtraOptionsEnabled' : 'mobileExtraOptionsDisabled') + ' p-datatable-sm'"
          :rows="10"
          dataKey="id"
          columnResizeMode="expand"
          @row-click="onRowClickTable1"
          :filters="filtersTable1"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport
          RowsPerPageDropdown"
          :rowsPerPageOptions="[5,10,25]">

          <template #header>
            <h3>Bieżące zamówienia</h3>

            <div class="headerActions">
              <span class="p-input-icon-left search">
                <i class="pi pi-search"/>
                <InputText
                  class="search"
                  v-model="filtersTable1['global']"
                  placeholder="Wyszukiwanie..."
                />
              </span>
              <ToggleButton
                v-model="mobileExtraOptionsToggledTable1"
                class="p-d-md-none"
                onIcon="pi pi-angle-double-up"
                offIcon="pi pi-angle-double-down"
              />
            </div>
          </template>

          <template #empty>
            Nie znaleziono elementów!
          </template>

          <template #expansion="slotProps">
            <h3 class="expandedHeader">Zamówienie: #{{ slotProps.data.orderNumber }}</h3>
            <div class="p-grid expandedContent">
              <div class="p-col-12 p-md-5 expandedContentDetails">
                <img src="../../assets/abler_logo.png" alt="abler.png">
                <h4>Szczegóły:</h4>
                <p>Zamawiający: {{ slotProps.data.name }}</p>
                <p>Numer przewozowy: {{ slotProps.data.trackingNumber }}</p>
                <p>Dostawca: {{ slotProps.data.deliveryService }}</p>
                <p>Data zamówienia: {{ slotProps.data.orderDate }}</p>
                <p>Data realizacji: {{ slotProps.data.realisationDate }}</p>
              </div>
              <div class="p-col-12 p-md-7 centerSelfChart">
                <Chart type="bar" :data="chartCostPerCategory.multiAxisData"
                       :options="chartCostPerCategory.options"/>
              </div>
            </div>
          </template>

          <Column
            :expander="true"
            bodyClass="expandableColumn"
            headerClass="expandableColumn"
          />

          <Column
            field="orderNumber"
            header="Numer zamówienia"
            :sortable='true'>
            <template #body="slotProps">
              <span class="p-column-title">Numer zamówienia</span>
              <span>{{ slotProps.data.orderNumber }}</span>
            </template>
          </Column>

          <Column
            field="name"
            header="Zamawiający"
            :sortable='true'>
            <template #body="slotProps">
              <span class="p-column-title">Zamawiający</span>
              <span>{{ slotProps.data.name }}</span>
            </template>
          </Column>

          <Column
            field="orderDate"
            header="Data zamówienia"
            filterMatchMode="custom"
            :filterFunction="filterDate"
            :sortable='true'>
            <template #body="slotProps">
              <span class="p-column-title">Data zamówienia</span>
              <span>{{ slotProps.data.orderDate }}</span>
            </template>
          </Column>
        </DataTable>
        <DataTable
          :value="ordersTable2"
          v-model:expandedRows="expandedRowsTable2"
          currentPageReportTemplate="( {currentPage} z {totalPages} )"
          :paginator='true'
          :class="(mobileExtraOptionsToggledTable2 ? 'mobileExtraOptionsEnabled' : 'mobileExtraOptionsDisabled') + ' p-datatable-sm'"
          :rows="10"
          dataKey="id"
          columnResizeMode="expand"
          @row-click="onRowClickTable2"
          :filters="filtersTable2"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5,10,25]">

          <template #header>
            <h3>Wszystkie zamówienia</h3>

            <div class="headerActions">
              <span class="p-input-icon-left search">
                <i class="pi pi-search"/>
                <InputText
                  class="search"
                  v-model="filtersTable2['global']"
                  placeholder="Wyszukiwanie.."
                />
              </span>
              <ToggleButton
                v-model="mobileExtraOptionsToggledTable2"
                class="p-d-md-none"
                onIcon="pi pi-angle-double-up"
                offIcon="pi pi-angle-double-down"
              />
            </div>
          </template>

          <template #empty>
            Nie znaleziono elementów!
          </template>

          <template #expansion="slotProps">
            <h3 class="expandedHeader">Zamówienie: #{{ slotProps.data.orderNumber }}</h3>
            <div class="p-grid expandedContent">
              <div class="p-col-12 p-md-5 expandedContentDetails">
                <img src="../../assets/abler_logo.png" alt="abler.png">
                <h4>Szczegóły:</h4>
                <p>Zamawiający: {{ slotProps.data.name }}</p>
                <p>Numer przewozowy: {{ slotProps.data.trackingNumber }}</p>
                <p>Dostawca: {{ slotProps.data.deliveryService }}</p>
                <p>Data zamówienia: {{ slotProps.data.orderDate }}</p>
                <p>Data realizacji: {{ slotProps.data.realisationDate }}</p>
              </div>
              <div class="p-col-12 p-md-7 centerSelfChart">
                <Chart type="bar" :data="chartCostPerCategory.multiAxisData"
                       :options="chartCostPerCategory.options"/>
              </div>
            </div>
          </template>

          <Column
            :expander="true"
            bodyClass="expandableColumn"
            headerClass="expandableColumn"
          />

          <Column
            field="orderNumber"
            header="Numer zamówienia"
            :sortable='true'>
            <template #body="slotProps">
              <span class="p-column-title">Numer zamówienia</span>
              <span>{{ slotProps.data.orderNumber }}</span>
            </template>
          </Column>

          <Column
            field="name"
            header="Zamawiający"
            :sortable='true'>
            <template #body="slotProps">
              <span class="p-column-title">Zamawiający</span>
              <span>{{ slotProps.data.name }}</span>
            </template>
          </Column>

          <Column
            field="orderDate"
            header="Data zamówienia"
            filterMatchMode="custom"
            :filterFunction="filterDate"
            :sortable='true'>
            <template #body="slotProps">
              <span class="p-column-title">Data zamówienia</span>
              <span>{{ slotProps.data.orderDate }}</span>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import ToggleButton from 'primevue/togglebutton'
import Card from 'primevue/card'
import Chart from 'primevue/chart'
import Carousel from 'primevue/carousel'
import { ref } from 'vue'
import { filterDate, generateRandomOrdersList } from '@/utils/utils'
import { calendarTranslationPl, chartCostPerCategory, chartAccountStats, carouselBestProducts } from '@/utils/tempData'

export default {
  name: 'OrdersComponent',
  components: {
    DataTable,
    Column,
    InputText,
    Card,
    ToggleButton,
    Chart,
    Carousel
  },
  setup () {
    const expandedRowsTable1 = ref([])
    const onRowClickTable1 = (event) => {
      let currentExpandList = [...expandedRowsTable1.value]

      if (currentExpandList.includes(event.data)) {
        currentExpandList = currentExpandList.filter(order => {
          if (order.id !== event.data.id) return order
        })
      } else {
        currentExpandList = currentExpandList.concat(event.data)
      }

      expandedRowsTable1.value = currentExpandList
    }

    const expandedRowsTable2 = ref([])
    const onRowClickTable2 = (event) => {
      let currentExpandList = [...expandedRowsTable2.value]

      if (currentExpandList.includes(event.data)) {
        currentExpandList = currentExpandList.filter(order => {
          if (order.id !== event.data.id) return order
        })
      } else {
        currentExpandList = currentExpandList.concat(event.data)
      }

      expandedRowsTable2.value = currentExpandList
    }

    return {
      // Table 1
      ordersTable1: ref(generateRandomOrdersList()),
      filtersTable1: ref({}),
      expandedRowsTable1,
      mobileExtraOptionsToggledTable1: ref(false),
      onRowClickTable1,
      // Table 2
      ordersTable2: ref(generateRandomOrdersList()),
      filtersTable2: ref({}),
      expandedRowsTable2,
      mobileExtraOptionsToggledTable2: ref(false),
      onRowClickTable2,
      // Other elements
      chartCostPerCategory,
      calendarTranslationPl,
      chartAccountStats,
      carouselBestProducts,
      filterDate
    }
  }
}
</script>

<style lang="scss" scoped>

.p-datatable-sm {
 margin-bottom: 50px;
}

::v-deep(.expandableColumn) {
  width: 5rem;
  @media screen and (max-width: 768px) {
    display: none !important;
  }
}

.ordersWrapper {
  padding: 20px 20px;

  .expandedHeader {
    text-align: center;
  }

  .expandedContent {
    padding: 2% 4%;

    @media screen and (max-width: 768px) {
      padding: 10% 0;

      .expandedContentDetails {
        background-image: url("../../assets/abler_logo.png");
        background-position: center;

        background-color: hsla(0, 0%, 100%, 0.85);
        background-blend-mode: overlay;
        background-repeat: no-repeat;

        img {
          display: none;
        }

        h4, p {
          opacity: 1;
        }
      }
    }
  }

  .centerSelfChart {
    align-self: center;
  }

  ::v-deep(.p-card) {
    height: 100%;

    .p-card-title {
      text-align: center;
    }
  }

  ::v-deep(.p-datatable-header) {
    display: flex;
    justify-content: space-between;
  }

  .product-item {
    max-height: 295px;

    .product-item-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 3px;
      text-align: center;
    }

    .productImage {
      max-width: 60%;
      max-height: 150px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }
  }

  .p-datatable-sm .p-datatable-tbody > tr > td .p-column-title {
    display: none;
  }

  .filter {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .mobileExtraOptionsEnabled {
      ::v-deep(th) {
        display: table-cell;
      }
    }

    .mobileExtraOptionsDisabled {
      ::v-deep(th) {
        display: none;
      }
    }

    ::v-deep(.p-datatable-header) {
      display: block;

      .headerActions {
        margin-top: 4px;
        display: flex;
        justify-content: flex-end;

        .search {
          width: 100%;
        }
      }
    }

    ::v-deep(.p-datatable) {
      &.p-datatable-sm {
        .p-datatable-tfoot > tr > td {
          display: flex;
        }

        .p-datatable-thead {
          tr {
            display: flex;
            flex-basis: 100%;
            flex-direction: column;
          }

          th {
            text-align: right;
            height: 60px
          }
        }

        .p-datatable-tbody > tr > td {
          text-align: left;
          display: block;
          width: 100%;
          float: left;
          clear: left;
          border: 0 none;

          .p-column-title {
            padding: .4rem;
            display: inline-block;
            margin: -.4em 1em -.4em -.4rem;
            font-weight: bold;
            width: 50%;
          }

          &:last-child {
            border-bottom: 1px solid var(--surface-d);
          }
        }
      }
    }
  }
}

</style>

<!--<template>-->
<!--  <div class="ordersWrapper">-->
<!--    <div-->
<!--      :class="(mobileExtraOptionsToggled ? 'mobileExtraOptionsEnabled' : 'mobileExtraOptionsDisabled') + ' p-grid  p-flex-row-reverse'">-->
<!--      <div class="p-col-12 p-lg-3">-->
<!--        <div class="p-grid">-->
<!--          <div class="p-col-12 p-md-6 p-lg-12">-->
<!--            <Card>-->
<!--              <template v-slot:header>-->
<!--                <img alt="user header" src="../assets/meble.jpg">-->
<!--              </template>-->
<!--              <template v-slot:title>-->
<!--                Moje dane-->
<!--              </template>-->
<!--              <template v-slot:content>-->
<!--                <p><b>Imię:</b> Jan Kowalski</p>-->
<!--                <p><b>Miasto:</b> Toruń 87-100</p>-->
<!--                <p><b>Ulica:</b> Szosa Chełmińska 14</p>-->
<!--              </template>-->
<!--            </Card>-->
<!--          </div>-->

<!--          <div class="p-col-12 p-md-6 p-lg-12">-->
<!--            <Card>-->
<!--              <template v-slot:title>Moje statystyki</template>-->
<!--              <template v-slot:content>-->
<!--                <Chart type="doughnut" :data="chartDataAccount" :options="chartDataAccount.options" :height="250"/>-->
<!--              </template>-->
<!--            </Card>-->
<!--          </div>-->

<!--          <div class="p-col-12 p-lg-12">-->
<!--            <Card>-->
<!--              <template v-slot:title>-->
<!--                Najczęściej kupowane przedmioty-->
<!--              </template>-->
<!--              <template v-slot:content>-->
<!--                <Carousel :value="bestProducts" :numVisible="1" :numScroll="1" :autoplayInterval="2000"-->
<!--                          :circular="true">-->
<!--                  <template #item="slotProps">-->
<!--                    <div class="product-item">-->
<!--                      <div class="product-item-content">-->
<!--                        <div class="p-mb-3">-->
<!--                          <img :src="require('@/assets/' + slotProps.data.image)" :alt="slotProps.data.name"-->
<!--                               class="productImage"/>-->
<!--                        </div>-->
<!--                        <div>-->
<!--                          <h4 class="p-mb-1">{{ slotProps.data.name }}</h4>-->
<!--                          <h6 class="p-mt-0 p-mb-3">{{ slotProps.data.price }}zł.</h6>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </template>-->
<!--                </Carousel>-->
<!--              </template>-->
<!--            </Card>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="p-col-12 p-lg-9">-->
<!--        <DataTable-->
<!--          :value="orders"-->
<!--          v-model:expandedRows="expandedRows"-->
<!--          currentPageReportTemplate="( {currentPage} z {totalPages} )"-->
<!--          :paginator='true'-->
<!--          class="p-datatable-sm"-->
<!--          :rows="10"-->
<!--          dataKey="id"-->
<!--          columnResizeMode="expand"-->
<!--          @row-expand="onRowExpand"-->
<!--          :filters="filters"-->
<!--          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"-->
<!--          :rowsPerPageOptions="[5,10,25]">-->

<!--          <template #header>-->
<!--            <h3>-->
<!--              Zamówienia-->
<!--            </h3>-->

<!--            <div class="headerActions">-->
<!--              <span class="p-input-icon-left search">-->
<!--                <i class="pi pi-search" />-->
<!--                <InputText-->
<!--                  class="search"-->
<!--                  v-model="filters['global']"-->
<!--                  placeholder="Wyszukiwanie.."/>-->
<!--              </span>-->

<!--              <ToggleButton-->
<!--                v-model="mobileExtraOptionsToggled"-->
<!--                class="p-d-md-none"-->
<!--                onIcon="pi pi-angle-double-up"-->
<!--                offIcon="pi pi-angle-double-down"/>-->
<!--            </div>-->
<!--          </template>-->

<!--          <template #empty>-->
<!--            Nie znaleziono elementów!-->
<!--          </template>-->

<!--          <template #expansion="slotProps">-->
<!--            <h3 class="expandedHeader">Zamówienie: #{{ slotProps.data.orderNumber }}</h3>-->
<!--            <div class="p-grid expandedContent">-->
<!--              <div class="p-col-12 p-md-6">-->
<!--                <img src="../assets/abler_logo.png" alt="abler.png">-->
<!--                <h4>Szczegóły:</h4>-->
<!--                <p>Zamawiający: {{ slotProps.data.name }}</p>-->
<!--                <p>Numer przewozowy: {{ slotProps.data.trackingNumber }}</p>-->
<!--                <p>Dostawca: {{ slotProps.data.deliveryService }}</p>-->
<!--                <p>Data zamówienia: {{ slotProps.data.orderDate }}</p>-->
<!--                <p>Data realizacji: {{ slotProps.data.realisationDate }}</p>-->
<!--              </div>-->
<!--              <div class="p-col-12 p-md-6 centerSelfChart">-->
<!--                <Chart type="radar" :data="chartDataOrder" :options="chartDataOrder.options"/>-->
<!--              </div>-->
<!--            </div>-->
<!--          </template>-->
<!--          &lt;!&ndash;          <Column field="id" header="ID" :sortable='true'>&ndash;&gt;-->
<!--          &lt;!&ndash;            <template #body="slotProps">&ndash;&gt;-->
<!--          &lt;!&ndash;              <span class="p-column-title">Id</span>&ndash;&gt;-->
<!--          &lt;!&ndash;              {{ slotProps.data.id }}&ndash;&gt;-->
<!--          &lt;!&ndash;            </template>&ndash;&gt;-->
<!--          &lt;!&ndash;            <template #filter>&ndash;&gt;-->
<!--          &lt;!&ndash;              <InputText v-model="filters['id']"&ndash;&gt;-->
<!--          &lt;!&ndash;                         class="filter"&ndash;&gt;-->
<!--          &lt;!&ndash;                         placeholder="ID"/>&ndash;&gt;-->
<!--          &lt;!&ndash;            </template>&ndash;&gt;-->
<!--          &lt;!&ndash;          </Column>&ndash;&gt;-->

<!--          &lt;!&ndash;          <Column&ndash;&gt;-->
<!--          &lt;!&ndash;            :expander="true"&ndash;&gt;-->
<!--          &lt;!&ndash;            headerStyle="width: 5rem"&ndash;&gt;-->
<!--          &lt;!&ndash;          />&ndash;&gt;-->
<!--          <Column-->
<!--            :expander="true"-->
<!--            bodyClass="expandableColumn"-->
<!--            headerClass="expandableColumn"-->
<!--          />-->

<!--          <Column field="orderNumber" header="Numer zamówienia" :sortable='true'>-->
<!--            <template #body="slotProps">-->
<!--              <span class="p-column-title">Numer zamówienia</span>-->
<!--              {{ slotProps.data.orderNumber }}-->
<!--            </template>-->
<!--            &lt;!&ndash;            <template #filter>&ndash;&gt;-->
<!--            &lt;!&ndash;              <InputText v-model="filters['orderNumber']" class="filter" placeholder="Numer zamówienia"/>&ndash;&gt;-->
<!--            &lt;!&ndash;            </template>&ndash;&gt;-->
<!--          </Column>-->

<!--          <Column field="name" header="Zamawiający"-->
<!--                  :sortable='true'>-->
<!--            <template #body="slotProps">-->
<!--              <span class="p-column-title">Zamawiający</span>-->
<!--              {{ slotProps.data.name }}-->
<!--            </template>-->
<!--            &lt;!&ndash;            <template #filter>&ndash;&gt;-->
<!--            &lt;!&ndash;              <InputText v-model="filters['orderer']"&ndash;&gt;-->
<!--            &lt;!&ndash;                         class="filter"&ndash;&gt;-->
<!--            &lt;!&ndash;                         placeholder="Zamawiający"/>&ndash;&gt;-->
<!--            &lt;!&ndash;            </template>&ndash;&gt;-->
<!--          </Column>-->

<!--          &lt;!&ndash;          <Column field="trackingNumber"&ndash;&gt;-->
<!--          &lt;!&ndash;                  header="Numer przewozowy"&ndash;&gt;-->
<!--          &lt;!&ndash;                  :sortable='true'>&ndash;&gt;-->
<!--          &lt;!&ndash;            <template #body="slotProps">&ndash;&gt;-->
<!--          &lt;!&ndash;              <span class="p-column-title">Numer przewozowy</span>&ndash;&gt;-->
<!--          &lt;!&ndash;              {{ slotProps.data.trackingNumber }}&ndash;&gt;-->
<!--          &lt;!&ndash;            </template>&ndash;&gt;-->
<!--          &lt;!&ndash;            <template #filter>&ndash;&gt;-->
<!--          &lt;!&ndash;              <InputText v-model="filters['trackingNumber']"&ndash;&gt;-->
<!--          &lt;!&ndash;                         class="filter"&ndash;&gt;-->
<!--          &lt;!&ndash;                         placeholder="Numer przewozowy"/>&ndash;&gt;-->
<!--          &lt;!&ndash;            </template>&ndash;&gt;-->
<!--          &lt;!&ndash;          </Column>&ndash;&gt;-->
<!--          &lt;!&ndash;          <Column field="deliveryService"&ndash;&gt;-->
<!--          &lt;!&ndash;                  header="Dostawca"&ndash;&gt;-->
<!--          &lt;!&ndash;                  :sortable='true'>&ndash;&gt;-->
<!--          &lt;!&ndash;            <template #body="slotProps">&ndash;&gt;-->
<!--          &lt;!&ndash;              <span class="p-column-title">Dostawca</span>&ndash;&gt;-->
<!--          &lt;!&ndash;              {{ slotProps.data.deliveryService }}&ndash;&gt;-->
<!--          &lt;!&ndash;            </template>&ndash;&gt;-->
<!--          &lt;!&ndash;            <template #filter>&ndash;&gt;-->
<!--          &lt;!&ndash;              <InputText v-model="filters['deliveryService']"&ndash;&gt;-->
<!--          &lt;!&ndash;                         class="filter"&ndash;&gt;-->
<!--          &lt;!&ndash;                         placeholder="Dostawca"/>&ndash;&gt;-->
<!--          &lt;!&ndash;            </template>&ndash;&gt;-->
<!--          &lt;!&ndash;          </Column>&ndash;&gt;-->
<!--          <Column field="orderDate"-->
<!--                  header="Data zamówienia"-->
<!--                  filterMatchMode="custom"-->
<!--                  :filterFunction="filterDate"-->
<!--                  :sortable='true'>-->
<!--            <template #body="slotProps">-->
<!--              <span class="p-column-title">Data zamówienia</span>-->
<!--              <span>{{ slotProps.data.orderDate }}</span>-->
<!--            </template>-->
<!--            &lt;!&ndash;            <template #filter>&ndash;&gt;-->
<!--            &lt;!&ndash;              <Calendar v-model="filters['orderDate']"&ndash;&gt;-->
<!--            &lt;!&ndash;                        dateFormat="dd.mm.yy"&ndash;&gt;-->
<!--            &lt;!&ndash;                        class="filter"&ndash;&gt;-->
<!--            &lt;!&ndash;                        :locale=calendarTranslationPl&ndash;&gt;-->
<!--            &lt;!&ndash;                        :yearNavigator="true"&ndash;&gt;-->
<!--            &lt;!&ndash;                        yearRange="2000:2100"&ndash;&gt;-->
<!--            &lt;!&ndash;                        :manualInput=false&ndash;&gt;-->
<!--            &lt;!&ndash;                        :showButtonBar="true"&ndash;&gt;-->
<!--            &lt;!&ndash;                        appendTo="body"&ndash;&gt;-->
<!--            &lt;!&ndash;                        :showIcon="true"&ndash;&gt;-->
<!--            &lt;!&ndash;                        placeholder="Data zamówienia"/>&ndash;&gt;-->
<!--            &lt;!&ndash;            </template>&ndash;&gt;-->
<!--          </Column>-->
<!--          &lt;!&ndash;          <Column field="realisationDate"&ndash;&gt;-->
<!--          &lt;!&ndash;                  header="Data realizacji"&ndash;&gt;-->
<!--          &lt;!&ndash;                  filterMatchMode="custom"&ndash;&gt;-->
<!--          &lt;!&ndash;                  :filterFunction="filterDate"&ndash;&gt;-->
<!--          &lt;!&ndash;                  yearRange="2000:2100"&ndash;&gt;-->
<!--          &lt;!&ndash;                  :sortable='true'&ndash;&gt;-->
<!--          &lt;!&ndash;                  :class="mobileExtraOptionsToggled ? 'mobileExtraOptionsEnabled' : '' ">&ndash;&gt;-->

<!--          &lt;!&ndash;            <template #body="slotProps">&ndash;&gt;-->
<!--          &lt;!&ndash;              <span class="p-column-title">Data realizacji</span>&ndash;&gt;-->
<!--          &lt;!&ndash;              {{ slotProps.data.realisationDate }}&ndash;&gt;-->
<!--          &lt;!&ndash;            </template>&ndash;&gt;-->
<!--          &lt;!&ndash;            <template #filter>&ndash;&gt;-->
<!--          &lt;!&ndash;              <Calendar v-model="filters['realisationDate']"&ndash;&gt;-->
<!--          &lt;!&ndash;                        dateFormat="dd.mm.yy"&ndash;&gt;-->
<!--          &lt;!&ndash;                        class="filter"&ndash;&gt;-->
<!--          &lt;!&ndash;                        :yearNavigator='true'&ndash;&gt;-->
<!--          &lt;!&ndash;                        :locale=calendarTranslationPl&ndash;&gt;-->
<!--          &lt;!&ndash;                        yearRange="2000:2100"&ndash;&gt;-->
<!--          &lt;!&ndash;                        :showIcon="true"&ndash;&gt;-->
<!--          &lt;!&ndash;                        :manualInput="false"&ndash;&gt;-->
<!--          &lt;!&ndash;                        :showButtonBar="true"&ndash;&gt;-->
<!--          &lt;!&ndash;                        appendTo="body"&ndash;&gt;-->
<!--          &lt;!&ndash;                        placeholder="Data realizacji"/>&ndash;&gt;-->
<!--          &lt;!&ndash;            </template>&ndash;&gt;-->
<!--          &lt;!&ndash;          </Column>&ndash;&gt;-->
<!--        </DataTable>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</template>-->
