<template>
  <div v-if="isLoading"></div>
  <div v-else class="p-d-flex p-dir-col p-ai-center p-mt-6">
    <div class="buttons-section p-d-flex p-jc-center p-mt-3 p-mb-3">
      <Button :icon="isNewChat ? 'pi pi-times' : 'pi pi-plus'" :class="isNewChat ? 'p-button-outlined add-new-newsletter-button' : 'add-new-newsletter-button'" @click="handleAddingChat" type="button" :label="isNewChat ? 'Zamknij' : 'Rozpocznij nową rozmowę'"/>
    </div>
    <div class="p-col-11">
      <h3 class="p-mt-4 p-mb-5">NIEPRZECZYTANE</h3>
      <div class="p-d-flex p-dir-col p-ai-center messages-wrapper">
          <div class="p-col-12">
            <div v-for="(message, index) of unreadMessages" :key="index">
              <a class="p-button-link" @click="handleNavigationToMessage(message.id)">
                <div class="p-ml-4 p-mr-5 message-wrapper">
                  <p class="p-mb-2">{{ message.lastUpdateDate }}</p>
                  <h3 :class="message.read ? 'p-mb-2 bold-text' : 'p-mb-2'">{{ message.id }}</h3>
                </div>
              </a>
            </div>
          </div>
        <Paginator :rows="10" :totalRecords="unreadTotalRecords"  @page="onUnreadPage($event)"></Paginator>
      </div>
    </div>

    <div class="p-col-11">
      <h3 class="p-mt-4 p-mb-5">ZAKOŃCZONE KONWERSACJE</h3>
      <div class="p-d-flex p-dir-col p-ai-center p-mt-6 messages-wrapper">
        <div class="p-col-12">
          <div v-for="(message, index) of finishedMessages" :key="index">
            <a class="p-button-link" @click="handleNavigationToMessage(message.id)">
              <div class="p-ml-4 p-mr-5 message-wrapper">
                <p class="p-mb-2">{{ message.lastUpdateDate }}</p>
                  <p :class="message.read ? 'p-mb-2 bold-text' : 'p-mb-2'">{{ message.id }}</p>
              </div>
            </a>
          </div>
        </div>
        <Paginator :rows="10" :totalRecords="finishedTotalRecords"  @page="onFinishedPage($event)"></Paginator>
      </div>
    </div>
  </div>
</template>

<script>
import Paginator from 'primevue/paginator'
import Button from 'primevue/button'
import router from '@/router'
import { ChatService } from '@/services/chatService'
import { inject, ref, onMounted } from 'vue'

export default {
  name: 'MessagesView',
  components: {
    Paginator,
    Button
  },
  setup (props) {
    const keycloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const chatService = new ChatService(keycloak)

    const unreadTotalRecords = ref()
    const finishedTotalRecords = ref()

    const unreadMessages = ref()
    const finishedMessages = ref()

    const isLoading = ref(false)
    const isNewChat = ref(false)

    onMounted(async () => {
      isLoading.value = true
      await chatService.fetchInProgressChats(0, 9).then(response => {
        unreadTotalRecords.value = response.count
        unreadMessages.value = response.chats
      })
      await chatService.fetchClosedChats(0, 9).then(response => {
        finishedTotalRecords.value = response.count
        finishedMessages.value = response.chats
        isLoading.value = false
      })
    })

    const handleNavigationToMessage = (id) => {
      router.push({ name: 'Chat room', params: { chatRoomId: id } })
    }

    const onUnreadPage = async (event) => {
      await chatService.fetchInProgressChats(event.page !== 0 ? event.page * 10 : 0, event.page !== 0 ? (event.page * 10) + 9 : event.page + 9).then(response => {
        unreadMessages.value = response.chats
      })
    }

    const onFinishedPage = async (event) => {
      await chatService.fetchClosedChats(event.page !== 0 ? event.page * 10 : 0, event.page !== 0 ? (event.page * 10) + 9 : event.page + 9).then(response => {
        finishedMessages.value = response.chats
      })
    }

    const handleAddingChat = async () => {
    }

    return { isNewChat, isLoading, handleAddingChat, unreadTotalRecords, finishedTotalRecords, unreadMessages, finishedMessages, onUnreadPage, onFinishedPage, handleNavigationToMessage }
  }
}
</script>

<style lang="scss" scoped>
.messages-wrapper{
  background-color: white;
}
.buttons-section{
  .p-button.p-component{
    background-color: black;
    border: 1px solid black;
    color: white;
    padding: 12px 30px;
    &:hover{
      background-color: white;
      border: 1px solid #B72937;
      color: #B72937;
    }
  }

  Button{
    height: 3rem;
  }
}
.message-wrapper{
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom-color: #A4A4A4;
  border-style: hidden hidden solid hidden;
  border-width: 0.5px;

  .bold-text{
    font-weight: 600;
  }
}
</style>
