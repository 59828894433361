<template>
  <div class="FAQ">
    <div class="FAQ-Category">
      <div v-for="element in faqCategory" :key="element">
        <h1> {{element.name}} </h1>
        <div v-for="item in element.faqDtoList" :key="item">
          <faqClientItem :faq_item="item"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import faqClientItem from './FAQClientItem.vue'
import { ref } from 'vue'
export default {
  inheritAttrs: false,
  name: 'faqClientComponent',
  components: {
    faqClientItem
  },
  props: {
    faq: {
      type: Array,
      required: true,
      id: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      order: {
        type: Number,
        required: true
      },
      faqDtoList: {
        type: Array,
        required: true
      }
    }
  },
  setup (props) {
    const faqCategory = ref(JSON.parse(JSON.stringify(props.faq)))
    return {
      faqCategory
    }
  }
}
</script>

<style lang="scss" scoped>

.FAQ{
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 360px;
  padding: 20px;
  @media only screen and (max-width: 768px){
    padding: 10px;
  }
  .FAQ-Category{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: auto;
    width: 100%;
    padding: 0 20px;
    div{
      width: 100%;
    }
    h1{
      padding: 20px;
      font-weight: bold;
      font-size: 20px;
      text-transform: uppercase;
      @media only screen and (max-width: 768px){
        font-size: 15px;
        padding: 10px;
      }
    }
  }
}

</style>
