<template>
  <div class="stepsWrapper">
      <Steps :model="steps"/>
  </div>
  <router-view v-slot="{Component}">
    <keep-alive>
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
import Steps from 'primevue/steps'
import { ref } from 'vue'

export default {
  name: 'ShoppingCartView',
  components: { Steps },
  setup () {
    const steps = ref([
      {
        label: 'Koszyk',
        to: '/shopping-cart'
      },
      {
        label: 'Dane',
        to: '/shopping-cart/shopping-invoice'
      },
      {
        label: 'Rezultat',
        to: '/shopping-cart/shopping-summary'
      }
    ])

    return { steps }
  }
}
</script>

<style lang="scss" scoped>

.stepsWrapper {
  padding: 55px 15% 0 15%;
  background-color: #f8f8f8;

  ::v-deep(.p-steps .p-steps-item .p-menuitem-link){
    background-color: transparent;
  }

  @media screen and (max-width: 768px) {
    padding: 25px 0;
  }
}

</style>
