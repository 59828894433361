<template>
  <div class="p-d-flex p-jc-center">
    <div class="p-d-flex p-dir-col p-ai-center p-mt-6 news-wrapper">
      <h2 class="p-mt-4 p-mb-5">AKTUALNOŚCI</h2>
      <div class="p-d-flex p-dir-row">
        <div class="p-d-flex p-dir-col border-column">
          <div v-for="(article, index) of firstArticleColumn" :key="index">
            <div class="p-d-flex p-dir-col p-jc-center p-ml-6 p-mr-5 p-mb-3 article-wrapper">
              <p class="p-mb-2">{{ article.publishDateStart }}</p>
              <h3 class="p-mb-2">{{ article.title }}</h3>
              <p class="p-mb-2">{{ article.lead }}<Button label="Czytaj więcej" class="p-button-link" @click="handleNavigationToArticle(article.id)"/></p>
            </div>
          </div>
        </div>
        <div class="p-d-flex p-dir-col">
          <div v-for="(article, index) of secondArticleColumn" :key="index">
            <div class="p-d-flex p-dir-col p-jc-center p-ml-6 p-mr-5 p-mb-3 article-wrapper">
              <p class="p-mb-2">{{ article.publishDateStart }}</p>
              <h3 class="p-mb-2">{{ article.title }}</h3>
              <p class="p-mb-2">{{ article.lead }}<Button label="Czytaj więcej" class="p-button-link" @click="handleNavigationToArticle(article.id)" /></p>
            </div>
          </div>
        </div>
      </div>
      <Paginator :rows="10" :totalRecords="totalRecords"  @page="onPage($event)"></Paginator>
    </div>
  </div>
</template>

<script>
import Paginator from 'primevue/paginator'
import { ref, inject, onMounted } from 'vue'
import { ArticleService } from '@/services/articleService'
import Button from 'primevue/button'
import router from '@/router'

export default {
  components: {
    Paginator,
    Button
  },
  name: 'AllArticlesView',
  setup (props) {
    const keycloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const articleService = new ArticleService(keycloak)
    const totalRecords = ref()
    const firstArticleColumn = ref()
    const secondArticleColumn = ref()

    onMounted(async () => {
      await articleService.fetchArticles(0, 9).then(response => {
        totalRecords.value = response.count
        firstArticleColumn.value = response.articles.slice(0, 5)
        secondArticleColumn.value = response.articles.slice(5, 10)
      })
    })
    const onPage = async (event) => {
      await articleService.fetchArticles(event.page !== 0 ? event.page * 10 : 0, event.page !== 0 ? (event.page * 10) + 9 : event.page + 9).then(response => {
        firstArticleColumn.value = response.articles.slice(0, 5)
        secondArticleColumn.value = response.articles.slice(5, 10)
      })
    }
    const handleNavigationToArticle = (id) => {
      router.push({ name: 'Article', params: { articleId: id } })
    }

    return { totalRecords, onPage, firstArticleColumn, secondArticleColumn, handleNavigationToArticle }
  }
}
</script>

<style lang="scss" scoped>
body{
  background-color: $light_grey;
}

.p-button.p-component.p-button-link{
  border: none;
  background-color: white;
  color: $primary_color;
  padding: 0px;
}
.news-wrapper{
  background-color: white;
  .article-wrapper{
    width: 450px;
  }

  .border-column{
    border-right-color: #A4A4A4;
    border-style: hidden solid hidden hidden;
    border-width: 0.5px;
  }
}
</style>
