<template>
  <div class="p-mr-3">
    <div class="p-d-flex p-jc-around p-mb-1" style="height: 19px;">
      <p>{{ initDate }}</p>
      <p>{{ sentDate }}</p>
    </div>
    <div class="newsletter-card-wrapper p-shadow-1" :style="cardStyle">
      <div class="details">
        <h4>{{ newsletter.name }}</h4>
        <div class="p-d-flex p-dir-row p-jc-between p-mt-4" style="width: 100%">
          <div class="p-d-flex p-dir-row p-ai-center" :class="statusClass.value">
            <div class="p-mr-2">
              <CustomIcon width="20" height="21" transformCords="translate(-3 -2)" viewBox="0 0 20 21" :iconColor="iconColor.value" iconName="ikona kalkulatora"><calculator-icon/></CustomIcon>
            </div>
            <h4>{{ translateStatusRole(newsletter.status) }}</h4>
          </div>
          <div class="p-d-flex p-dir-row p-ai-center">
            <p>Wysłano: {{newsletter.sentAmount !== null ? newsletter.sentAmount : '0'}}{{'/' + newsletter.totalAmount }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <CustomIcon viewBox="0 0 32 32" :iconColor="isSelected ? '#B72937' : '#161614'" height="32" width="32" iconName="ikona wybranego newslettera" ><accept-icon/></CustomIcon>
      <div class="p-d-flex p-jc-between">
        <Button v-tooltip.top="'Edytuj'" class="p-button-text p-button-rounded" @click="selectNewsletter">
          <CustomIcon width="20" height="20" viewBox="0 0 20 20" :iconColor="isSelected ? '#B72937' : '#161614'" iconName="ikona edycji"><pencil-icon/></CustomIcon>
        </Button>
        <Button v-tooltip.top="'Usuń'" class="p-button-text p-button-rounded" @click="toggleRemoveNewsletterModal">
          <CustomIcon width="20" height="21" viewBox="0 0 20 21" iconColor="#161614" iconName="ikona usuwania"><trashcan-icon/></CustomIcon>
        </Button>
      </div>
    </div>
  </div>

  <ConfirmationModal
    text="Czy jesteś pewien, że chcesz usunać ten newsletter?"
    v-model:visible="removeNewsletterModal"
    :noPressed="toggleRemoveNewsletterModal"
    :yesPressed="submitRemoveNewsletterModal"
  />
</template>

<script>
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import { ref, computed } from 'vue'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'
import { parseDateArray, translateStatusRole } from '@/utils/utils'
import { newsletterStateEnum } from '@/enums/newsletterStateEnum'
import CustomIcon from '@/components/CustomIcon'
import AcceptIcon from '@/assets/icons/accept-icon'
import CalculatorIcon from '@/assets/icons/calculator-icon'
import PencilIcon from '@/assets/icons/pencil-icon'
import TrashcanIcon from '@/assets/icons/trashcan-icon'

export default {
  name: 'NewsletterCard',
  components: {
    TrashcanIcon,
    PencilIcon,
    CalculatorIcon,
    AcceptIcon,
    Button,
    CustomIcon,
    ConfirmationModal
  },
  directives: {
    tooltip: Tooltip
  },
  props: {
    index: {
      required: true
    },
    selectedId: {
      type: String
    },
    newsletter: {
      type: Object,
      required: true
    }
  },
  emits: ['selectNewsletter', 'deleteNewsletter'],
  setup (props, { emit }) {
    const removeNewsletterModal = ref(false)
    const isSelected = computed(() => props.selectedId === props.newsletter.id)
    const newsletterStatus = computed(() => translateStatusRole(props.newsletter.status))
    const sentDate = computed(() => {
      if (props.newsletter.sentDate !== null) {
        return parseDateArray(props.newsletter.sentDate)
      } else {
        return ''
      }
    })
    const initDate = computed(() => {
      if (props.newsletter.initSendDate !== null) {
        return parseDateArray(props.newsletter.initSendDate)
      } else {
        return ''
      }
    })
    const iconColor = computed(() => {
      const selectedStatus = ref('')
      if (props.newsletter.status === newsletterStateEnum.NEW) {
        selectedStatus.value = '#FF0019'
      } else if (props.newsletter.status === newsletterStateEnum.SENT) {
        selectedStatus.value = '#3DA800'
      } else if (props.newsletter.status === newsletterStateEnum.PENDING) {
        selectedStatus.value = '#FF5E00'
      }
      return selectedStatus
    })
    const statusClass = computed(() => {
      const selectedStatus = ref('')
      if (props.newsletter.status === newsletterStateEnum.NEW) {
        selectedStatus.value = 'newsletter-new'
      } else if (props.newsletter.status === newsletterStateEnum.SENT) {
        selectedStatus.value = 'newsletter-sent'
      } else if (props.newsletter.status === newsletterStateEnum.PENDING) {
        selectedStatus.value = 'newsletter-pending'
      }
      return selectedStatus
    })
    const cardStyle = computed(() => {
      if (props.selectedId === props.newsletter.id) {
        return 'background: #eaeaea'
      } else {
        return 'background: white'
      }
    })

    const toggleRemoveNewsletterModal = () => {
      removeNewsletterModal.value = !removeNewsletterModal.value
    }

    const submitRemoveNewsletterModal = () => {
      toggleRemoveNewsletterModal()
      // TODO Fix eslint
      // eslint-disable-next-line vue/custom-event-name-casing
      emit('deleteNewsletter', props.index)
    }

    const selectNewsletter = () => {
      // eslint-disable-next-line vue/custom-event-name-casing
      emit('selectNewsletter', props.index)
    }

    return {
      isSelected,
      cardStyle,
      iconColor,
      initDate,
      sentDate,
      removeNewsletterModal,
      newsletterStatus,
      toggleRemoveNewsletterModal,
      submitRemoveNewsletterModal,
      selectNewsletter,
      statusClass,
      translateStatusRole
    }
  }
}
</script>

<style lang="scss" scoped>
.newsletter-card-wrapper {
  padding: 20px 25px;
  max-width: 450px;
  min-width: 300px;
  overflow: hidden;
  background: white;

  .details {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .calculator-icon{
      width: 20px;
      height: 20px;
      svg{
        fill: blue;
      }
    }

    .newsletter-new{
      color: #FF0019;
    }

    .newsletter-sent{
      color: #3DA800;
    }

    .newsletter-pending{
      color: #FF5E00;
    }
  }
}
.buttons {
  width: 100%;
  margin-top: 15px;
  display: flex;
  padding: 0 0 0 15px;
  justify-content: space-between;
  align-items: center;

  .pi{
    font-size: 1.2rem;
  }

  .right-buttons{
    width: 55px;
  }
}
</style>
