<template>
  <div class="shoppingCartTableWrapper">
    <DataTable
      :value="finalShoppingCart.products"
      class="p-datatable-responsive-demo"
      :paginator="finalShoppingCart.products.length > 5"
      columnResizeMode="fit"
      :rowHover="true"
      :rows="5">
      <template #empty>
        Nie posiadasz żadnych elementów w koszyku!
      </template>
      <Column headerStyle="width:20%;">
        <template #body="slotProps">
            <span>
              <img
                class="productImage"
                :src="slotProps.data.image"
              />
            </span>
        </template>
      </Column>
      <Column headerStyle="width:25%;" field="Nazwa" header="Nazwa">
        <template #body="slotProps">
          <span class="p-column-title">Nazwa</span>
          <span class="spanWithValue">{{ slotProps.data.name }}</span>
        </template>
      </Column>
      <Column headerStyle="width:20%;" field="Cena" header="Cena">
        <template #body="slotProps">
          <span class="p-column-title">Cena</span>
          <span class="spanWithValue">{{ slotProps.data.price }}zł</span>
        </template>
      </Column>
      <Column headerStyle="width:15%;" field="Ilość" header="Ilość">
        <template #body="slotProps">
          <span class="p-column-title">Ilość</span>
          <span class="spanWithValue">
              {{slotProps.data.amount}}
            </span>
        </template>
      </Column>
      <Column headerStyle="width:20%;" field="Wartość" header="Wartość">
        <template #body="slotProps">
          <span class="p-column-title">Wartość</span>
          <span class="spanWithValueTotalPrice">{{
              parseFloat((slotProps.data.price * slotProps.data.amount).toString()).toFixed(2)
            }}zł</span>
        </template>
      </Column>
    </DataTable>
  </div>
  <div class="paymentBox">
    <h3>Sposób płatności</h3>
    <div class="paymentMethod">
      <p>Przelew</p>
      <h4>9.79zł brutto</h4>
    </div>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { useStore } from 'vuex'

export default {
  name: 'ShoppingCartStepThreeTableColumn',
  components: { DataTable, Column },
  setup () {
    const store = useStore()
    const finalShoppingCart = store.getters['shoppingCartStore/getCurrentShoppingCart']

    return {
      finalShoppingCart
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/componentStyles/shoppingCartProductsTable.scss";

.paymentBox {
  margin-top: 30px;
  border: 1px solid $grey;
  background-color: white;
  padding: 15px;

  h3 {
    font-weight: 500;
    color: $brown;
  }

  .paymentMethod {
    padding:15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
