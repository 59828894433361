import { ProductImage } from '@/models/productImage'

export class Product {
  constructor (product) {
    this.id = product.id
    this.name = product.name
    this.description = product.description
    this.price = product.price
    this.stock = product.stock
    this.category = product.category
    this.available = product.available
    this.amount = product.amount
    this.images = this.parseImages(product.images)
  }

  parseImages (images) {
    const imageList = []
    for (const image of images) {
      imageList.push(new ProductImage(image))
    }
    return imageList
  }
}
