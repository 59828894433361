<template>
  <button
    :class="buttonClass"
    @click="click !== undefined ? click : null"
    :type="type !== undefined ? 'type' : 'button'">
    {{ label }}
  </button>
</template>

<script>
export default {
  name: 'CustomButton',
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false
    },
    click: {
      type: Function,
      required: false
    },
    buttonStyle: {
      type: String,
      required: false
    }
  },
  setup (props) {
    let buttonClass

    switch (props.buttonStyle) {
      case 'brown':
        buttonClass = 'brown'
        break
      case 'red':
        buttonClass = 'red'
        break
      case 'white':
        buttonClass = 'white'
        break
      default :
        buttonClass = 'brown'
        break
    }

    return { buttonClass }
  }
}
</script>

<style lang="scss" scoped>

.brown {
  background-color: $brown;
  color: white;

  &:hover {
    background-color: $dark_brown;
  }
}

.white {
  background-color: white;
  color: black;
  border: 1px solid #c8c8c8;

  &:hover {
    background-color: #c8c8c8;
  }
}

.red {
  background-color: #d66868;
  color: white;

  &:hover {
    background-color: red;
  }
}

button {
  width: 100%;
  font-weight: 500;
  height: 2.5em;
  border: none;
  font-size: 1em;
  cursor: pointer;
}

button:hover {
  border: none;
  transition: background-color 0.1s ease-out;
}
</style>
