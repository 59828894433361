<template>
  <Dialog header="Potwierdzenie akcji" v-model:visible="modalVisible" :modal="true">
    <p class="p-m-0">{{ text }}</p>
    <template #footer>
      <Button label="Nie" icon="pi pi-times" @click="noPressed" class="p-button-text"/>
      <Button label="Tak" icon="pi pi-check" @click="yesPressed" autofocus />
    </template>
  </Dialog>
</template>

<script>
import { computed } from 'vue'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'

export default {
  name: 'ConfirmationModal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    noPressed: {
      type: Function,
      required: true
    },
    yesPressed: {
      type: Function,
      required: true
    }
  },
  components: {
    Dialog,
    Button
  },
  setup (props, { emit }) {
    const modalVisible = computed({
      get () {
        return props.visible
      },
      set (value) {
        emit('update:visible', value)
      }
    })

    return { modalVisible }
  }
}
</script>

<style lang="scss" scoped>

</style>
