<template>
  O firmie
  <button @click="click">Wyswietl role</button>
  <h2>{{ roles }}</h2>
  <button @click="click2">Pobierz adres użytkownika</button>
  <div v-if="userAddress !== undefined">
    <h1> Adres do faktury </h1>
    <p>Numer apartamentu: {{ userAddress.invoiceAddress.apartmentNumber }}</p>
    <p>Numer budynku: {{ userAddress.invoiceAddress.buildingNumber }}</p>
    <p>Miasto: {{ userAddress.invoiceAddress.city }}</p>
    <p>Ulica: {{ userAddress.invoiceAddress.street }}</p>
    <p>Kod pocztowy: {{ userAddress.invoiceAddress.zipCode }}</p>
    <h1> Adres dostawy </h1>
    <p>Numer apartamentu: {{ userAddress.shippingAddress.apartmentNumber }}</p>
    <p>Numer budynku{{ userAddress.shippingAddress.buildingNumber }}</p>
    <p>Miasto: {{ userAddress.shippingAddress.city }}</p>
    <p>Ulica: {{ userAddress.shippingAddress.street }}</p>
    <p>Kod pocztowy: {{ userAddress.shippingAddress.zipCode }}</p>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { ref, inject } from 'vue'
import { UserService } from '@/services/userService'

export default {
  name: 'AboutView',
  setup () {
    const roles = ref([])
    const userAddress = ref()
    const keyCloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const userService = new UserService(keyCloak)

    const click = () => {
      roles.value = useStore().getters['keycloakStore/getRoles']
    }

    const click2 = async () => {
      const response = await userService.readUserData()

      if (response !== false) {
        userAddress.value = response
      }
    }

    return {
      click,
      click2,
      roles,
      userAddress
    }
  }
}
</script>
