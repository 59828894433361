import axios from 'axios'
import { handleRequestError } from '../utils/utils'
import authContentTypeHeader from './auth_content_type_header'

export class FaqService {
  constructor (keyCloak) {
    this.keyCloak = keyCloak
    this.baseUrl = process.env.VUE_APP_BASE_BACKEND_URL
  }

  async fetchFaqData () {
    const options = {
      method: 'get',
      headers: {
        accept: 'application/json'
      },
      url: this.baseUrl + '/faq'
    }
    const response = await axios(options).then(value => {
      return value.data
    }).catch(async (error) => {
      await handleRequestError(error)
    })
    return Promise.resolve(response)
  }

  async sendFaqData (faq) {
    const options = {
      method: 'post',
      headers: authContentTypeHeader(this.keyCloak.token),
      url: this.baseUrl + '/faq',
      data: faq
    }
    const response = await axios(options).then(() => {
      return 1
    }).catch(async (error) => {
      await handleRequestError(error)
    })
    return Promise.resolve(response)
  }
}
