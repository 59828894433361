<template>
  <div class="p-d-flex p-jc-center">
    <div class="p-col-12 p-md-11 p-lg-11 newsletter-wrapper">
      <div class="buttons-section p-d-flex p-jc-center">
        <Button :icon="isNewNewsletter ? 'pi pi-times' : 'pi pi-plus'" :class="isNewNewsletter ? 'p-button-outlined add-new-newsletter-button' : 'add-new-newsletter-button'" @click="handleAddingNewsletter" type="button" :label="isNewNewsletter ? 'Zamknij' : 'Dodaj nowy newsletter'"/>
      </div>
      <div class="p-d-flex p-flex-row p-jc-center newsletter-cards-box">
        <div v-for="(newsletter, index) in newslettersData"
             :key="index">
          <NewsletterCard :selectedId="selectedId" @selectNewsletter="selectNewsletterData" @deleteNewsletter="handleDeleteNewsletterData"  :index="index" :newsletter="newsletter"/>
        </div>
      </div>
      <div class="newsletter-config-wrapper" v-if="isSelected">
        <h3 style="text-align: center">TWORZENIE NEWSLETTERA</h3>
        <h4>Nazwa</h4>
        <div class="p-col-12 name-section">
          <LabeledInput
            name="newsletter_name_input"
            rules="required"
            type="text"
            v-model:modelValue="currentlyEditing.name"
            placeholder="Wpisz nazwę"
          />
        </div>
        <h4>Kategoria</h4>
        <div class="category-section">
          <div class="p-d-flex p-dir-row p-flex-wrap p-mb-1">
              <div v-for="[key, value] of Object.entries(newslettersCategories.categories)" :key="key" class="p-mr-6">
                <h4 class="p-mt-2 p-mb-3">{{ key }}</h4>
                <div v-for="subcategory of value" :key="subcategory.key" class="p-field-checkbox p-mb-3">
                  <Checkbox :id="subcategory.key" name="category" :value="subcategory.id" v-model="currentlyEditing.categories"/>
                  <label :for="subcategory.key">{{ subcategory.subcategory }}</label>
                </div>
              </div>
            </div>
        </div>
        <h4>Edycja treści newslettera</h4>
        <div class="newsletter-header-content">
          <h4 class="p-mb-3">Nagłówek </h4>
          <QuillEditor class="wysiwyg-editor" theme="snow" :modules="moduleConfig" :toolbar="EditorConfig" contentType="html" v-model:content="currentlyEditing.header" :enable="!isSaving"/>
        </div>
        <div class="newsletter-body-content">
          <h4 class="p-mb-3">Treść </h4>
          <QuillEditor class="wysiwyg-editor" theme="snow" :modules="moduleConfig" :toolbar="EditorConfig" contentType="html" v-model:content="currentlyEditing.content" :enable="!isSaving"/>
        </div>
        <div class="newsletter-footer-content">
          <h4 class="p-mb-3">Stopka </h4>
          <QuillEditor class="wysiwyg-editor" theme="snow" :modules="moduleConfig" :toolbar="EditorConfig" contentType="html" v-model:content="currentlyEditing.footer" :enable="!isSaving"/>
        </div>
        <div class="p-d-flex p-jc-start button-section">
          <Button type="button" @click="handleSavingNewsletterData" label="Zapisz zmiany" :loading="isSaving"/>
          <Button icon="pi pi-envelope" class="send-test-button" v-if="!isNewNewsletter" type="button" @click="toggleSendNewsletter" :label="isSendingCurrentNewsletter ? 'Wysyłanie' : 'Wyślij testowy newsletter'" :loading="isSaving || isSendingCurrentNewsletter"/>
          <Button icon="pi pi-envelope" class="send-test-button p-ml-2" v-if="!isNewNewsletter" type="button" @click="toggleSendingNewsletters" :label="isSendingCurrentNewsletter ? 'Wysyłanie' : 'Wyślij newsletter'" :loading="isSaving || isSendingCurrentNewsletter"/>
        </div>
      </div>

      <BasicFormModal
        v-model:modelValue="sendNewsletterToEmailValue"
        v-model:visible="sendNewsletterModal"
        :submit="submitSendingNewsletter"
        :toggle="toggleSendNewsletter"
        header="Podaj adres na który zostanie wysłany newsletter"
        placeholder="Adres email"
      />
      <ConfirmationModal
        text="Czy jesteś pewien, że chcesz wysłać ten newsletter?"
        v-model:visible="sendNewslettersModal"
        :noPressed="toggleSendingNewsletters"
        :yesPressed="submitSendingNewsletters"
      />
    </div>
  </div>
</template>

<script>
import { ref, inject, onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { NewsletterService } from '@/services/newsletterService'
import BasicFormModal from '@/components/modals/BasicFormModal'
import ConfirmationModal from '@/components/modals/ConfirmationModal'
import LabeledInput from '@/components/LabeledInput'
import Checkbox from 'primevue/checkbox'
import Button from 'primevue/button'
import NewsletterCard from '@/components/NewsletterCard'
import { newsletterStateEnum } from '@/enums/newsletterStateEnum'
import BlotFormatter from 'quill-blot-formatter'
import { NewsletterModel } from '@/models/newsletterModel'
import { v4 as uuidv4 } from 'uuid'
import { parseUserSubscriptionsMap, sanitizeQuillHtml } from '@/utils/utils'

export default {
  name: 'newNewsletterConfigView',
  components: {
    BasicFormModal,
    ConfirmationModal,
    LabeledInput,
    Checkbox,
    Button,
    NewsletterCard
  },
  setup () {
    const keycloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const store = useStore()
    const newsletterService = new NewsletterService(keycloak)

    const isLoading = ref(true)
    const isSaving = ref(false)

    const newslettersData = computed(() => store.state.newsletterStore.newsletters)
    const newslettersCategories = computed(() => store.state.newsletterStore.categories)

    const isSelected = ref(false)
    const isNewNewsletter = ref(false)
    const sendNewsletterModal = ref(false)
    const sendNewslettersModal = ref(false)
    const isSendingCurrentNewsletter = computed(() => {
      const index = newslettersData.value.findIndex(newsletter => newsletter.id === selectedId.value)
      if (newslettersData.value[index].status === newsletterStateEnum.PENDING) {
        return true
      } else {
        return false
      }
    })

    const currentlyEditing = reactive({
      id: '',
      name: '',
      header: '',
      content: '',
      footer: ''
    })
    const sendNewsletterToEmailValue = ref('')
    const selectedId = ref()
    const EditorConfig = [
      ['bold', 'italic', 'underline', 'strike', { color: [] }],
      [{ align: [] }, { list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['link', 'image']
    ]
    const moduleConfig = {
      name: 'blotFormatter',
      module: BlotFormatter,
      options: {/* options */}
    }

    onMounted(() => {
      store.dispatch('newsletterStore/fetchNewsletterData', { newsletterService: newsletterService }).then(
        async response => {
          isLoading.value = false
        }
      )
      store.dispatch('newsletterStore/fetchNewsletterCategoriesData', { newsletterService: newsletterService }).then(
        async response => {
          isLoading.value = false
        }
      )
    })

    const toggleSendNewsletter = () => {
      sendNewsletterModal.value = !sendNewsletterModal.value
    }

    const toggleSendingNewsletters = () => {
      sendNewslettersModal.value = !sendNewslettersModal.value
    }

    const assignNewsletterData = async (index) => {
      currentlyEditing.id = newslettersData.value[index].id
      currentlyEditing.name = newslettersData.value[index].name
      currentlyEditing.sentDate = newslettersData.value[index].sentDate
      currentlyEditing.initSendDate = newslettersData.value[index].initSendDate
      currentlyEditing.sentAmount = newslettersData.value[index].sentAmount
      currentlyEditing.totalAmount = newslettersData.value[index].totalAmount
      currentlyEditing.status = newslettersData.value[index].status
      console.log(await parseUserSubscriptionsMap(newslettersData.value[index]))
      currentlyEditing.categories = await parseUserSubscriptionsMap(newslettersData.value[index])
      currentlyEditing.header = newslettersData.value[index]?.header
      currentlyEditing.content = newslettersData.value[index]?.content
      currentlyEditing.footer = newslettersData.value[index]?.footer
    }

    const clearNewsletterData = () => {
      currentlyEditing.id = ''
      currentlyEditing.name = ''
      currentlyEditing.categories = []
      currentlyEditing.header = ''
      currentlyEditing.content = ''
      currentlyEditing.footer = ''
    }

    const selectNewsletterData = async (index) => {
      isSelected.value = false
      isNewNewsletter.value = false
      await handleFetchSelectedNewsletterDataFromStore(newslettersData.value[index].id)
      await assignNewsletterData(index)
      selectedId.value = currentlyEditing.id
      isSelected.value = true
    }

    const handleDeleteNewsletterData = (index) => {
      const id = newslettersData.value[index].id
      if (selectedId.value === id) {
        isSelected.value = false
      }
      handleDeleteNewsletterDataToStore(id)
    }

    const handleAddingNewsletter = async () => {
      if (isNewNewsletter.value) {
        isSelected.value = false
        isNewNewsletter.value = !isNewNewsletter.value
      } else {
        isSelected.value = false
        isNewNewsletter.value = true
        selectedId.value = ''
        await clearNewsletterData()
        isSelected.value = true
      }
    }

    const handleSavingNewsletterData = () => {
      const newsletterData = new NewsletterModel({
        id: isNewNewsletter.value ? uuidv4() : currentlyEditing.id,
        name: currentlyEditing.name,
        categories: currentlyEditing.categories,
        sentDate: currentlyEditing.sentDate,
        initSendDate: currentlyEditing.initSendDate,
        sentAmount: isNewNewsletter.value ? '0' : currentlyEditing.sentAmount,
        totalAmount: isNewNewsletter.value ? '0' : currentlyEditing.totalAmount,
        status: isNewNewsletter.value ? newsletterStateEnum.NEW : currentlyEditing.status,
        header: sanitizeQuillHtml(currentlyEditing.header),
        content: sanitizeQuillHtml(currentlyEditing.content),
        footer: sanitizeQuillHtml(currentlyEditing.footer)
      })

      if (isNewNewsletter.value) {
        handleSavingNewsletterDataToStore(newsletterData)
        isSelected.value = false
        isNewNewsletter.value = false
      } else {
        handleUpdatingNewsletterDataToStore(newsletterData)
      }
    }

    const submitSendingNewsletter = async () => {
      toggleSendNewsletter()

      const newsletterData = {
        newsletterId: currentlyEditing.id,
        testEmail: sendNewsletterToEmailValue.value
      }
      await handleSendingNewsletterToStore(newsletterData)
    }

    const submitSendingNewsletters = async () => {
      toggleSendingNewsletters()

      const newsletterData = {
        newsletterId: currentlyEditing.id
      }
      await handleSendingNewslettersToStore(newsletterData)
    }

    const handleFetchSelectedNewsletterDataFromStore = async (id) => {
      isLoading.value = true
      await store.dispatch('newsletterStore/fetchSelectedNewsletterData', { newsletterService: newsletterService, id: id }).then(
        async response => {
          isLoading.value = false
        }
      )
    }

    const handleDeleteNewsletterDataToStore = async (id) => {
      isSaving.value = true
      await store.dispatch('newsletterStore/removeNewsletterData', { id: id, newsletterService: newsletterService }).then(
        async () => {
          await store.dispatch('snackbarStore/showMessage', 'Usunięto newsletter.')
          isSaving.value = false
        }
      )
    }

    const handleSavingNewsletterDataToStore = async (newsletterData) => {
      isSaving.value = true
      await store.dispatch('newsletterStore/saveNewsletterData', { newsletterData: newsletterData, newsletterService: newsletterService }).then(
        async () => {
          await store.dispatch('snackbarStore/showMessage', 'Zapisano nowy newsletter.')
          isSaving.value = false
        }
      )
    }

    const handleUpdatingNewsletterDataToStore = async (newsletterData) => {
      isSaving.value = true
      await store.dispatch('newsletterStore/updateNewsletterData', { newsletterData: newsletterData, newsletterService: newsletterService }).then(
        async () => {
          await store.dispatch('snackbarStore/showMessage', 'Zaktualizowano newsletter.')
          isSaving.value = false
        }
      )
    }

    const handleSendingNewsletterToStore = async (newsletterData) => {
      isSaving.value = true
      await store.dispatch('newsletterStore/sendNewsletterData', { newsletterData: newsletterData, newsletterService: newsletterService }).then(
        async () => {
          await store.dispatch('snackbarStore/showMessage', 'Rozpoczęto wysyłanie newslettera.')
          isSaving.value = false
        }
      )
    }

    const handleSendingNewslettersToStore = async (newsletterData) => {
      isSaving.value = true
      await store.dispatch('newsletterStore/sendNewsletterData', { newsletterData: newsletterData, newsletterService: newsletterService }).then(
        async () => {
          await store.dispatch('snackbarStore/showMessage', 'Rozpoczęto wysyłanie newslettera.')
          isSaving.value = false
        }
      )
    }

    return {
      newslettersData,
      newslettersCategories,
      isLoading,
      isSaving,
      handleUpdatingNewsletterDataToStore,
      currentlyEditing,
      sendNewsletterModal,
      sendNewslettersModal,
      sendNewsletterToEmailValue,
      selectedId,
      EditorConfig,
      isNewNewsletter,
      isSelected,
      isSendingCurrentNewsletter,
      moduleConfig,
      toggleSendNewsletter,
      toggleSendingNewsletters,
      submitSendingNewsletter,
      submitSendingNewsletters,
      handleSavingNewsletterData,
      handleAddingNewsletter,
      selectNewsletterData,
      handleDeleteNewsletterData
    }
  }
}
</script>

<style  lang="scss" scoped>
body{
  background-color: #f4f4f4;
}

.newsletter-wrapper {
  margin-top: 30px;
}
.buttons-section{
  .p-button.p-component{
    background-color: black;
    border: 1px solid black;
    color: white;
    padding: 12px 30px;
    &:hover{
      background-color: white;
      border: 1px solid #B72937;
      color: #B72937;
    }
  }

  Button{
    height: 3rem;
  }
}

.newsletter-cards-box{
  width: 100%;
  background: #ffffff;
  padding: 20px;
  flex-flow: row wrap;
}

.newsletter-config-wrapper{
  width: 100%;
  background: #ffffff;
  margin-top: 30px;
  padding: 20px;
}

.add-new-newsletter-button{
  margin-bottom: 30px;
}

.name-section{
  padding: 0 10px;
  margin-bottom: 15px;
}

.category-section {
  margin-bottom: 15px;
  padding: 10px 10px;
}

.newsletter-header-content {
  margin-top: 10px;
  padding: 10px 10px;

  h4 {
    margin-bottom: 5px;
  }

  .file-uploader{
    margin-bottom: 15px;
  }
}

.newsletter-body-content {
  margin-top: 10px;
  padding: 10px 10px;

  h4 {
    margin-bottom: 5px;
  }
}

.newsletter-footer-content {
  margin: 10px 0;
  padding: 10px 10px;

  h4 {
    margin-bottom: 5px;
  }
}
.send-test-button.p-button.p-component{
  background-color: black;
  border: 1px solid black;
  color: white;
  padding: 12px 30px;
  &:hover{
    background-color: white;
    border: 1px solid #B72937;
    color: #B72937;
  }
}

Button:nth-child(1){
  margin-right: 10px;
}
</style>
