<template>
  <div class="field">
    <label v-if="showLabel" :for="name"> {{ label }}</label>
    <Field
      :id="name"
      as="input"
      :name="name"
      :rules="rules"
      :disabled="isDisabled"
      :placeholder="placeholder"
      v-model="localValue"
    />
    <ErrorMessage :name="name"/>
  </div>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
import { computed } from 'vue'

export default {
  name: 'LabeledInput',
  props: {
    name: {
      type: String,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: false
    },
    label: {
      type: String
    },
    rules: {
      type: String,
      required: true
    },
    modelValue: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  components: {
    Field, ErrorMessage
  },
  setup (props, { emit }) {
    const localValue = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })
    return {
      localValue
    }
  }
}
</script>

<style lang="scss" scoped>

.field {
  position: relative;
  padding-top: 2%;
  padding-bottom: 2%;
  flex-basis: 100%;

  input, label {
    display: block;
  }

  label {
    color: black;
  }

  input {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #B72937;
    padding: 11px 4px;
    position: relative;
  }

  span {
    position: absolute;
    color: red;
    font-size: 0.75em;
    display: flex;
    justify-content: flex-end;
    right: 0;
  }
}
</style>
