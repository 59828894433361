<template>
  <div class="FAQ-Item">
      <div @click="state.is_show = !state.is_show" class="FAQ-Item_Header">
        <div style="width: calc(100% - 80px)">
          <div class="FAQ-Item_Header-Title" v-bind:class="{Bold: state.is_show}">
            <span>{{faq_item.question}}</span>
          </div>
          <div class="FAQ-Item_Answer" v-bind:class="state.is_show ? 'Open' : 'Closed'">
            {{faq_item.answer}}
          </div>
        </div>
        <div style="display: flex; align-items: start; width: 80px">
            <div class="FAQ-Item_Header-CollapseExtend" v-bind:class="[state.is_show ? 'Active' : 'Nonactive']">
              <span>{{ state.is_show ? 'zwiń' : 'rozwiń'}}</span><span><i class="arrow"></i></span>
            </div>
        </div>
      </div>
  </div>
</template>

<script>
import { reactive } from 'vue'
export default {
  name: 'faqClientItem',
  props: {
    faq_item: {
      type: Object,
      required: true
    }
  },

  setup () {
    const state = reactive({
      is_show: false
    })
    return {
      state
    }
  }
}

</script>

<style lang="scss" scoped>
.FAQ-Item{
  margin: 10px 0;
  cursor: pointer;
  padding: 10px 30px;
  width: 100%;
  max-width: 100%;
  background-color: white;
  @media only screen and (max-width: 768px){
    padding: 10px 20px;
  }
  .FAQ-Item_Header{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .FAQ-Item_Header-Title{;
      word-wrap: break-word;
      font-weight: normal;
      font-size: 20px;
      @media only screen and (max-width: 768px){
        font-size: 15px;
      }
    }
    .Bold{
      font-weight: bold;
    }
      .FAQ-Item_Header-CollapseExtend{
        display: flex;
        flex-direction: row;
        margin: 0 10px;
        color: #B72937;
        font-size: 16px;
        font-weight: bold;
        width: 80px;
        span{
          margin: 0 5px;
        }
        .arrow{
          border: solid #B72937;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
        }
        @media only screen and (max-width: 768px){
          font-size: 13px;
        }
      }
      .Nonactive{
        .arrow{
          transform: rotate(45deg);
          -webkit-transform: rotate(45deg);
          margin-bottom: 3px;
        }
      }
      .Active{
        .arrow{
          transform: rotate(-135deg);
          -webkit-transform: rotate(-135deg);
          margin-top: 3px;
        }
      }
  }

  .FAQ-Item_Answer{
    font-size: 17px;
    white-space: pre-wrap;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    word-wrap: break-word;
    @media only screen and (max-width: 768px){
      font-size: 15px;
    }
  }
  .Closed{
    height: 0;
    overflow: hidden;
    padding: 0 0;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
  }
  .Open{
    height: auto;
    padding: 20px 0;
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    @media only screen and (max-width: 768px){
      padding: 10px 0;
    }
  }
}

</style>
