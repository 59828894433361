<template>
  <div class="addressGridWrapper">
    <div class="p-grid">
      <div class="p-col-12 p-md-12 p-lg-6 p-xl-4" v-for="item of items" :key="item.key">
        <div
          class="card"
          :class="currentAddress.key === item.key ? 'active' : ''"
          @click="handleOnCardClick(item)">
          <h3>{{ item.name }}</h3>
          <p>{{ item.address }}</p>
          <p>{{ item.cityWithPostcode }}</p>
          <p>{{ item.phoneNumber }}</p>
        </div>
      </div>
    </div>
    <button class="addressGridButton" @click="handleOpenModal">Dodaj adres<i class="pi pi-plus"/></button>

    <Dialog v-model:visible="modalVisible" :modal="true">
      <template #header>
        <h3>Nowy adres dostawy</h3>
      </template>
      <Form @submit="onSubmit" @keydown.enter.prevent.self>
        <div class="p-grid modal">
          <div class="p-col-12 p-md-6">
            <LabeledInput
              name="name"
              label="Imię*"
              rules="required|text"
              v-model:modelValue="nameInputValue"
            />
          </div>
          <div class="p-col-12 p-md-6">
            <LabeledInput
              name="surname"
              label="Nazwisko*"
              rules="required|text"
              v-model:modelValue="surnameInputValue"
            />
          </div>
          <div class="p-col-12 p-md-6">
            <LabeledInput
              name="phoneNumber"
              label="Numer telefonu*"
              rules="required|phoneNumber"
              v-model:modelValue="phoneNumberInputValue"
              placeholder="/(format: xxx-xxx-xxx)/"
            />
          </div>
          <div class="p-col-12 p-md-6"></div>
          <div class="p-col-12 p-md-6">
            <LabeledInput
              name="shippingStreet"
              label="Ulica*"
              rules="required|text"
              v-model:modelValue="streetInputValue"
            />
          </div>
          <div class="p-col-12 p-md-6">
            <LabeledInput
              name="shippingBuildingNumber"
              label="Numer domu*"
              rules="required|textNumber"
              v-model:modelValue="buildingNumberInputValue"
            />
          </div>
          <div class="p-col-12 p-md-6">
            <LabeledInput
              name="shippingCity"
              label="Miejscowość*"
              rules="required|text"
              v-model:modelValue="cityInputValue"
            />
          </div>
          <div class="p-col-12 p-md-6">
            <LabeledInput
              name="shippingZipCode"
              label="Kod pocztowy*"
              rules="required|postCode"
              placeholder="/(format: xx-xxx)/"
              v-model:modelValue="postcodeInputValue"
            />
          </div>
          <p class="requiredField">* - pola wymagane</p>
        </div>
        <div class="footer">
          <button id="closeModalButton" class="addressGridButton" @click="handleCloseModal">Zamknij</button>
          <button type="submit" id="submitModalButton" class="addressGridButton">Zatwierdź</button>
        </div>
      </Form>
    </Dialog>
  </div>
</template>

<script>
import LabeledInput from '@/components/LabeledInput'
import Dialog from 'primevue/dialog'

import { v4 as uuidv4 } from 'uuid'
import { Form } from 'vee-validate'
import { computed, ref } from 'vue'

export default {
  name: 'AddressGrid',
  components: {
    LabeledInput,
    Dialog,
    Form
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    modelValue: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  setup (props, { emit }) {
    const nameInputValue = ref('')
    const surnameInputValue = ref('')
    const phoneNumberInputValue = ref('')
    const streetInputValue = ref('')
    const cityInputValue = ref('')
    const postcodeInputValue = ref('')
    const buildingNumberInputValue = ref('')

    const addresses = computed(() => props.items)
    const currentAddress = computed({
      get () {
        return props.modelValue
      },
      set (value) {
        emit('update:modelValue', value)
      }
    })

    const modalVisible = computed({
      get () {
        return props.visible
      },
      set (value) {
        emit('update:visible', value)
      }
    })

    const handleOnCardClick = (item) => {
      currentAddress.value = item
    }

    const handleOpenModal = () => {
      modalVisible.value = true
    }

    const handleCloseModal = () => {
      modalVisible.value = false
    }

    const onSubmit = async () => {
      const newItem = {
        key: uuidv4(),
        name: nameInputValue.value,
        address: streetInputValue.value + ' ' + buildingNumberInputValue.value,
        cityWithPostcode: postcodeInputValue.value + ' ' + cityInputValue.value,
        phoneNumber: phoneNumberInputValue.value
      }

      addresses.value.push(newItem)
      currentAddress.value = newItem

      handleCloseModal()

      nameInputValue.value = ''
      surnameInputValue.value = ''
      phoneNumberInputValue.value = ''
      streetInputValue.value = ''
      cityInputValue.value = ''
      postcodeInputValue.value = ''
      buildingNumberInputValue.value = ''
    }

    return {
      currentAddress,
      handleOnCardClick,
      modalVisible,
      handleOpenModal,
      handleCloseModal,
      nameInputValue,
      surnameInputValue,
      phoneNumberInputValue,
      streetInputValue,
      cityInputValue,
      postcodeInputValue,
      buildingNumberInputValue,
      onSubmit
    }
  }
}
</script>

<style lang="scss" scoped>
.addressGridWrapper {
  display: flex;
  flex-direction: column;

  .card {
    padding: 15px;
    border: 1px solid #c8c8c8;
    cursor: pointer;
    max-height: 200px;

    h3 {
      margin-bottom: 10px;
    }
  }

  .active {
    background-color: $brown;
    color:white
  }
}

.addressGridButton {
  margin-top: 7px;
  margin-bottom: -15px;
  align-self: flex-end;
  width: 25%;
  font-weight: 500;
  height: 2.5em;
  border: none;
  font-size: 1em;
  cursor: pointer;
  background-color: $brown;
  color: white;

  i {
    margin-left: 5px;
    font-size: 12px;
  }

  @media screen and (max-width: 1199px) {
    width: 100%;
    margin-left: 0;
  }
}

.addressGridButton:hover {
  transition: background-color 0.1s ease-out;
  background-color: $dark_brown;
}

#closeModalButton {
  background-color: white;
  color: $brown;
  width: 80px;

  &:hover {
    color: $dark_brown;
  }
}

#submitModalButton {
  width: 180px;
}

.modal {
  padding: 10px 15px;

  .newAddressInput {
    border: none;
    border-bottom: 1px solid #c8c8c8;
    width: 100%;
    height: 40px;
  }

  .requiredField {
    width: 100%;
    margin: 15px 7px 0 15px;
    text-align: right;
    color: $brown;
    font-size: 12px;
  }
}

.footer {
  padding: 16px;
  display:flex;
  justify-content: flex-end;
}

::v-deep(.p-dialog .p-dialog-header) {
  color: $brown;
}

::v-deep(.p-dialog) {
  width: 50vw;
  z-index: 1100;

  @media screen and (max-width: 768px) {
    width: 90vw;
  }
}

</style>
