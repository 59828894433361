<template>
  <div class="p-d-flex p-jc-center">
    <div class="p-col-12 p-md-11 p-lg-11 faq-wrapper">
      <faqClientCategory v-if="!isLoading" :faq="isPreview ? faqLocalStorage : faqStore "/>
    </div>
  </div>
</template>

<script>
import faqClientCategory from '../components/FAQ/FAQClientCategory'
import { useStore } from 'vuex'
import { computed, inject, onMounted, ref } from 'vue'
import { FaqService } from '../services/faqService'
import { useRoute } from 'vue-router'
export default {
  name: 'faqClientView',
  components: { faqClientCategory },
  setup () {
    const store = useStore()
    const keyCloak = inject(process.env.VUE_APP_KEYCLOAK_PROVIDE_VARIABLE)
    const faqService = new FaqService(keyCloak)
    const isLoading = ref(true)
    const route = useRoute()
    const isPreview = route.query.isPreview
    const faqStore = computed(() => store.state.faqStore.faqCategory)
    const faqLocalStorage = ref([])
    onMounted(async () => {
      if (isPreview) {
        await new Promise(resolve => setTimeout(resolve, 400))
        faqLocalStorage.value = await useLocalStorage()
        isLoading.value = false
      } else {
        await store.dispatch('faqStore/fetchFromServer', faqService).then(() => {
          isLoading.value = false
        })
      }
    })

    const useLocalStorage = () => {
      return JSON.parse(localStorage.getItem('abler-faq'))
    }
    return {
      isPreview,
      faqStore,
      faqLocalStorage,
      isLoading
    }
  }
}
</script>

<style scoped>
body{
  background-color: #f2f2f2;
}
.faq-wrapper{
  margin-top: 30px;
}
</style>
