const state = {
  roles: []
}

const getters = {
  getRoles (state) {
    return state.roles
  }
}

const actions = {
  fillRoles: (context, payload) => {
    context.commit('FILL_ROLES', payload)
  }
}

const mutations = {
  FILL_ROLES: (state, payload) => {
    state.roles = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
