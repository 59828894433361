<template>
  <p>Panel administratora</p>
</template>

<script>
export default {
  name: 'AdminView'
}
</script>

<style lang="scss" scoped>

</style>
