import { parseDateArray } from '@/utils/utils'

export class SingleChatMessage {
  constructor (element) {
    this.message = element.message
    this.author = element.author
    this.customerRead = element.customerRead
    this.firstServiceConsultantRead = element.firstServiceConsultantRead
    this.secondServiceConsultantRead = element.secondServiceConsultantRead
    this.sendDate = parseDateArray(element.sendDate)
  }
}
