import { requestStateEnum } from '@/enums/requestStateEnum'
import { NewsletterModel } from '@/models/newsletterModel'
import { newsletterStateEnum } from '@/enums/newsletterStateEnum'
// import { NewsletterService } from '@/services/newsletterService'

const state = {
  newsletters: null,
  categories: null,
  requestStatus: requestStateEnum.NOT_SENT
}

const getters = {
  getNewsletterData (state) {
    return state.newsletter
  },
  getNewsletterCategoriesData (state) {
    return state.categories
  }
}

const actions = {
  async fetchNewsletterData ({ commit }, payload) {
    return payload.newsletterService.fetchNewsletter().then(
      response => {
        commit('fetchDataSuccess', response)
        return response
      },
      error => {
        commit('fetchDataFailure', error)
      }
    )
  },
  async fetchSelectedNewsletterData ({ commit }, payload) {
    return payload.newsletterService.fetchSelectedNewsletter(payload.id).then(
      response => {
        commit('fetchSelectedDataSuccess', response.data)
        return response
      },
      error => {
        commit('fetchSelectedDataFailure', error)
      }
    )
  },
  async fetchNewsletterCategoriesData ({ commit }, payload) {
    return payload.newsletterService.fetchNewsletterCategories().then(
      response => {
        commit('fetchCategoriesSuccess', response.data)
      },
      error => {
        commit('fetchCategoriesFailure', error)
      }
    )
  },
  async saveNewsletterData ({ commit }, payload) {
    const newsletterData = new NewsletterModel(JSON.parse(JSON.stringify(payload.newsletterData)))
    const newsletterDataToServer = new NewsletterModel({
      id: payload.newsletterData.id,
      name: payload.newsletterData.name,
      categories: payload.newsletterData.categories,
      header: payload.newsletterData.header,
      content: payload.newsletterData.content,
      footer: payload.newsletterData.footer
    })

    return payload.newsletterService.addNewsletter(newsletterDataToServer).then(
      response => {
        commit('saveNewsletterDataSuccess', newsletterData)
      },
      error => {
        commit('saveNewsletterDataFailure', error)
      }
    )
  },
  async sendNewsletterData ({ commit }, payload) {
    const newsletterData = JSON.parse(JSON.stringify(payload.newsletterData))

    return payload.newsletterService.sendNewsletter(newsletterData, newsletterData.newsletterId).then(
      response => {
        commit('sendNewsletterDataSuccess', newsletterData)
      },
      error => {
        console.log(error)
        commit('sendNewsletterDataFailure', error)
      }
    )
  },
  async sendNewsletterCategories ({ commit }, payload) {
    return payload.newsletterService.sendNewsletterCategory(payload.newsletterMap).then(
      response => {
        commit('sendNewsletterCategoriesSuccessful', payload.newsletterMap)
      },
      error => {
        console.log(error)
      }
    )
  },
  async updateNewsletterData ({ commit }, payload) {
    const newsletterData = new NewsletterModel(JSON.parse(JSON.stringify(payload.newsletterData)))
    const newsletterDataToServer = new NewsletterModel({
      id: payload.newsletterData.id,
      name: payload.newsletterData.name,
      categories: payload.newsletterData.categories,
      header: payload.newsletterData.header,
      content: payload.newsletterData.content,
      footer: payload.newsletterData.footer
    })

    return payload.newsletterService.updateNewsletter(newsletterDataToServer).then(
      response => {
        commit('updateNewsletterDataSuccess', newsletterData)
      },
      error => {
        console.log(error)
        commit('updateNewsletterDataFailure', error)
      }
    )
  },
  async removeNewsletterData ({ commit }, payload) {
    return payload.newsletterService.deleteNewsletter(payload.id).then(
      response => {
        commit('removeNewsletterDataSuccess', payload.id)
      },
      error => {
        console.log(error)
        commit('removeNewsletterDataFailure', error)
      }
    )
  }
}

const mutations = {
  fetchDataSuccess (state, data) {
    state.newsletters = data
    state.requestStatus = requestStateEnum.SUCCESS
  },
  fetchDataFailure (state, error) {
    state.newsletters = null
    state.requestStatus = requestStateEnum.ERROR
  },
  fetchSelectedDataSuccess (state, data) {
    for (let i = 0; i < state.newsletters.length; i++) {
      if (state.newsletters[i].id === data.id) {
        state.newsletters[i] = new NewsletterModel(data)
      }
    }
  },
  fetchSelectedDataFailure (state) {
    state.requestStatus = requestStateEnum.ERROR
  },
  fetchCategoriesSuccess (state, data) {
    state.categories = data
    state.requestStatus = requestStateEnum.SUCCESS
  },
  fetchCategoriesFailure (state, data) {
    state.categories = null
    state.requestStatus = requestStateEnum.ERROR
  },
  saveNewsletterDataSuccess (state, data) {
    state.newsletters.push(data)
    state.requestStatus = requestStateEnum.SUCCESS
  },
  saveNewsletterDataFailure (state, error) {
    state.newsletter = null
    state.requestStatus = requestStateEnum.ERROR
  },
  updateNewsletterDataSuccess (state, newsletter) {
    for (let i = 0; i < state.newsletters.length; i++) {
      if (state.newsletters[i].id === newsletter.id) {
        state.newsletters[i] = new NewsletterModel(newsletter)
      }
    }
  },
  sendNewsletterDataSuccess (state, data) {
    const index = state.newsletters.findIndex(newsletter => newsletter.id === data.newsletterId)
    state.newsletters[index].status = newsletterStateEnum.PENDING
  },
  sendNewsletterCategoriesSuccessful (state, data) {
    state.categories = data
  },
  removeNewsletterDataSuccess (state, id) {
    let indexToRemove
    for (let i = 0; i < state.newsletters.length; i++) {
      if (state.newsletters[i].id === id) {
        indexToRemove = i
        break
      }
    }
    state.newsletters.splice(indexToRemove, 1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
